import { BaseComponent } from "@symbiotejs/symbiote";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { PostMatchesComponent } from "../postmatches-component/postmatches-component";
import { GameNavbar } from "../game-navbar/game-navbar";

const noImage = require('../matches-component/Assets/no image found.png');

const csgologo = require("../game-navbar/assets/images/csgologo 150x150.png");
const dota2 = require('../game-navbar/assets/images/dota2logo 150x150.png');
const lol = require('../game-navbar/assets/images/lollogo 150x150.png');
const valorant = require('../game-navbar/assets/images/valorantlogo 150x150.png');
const fifa = require('../game-navbar/assets/images/fifalogo 150x150.png');
const fortnite = require('../game-navbar/assets/images/fortnite 150x150.png');
const hearthstone = require('../game-navbar/assets/images/hearthstone 150x150.png');
const minecraft = require('../game-navbar/assets/images/minecraft 150x150.png');
const overwatch = require('../game-navbar/assets/images/overwatch 150x150.png');
const pubg = require('../game-navbar/assets/images/pubg 150x150.png');
const r6 = require('../game-navbar/assets/images/rainbow six 150x150.png');
const roblox = require('../game-navbar/assets/images/roblox 150x150.png');
const rocketleague = require('../game-navbar/assets/images/rocketleague 150x150.png');
const wow = require('../game-navbar/assets/images/world of warcraft 150x150.png');
const startcraft = require('../game-navbar/assets/images/starcraft II 150x150.png');
const cod = require('../game-navbar/assets/images/call of duty 150x150.png');
const ea = require('../game-navbar/assets/images/ea sports 150x150.png');
const apex = require('../game-navbar/assets/images/ApexLegends 150x150.webp');
const kingofglory = require('../game-navbar/assets/images/KingOfGlory 150x150.webp');
const mlegends = require('../game-navbar/assets/images/mobileLegends 150x150.webp');

const css = `
<style>
  /* Base Styles */
  analyses-homecomponent .ah-component {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      color: #f1f1f1;
      
  }

  analyses-homecomponent .ht-section-header {
      border-bottom: 2px solid #B22A23;
      padding-bottom: 10px;
      margin-bottom: 20px;
  }

  analyses-homecomponent .ht-section-title {
      font-size: 2.5rem;
      color: #f1f1f1;
      position: relative;
  }

  analyses-homecomponent .ht-section-title::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 60px;
      height: 4px;
      background-color: #B22A23;
      border-radius: 2px;
  }

  /* Analysis Card Styles */
  analyses-homecomponent .analysis-card {
      background-color: #1E252E;
      border-radius: 12px;
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      text-decoration: none;
      color: inherit;
  }

  analyses-homecomponent .analysis-card:hover .analysis-summary  , .analysis-card:hover  {
      transform: translateY(-5px);
      cursor:pointer;
      background-color: #3A4B5C;
  }
analyses-homecomponent .analysis-card:hover .analysis-row {
transform: translateY(-5px);
}
  /* Combined Row for Game Logo and Teams */
   analyses-homecomponent .analysis-row {
      display: flex;
      align-items: center;
      background-color: #161b22;
      padding: 0.5rem;
      border-bottom: 1px solid #B22A23;
      justify-content: space-between;
      gap: 0.5rem;
      position: relative;
       transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  }

  /* Game Logo Container */
  analyses-homecomponent  .game-logo-container img {
      
      object-fit: contain;
  }

  /* Teams Container */
  analyses-homecomponent .teams-container {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      gap: 0.5rem;
      flex-wrap: wrap;
  }

  /* Team 1 and Team 2 Containers */
  analyses-homecomponent .team-container {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      flex-shrink: 0;
  }

  /* Team Name */
  analyses-homecomponent  .analysis-team-name {
     
      font-weight: 600;
      color: #f1f1f1;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
  }

  /* Team Logo */
  analyses-homecomponent  .team-logo img {
     
      object-fit: contain;
  }

  /* VS Separator */
  analyses-homecomponent  .vs {
      
      font-weight: 600;
      color: #f1f1f1;
      flex-shrink: 0;
      margin: 0 10px;
  }

  /* Summary */
  analyses-homecomponent  .analysis-summary {
      
      color: #ccc;
      padding: 0;
      margin:0.75rem;
      background-color: #1e252e;
      border-radius: 0 0 12px 12px;
      text-align: left;

      /* Truncate text to 6 lines */
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  }

  /* Responsive Adjustments */
  /* 1. Extra Small Devices: up to 600px */
  @media only screen and (max-width: 600px) {
   analyses-homecomponent .ht-section-title {
         font-size: 1.2rem;
    }
      analyses-homecomponent .analysis-row {
          align-items: flex-start;
          gap: 0.5rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 1.5rem;
          height: 1.5rem;
      }

      analyses-homecomponent .team-logo img {
          width: 1.2rem;
          height: 1.2rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.8rem;
          margin: 0;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.75rem;
          
      }

      analyses-homecomponent .analysis-team-name {
          max-width: 120px;
      }
  }

  /* 2. Small Devices: 600px - 768px */
  @media only screen and (min-width: 600px) and (max-width: 768px) {
  analyses-homecomponent .ht-section-title {
         font-size: 1.5rem;
    }
      analyses-homecomponent .analysis-row {
          flex-direction: row;
          align-items: center;
          gap: 0.4rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 1.05rem;
          height: 1.05rem;
      }

      analyses-homecomponent .team-logo img {
          width: 1.1rem;
          height: 1.1rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.85rem;
          margin: 0 8px;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.8rem;
          
      }

      analyses-homecomponent .analysis-team-name {
         font-size: 0.6rem;
      }
  }

  /* 3. Medium Devices: 768px - 992px */
  @media only screen and (min-width: 768px) and (max-width: 992px) {
  analyses-homecomponent .ht-section-title {
         font-size: 1.2rem;
    }
      analyses-homecomponent .analysis-row {
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 0.7rem;
          height: 0.7rem;
      }

      analyses-homecomponent .team-logo img {
          width: 0.75rem;
          height: 0.75rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.9rem;
          margin: 0 10px;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.6rem;
          
      }

      analyses-homecomponent .analysis-team-name {
          font-size: 0.44rem;
      }
  }

  /* 4. Large Devices: 992px - 1200px */
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
   analyses-homecomponent .ht-section-title {
         font-size: 1.3rem;
    }
      analyses-homecomponent .analysis-row {
          flex-direction: row;
          align-items: center;
          gap: 0.6rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 0.74rem;
          height: 0.74rem;
      }

      analyses-homecomponent .team-logo img {
          width: 0.8rem;
          height: 0.8rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.8rem;
          margin: 0 12px;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.7rem;
          
      }

      analyses-homecomponent .analysis-team-name {
        
          font-size: 0.52rem;
      }
  }

  /* 5. Extra Large Devices: 1200px - 1920px */
  @media only screen and (min-width: 1200px) and (max-width: 1920px) {
  analyses-homecomponent .ht-section-title {
         font-size: 2rem;
    }
      analyses-homecomponent .analysis-row {
          flex-direction: row;
          align-items: center;
          gap: 0.7rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 0.92rem;
          height: 0.92rem;
      }

      analyses-homecomponent .team-logo img {
          width: 1rem;
          height: 1rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.8rem;
          margin: 0 14px;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 0.75rem;
          
      }

      analyses-homecomponent .analysis-team-name {
          font-size: 0.6rem;
      }
  }

  /* 6. Extra Extra Large Devices: 1920px and above */
  @media only screen and (min-width: 1920px) {
  analyses-homecomponent .ht-section-title {
         font-size: 2rem;
    }
      analyses-homecomponent .analysis-row {
          flex-direction: row;
          align-items: center;
          gap: 0.8rem;
      }

      analyses-homecomponent .game-logo-container img {
          width: 1.4rem;
          height: 1.4rem;
      }

      analyses-homecomponent .team-logo img {
          width: 1.5rem;
          height: 1.5rem;
      }

      analyses-homecomponent .vs {
          font-size: 0.9rem;
          margin: 0 16px;
      }

      analyses-homecomponent .analysis-summary {
          font-size: 1rem;
          
      }

      analyses-homecomponent .analysis-team-name {
          font-size: 0.8rem;
      }
  }
</style>
`;

export class AnalysesHomecomponent extends BaseComponent {

    initCallback() {
        // Inject CSS into the document head
        document.head.insertAdjacentHTML("beforeend", css);
        // Initialize the component
        RouterAssistant.checkForSingleContainer('analyses-homecomponent', AnalysesHomecomponent.initiator);
    }

    static initiator() {
        // Fetch analyses data and update the component
        AnalysesHomecomponent.getAnalyses('fromHomeWidgetComponent', function () {
            if (document.querySelectorAll('#latestAnalysesList').length !== 0) {
                const activeGameFilterId = GameNavbar.getCurrentlyActivatedGameIfAny();
                AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
            }
        });

        // Set up translations
        AnalysesHomecomponent.translations();

        // Subscribe to game filter changes
        GameNavbar.subscribeToGameFilterChanges('fromAiAnalyses', (activeGameFilterId) => {
            console.debug(activeGameFilterId);
            if (document.querySelectorAll('#latestAnalysesList').length !== 0) { // Ensure the component exists on the page
                AnalysesHomecomponent.updateAnalyses(activeGameFilterId);
            }
        });
    }

    static updateAnalyses(activeGameFilterId) {
        const gameIdImg = {
            '3': csgologo,
            '4': dota2,
            '1': lol,
            '25': ea,
            '26': valorant,
            '23': cod,
            '14': overwatch,
            '20': pubg,
            '24': r6,
            '22': rocketleague,
            '29': startcraft,
            '30': startcraft,
            '27': kingofglory,
            '34': mlegends
        };

        const gameIdFilter = {
            '3': 'csgo',
            '4': 'dota2',
            '1': 'lol',
            '25': 'ea',
            '26': 'valorant',
            '23': 'cod',
            '14': 'overwatch',
            '20': 'pubg',
            '24': 'r6',
            '22': 'rocketleague',
            '29': 'startcraft',
            '30': 'startcraft',
            '27': 'kingofglory',
            '34': 'mlegends'
        };

        const list = document.getElementById('latestAnalysesList');
        list.innerHTML = '';

        let latestAnalyses = [];
        if (activeGameFilterId !== null && activeGameFilterId !== 'null') {
            latestAnalyses = window.savedAnalyses.filter(doc => gameIdFilter[doc.data().gameId] === activeGameFilterId);
        } else {
            latestAnalyses = window.savedAnalyses.slice(0, 5);
        }

        console.debug(latestAnalyses);
        if (latestAnalyses.length === 0) {
            list.innerHTML = /*html*/`
                    <div class="mt-3 analysisFilterNotMsg" style="text-align: center; padding: 20px; background-color: #161b22; border-color: #f5c6cb; border-radius: .25rem;">
                    </div>
                    `;
            if (document.querySelector('.gameNameActive') && document.querySelector('.gameNameActive') !== null) {
                document.querySelector('.analysisFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyAnalysisFilterMessage', { analysisGame: document.querySelector('.gameNameActive').innerText });
            } else {
                document.querySelector('.analysisFilterNotMsg').innerHTML = I18n.translateString('matchTranslations.emptyAnalysisFilterMessage', { analysisGame: '' });
            }
            return;
        }

        for (let doc of latestAnalyses) {
            const clickBaitString = PostMatchesComponent.extractFirstTextBlock(doc.data().output);
            if (doc.id.split('-')[1] !== I18n.getUsersPreferredLanguage()) continue;
            if (clickBaitString.length === 0) continue;

            const image1 = (doc.data().team1logo !== '') ? doc.data().team1logo : noImage;
            const image2 = (doc.data().team2logo !== '') ? doc.data().team2logo : noImage;
            const team1name = (doc.data().opName1) ? doc.data().opName1 : '';
            const team2name = (doc.data().opName2) ? doc.data().opName2 : '';
            const team1slug = (doc.data().opSlug1) ? doc.data().opSlug1 : '';
            const team2slug = (doc.data().opSlug2) ? doc.data().opSlug2 : '';
            const gameId = doc.data().gameId;

            list.insertAdjacentHTML("beforeend", /*html*/`
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-4 mb-4">
                    <a href="${window.location.origin}/s_match?match=${doc.id.split('-')[0]}" id="analysis-${doc.id.split('-')[0]}" class="analysis-card">
                        <div class="analysis-row">
                            <!-- Game Logo on the Left -->
                            <div class="game-logo-container">
                                <img src="${gameIdImg[gameId]}" alt="${gameIdFilter[gameId]} Logo" class="img-fluid">
                            </div>
                            
                            <!-- Teams Section on the Right -->
                            <div class="teams-container">
                                <!-- Team 1 -->
                                <div class="team-container">
                                    <span class="analysis-team-name text-truncate">${team1slug}</span>
                                    <div class="team-logo ms-1">
                                        <img src="${image1}" alt="${team1name} Logo" class="img-fluid">
                                    </div>
                                </div>
                                
                                <!-- VS Separator -->
                                <div class="vs">VS</div>
                                
                                <!-- Team 2 -->
                                <div class="team-container">
                                    <div class="team-logo me-1">
                                        <img src="${image2}" alt="${team2name} Logo" class="img-fluid">
                                    </div>
                                    <span class="analysis-team-name text-truncate">${team2slug}</span>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Summary -->
                        <div class="analysis-summary">
                            ${clickBaitString}
                        </div>
                    </a>
                </div>
            `);

            // Add click event listener
            document.getElementById(`analysis-${doc.id.split('-')[0]}`).addEventListener('click', MiscAssistant.MyEsportsClick(function () {
                RouterAssistant.redirect('s_match', {
                    match: doc.id.split('-')[0]
                });
            }));
        }
    }

    static getAnalyses(callbackName, callback) {
        if (window.getAnalysesEventListeners === undefined) {
            window.getAnalysesEventListeners = {};
            window.getAnalysesEventListeners[callbackName] = callback;
            FirebaseAssistant.getAnalysesV2(querySnapshot => {
                console.debug('running initial firebase getAnalyses() query snapshot');
                window.savedAnalyses = querySnapshot;
                Object.keys(window.getAnalysesEventListeners).forEach(eventListenerName => {
                    window.getAnalysesEventListeners[eventListenerName]();
                });
            });
        } else if (window.savedAnalyses === undefined) {
            setTimeout(() => {
                AnalysesHomecomponent.getAnalyses(callbackName, callback);
            }, 50);
        } else {
            window.getAnalysesEventListeners[callbackName] = callback;
            callback();
        }
    }

    static translations() {
        const titleElement = document.getElementById('analysesHomeTitle');
        if (titleElement) {
            titleElement.innerHTML = I18n.translateString('homePage.latestAnalyses');
        }
    }
}

AnalysesHomecomponent.template = /*html*/`
<div id="analysesFinalHtml" class="container ah-component mt-4">
    <div class="ht-section-header d-flex justify-content-between align-items-center mb-4">
        <h2 id="analysesHomeTitle" class="ht-section-title">RECENT AI ANALYSIS</h2>
    </div>
  
    <div id="latestAnalysesList" class="row"> </div>
</div>
`;

AnalysesHomecomponent.reg('analyses-homecomponent'); // Components should always be 2 words separated with a dash
