
// postbookmakersskin-component.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { I18n } from "../../utilities/i18n/i18n";
import showdown from 'showdown';

const css = `
<style>
postbookmakersskin-component {
    display: block;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    background: #0f1219;
}
/* Top container styles */
postbookmakersskin-component .top {
    background: linear-gradient(135deg, #1b222a 0%, #0f1219 100%);
    border-radius: 10px;
    padding: 20px 15px;
    text-align: center;
    position: relative;
    margin-bottom: 40px;
}

postbookmakersskin-component .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}
postbookmakersskin-component .image-wrapper img{
    width: 180px;
    height: auto;
    transition: transform 0.3s ease;
}
postbookmakersskin-component .image-wrapper img:hover {
    transform: scale(1.05);
}

postbookmakersskin-component .text-white {
    color: #fff !important;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: uppercase;
}

postbookmakersskin-component .text-rating-resize {
    background-color: #f5c518;
    color: #000000;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    font-weight: 800;
    display: inline-block;
    margin-bottom: 15px;
}
postbookmakersskin-component .text-rating-resize i {
    color: #000000;
    margin-left: 5px;
}

postbookmakersskin-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: white !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none !important;
    display: inline-block;
}
postbookmakersskin-component .cta-col-action .btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* Intro Section (Review) */
postbookmakersskin-component .intro-section {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-bottom: 2rem;
}

postbookmakersskin-component .intro-section .title-section {
    font-size: calc(0.5rem + 1.5vw);
    color: #4CAF50;
    margin-bottom: 2rem;
    font-weight: 700;
    letter-spacing: 1px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(76,175,80,0.3);
}

postbookmakersskin-component .content-section {
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    line-height: 1.7;
    background: #1e252e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(76,175,80,0.05);
}

postbookmakersskin-component .content-section p {
    font-size: calc(0.5rem + 0.5vw);
    margin-bottom: 1rem;
}
postbookmakersskin-component .content-section h1 {
    font-size: calc(0.5rem + 1.5vw);
}
postbookmakersskin-component .content-section h2 {
    font-size: calc(0.5rem + 1vw);
}
postbookmakersskin-component .content-section li {
    font-size: calc(0.5rem + 0.5vw);
}

postbookmakersskin-component .content-section a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px dashed #4CAF50;
    transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

/* Headings inside content */
postbookmakersskin-component .content-section h1,
postbookmakersskin-component .content-section h2,
postbookmakersskin-component .content-section h3,
postbookmakersskin-component .content-section h4,
postbookmakersskin-component .content-section h5,
postbookmakersskin-component .content-section h6 {
    color: #4CAF50;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* Lists */
postbookmakersskin-component .content-section ul,
postbookmakersskin-component .content-section ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    color: #D3D3D3;
}

postbookmakersskin-component .content-section li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;
    list-style: none;
}

/* Add a star before each li item */
postbookmakersskin-component .content-section li::before {
    content: "★";
    color: #4CAF50;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0.1rem;
}

/* Code Blocks */
postbookmakersskin-component .content-section code {
    background: rgba(76,175,80,0.1);
    color: #66FF99;
    font-family: 'Source Code Pro', monospace;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9rem;
}
postbookmakersskin-component .content-section pre code {
    display: block;
    padding: 1rem;
    background: #0f1219;
    border: 1px solid rgba(76,175,80,0.3);
    border-radius: 5px;
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 1.5rem;
}

/* Banner Section (CTA) */
postbookmakersskin-component .banner-section {
    position: relative;
    margin-top: 40px;
    background: linear-gradient(135deg, #1e282e 0%, #0f1219 100%);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    border: 2px dashed rgba(255,255,255,0.3);
}

postbookmakersskin-component .banner-section img {
    max-width: 100px;
    height: auto;
    display: inline-block;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}
postbookmakersskin-component .banner-section img:hover {
    transform: scale(1.1);
}

postbookmakersskin-component .banner-text {
    display: block;
    color: #4CAF50;
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

postbookmakersskin-component .banner-section .betnow {
    background-color: #dc3545;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: 0 4px 10px rgba(220,53,69,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
    display: inline-block;
}
postbookmakersskin-component .banner-section .betnow:hover {
    background-color: #c32f3d;
    transform: translateY(-2px);
}



</style>
`;

export class PostBookmakersSkinComponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('postbookmakersskin-component', PostBookmakersSkinComponent.initiator);
    }

    static getBookmakersSkin(callbackName, callback) {
        if (window.getBookmakersSkinEventListeners === undefined) {
            window.getBookmakersSkinEventListeners = {};
            window.getBookmakersSkinEventListeners[callbackName] = callback;
            FirebaseAssistant.getAllBookmakersSkins((dataReceived) => {
                console.debug('running firebase getAllSkinBookmakers() query snapshot');
                dataReceived.forEach(single => {
                    const data = single.data();
                    if (window.savedBookmakersSkins === undefined) {
                        window.savedBookmakersSkins = { [single.id]: data };
                    } else {
                        window.savedBookmakersSkins[single.id] = data;
                    }
                });
                Object.keys(window.getBookmakersSkinEventListeners).forEach(eventListenerName => {
                    window.getBookmakersSkinEventListeners[eventListenerName]();
                });
            });
        } else if (window.savedBookmakersSkins === undefined) {
            setTimeout(() => {
                PostBookmakersSkinComponent.getBookmakersSkin(callbackName, callback);
            }, 50);
        } else {
            window.getBookmakersSkinEventListeners[callbackName] = callback;
            callback();
        }
    }

    static initiator() {
        PostBookmakersSkinComponent.getBookmakersSkin('fromBookmakersSkinComponent', () => {
            if (window.location.href.includes('s_bookmaker_skin') || document.querySelector('#s_bookmaker_skin')) {
                console.debug('PostBookmakersSkinComponent callback is running.');
                PostBookmakersSkinComponent.updateSkinBookmakers();
            } else {
                console.debug('PostBookmakersSkinComponent callback is supposed to run but I am not on the correct page!!!');
            }
        });
    }

    static updateSkinBookmakers() {
        const bookmakerSkinId = RouterAssistant.getCurrentVariableValue('skin_bookmaker');
        let data = null;

        if (window.savedBookmakersSkins !== undefined && window.savedBookmakersSkins[bookmakerSkinId]) {
            data = window.savedBookmakersSkins[bookmakerSkinId];
        } else {
            console.error('Bookmaker Skin data not found for ID:', bookmakerSkinId);
            return;
        }

        console.debug('Retrieved Bookmaker Skin Data:', data);

        const userLanguage = I18n.getUsersPreferredLanguage() || 'en';
        console.debug('User Preferred Language:', userLanguage);

        const getLocalizedField = (field) => {
            if (field && typeof field === 'object') {
                return field[userLanguage] || field['en'] || '';
            }
            return field || '';
        };

        const bookmakerSkinName = getLocalizedField(data.bookmaker_skin_name || data.name);
        const bookmakerSkinAbout = getLocalizedField(data.bookmaker_skin_about || data.about);
        const bookmakerSkinLogo = data.bookmaker_skin_logo || '';
        const bookmakerSkinColor = data.bookmaker_skin_color || '#f5c518';
        const bookmakerSkinBonusUrl = data.bookmaker_skin_bonus_url || '#';
        const bookmakerSkinBonusText = (data.bonus_text && data.bonus_text[userLanguage]) || '';
        const bookmakerSkinBonusDetails = (data.bonus_details && data.bonus_details[userLanguage]) || '';
        const bookmakerSkinRating = data.bookmaker_skin_rating || '0';

        const converter = new showdown.Converter();
        const bookmakerSkinAboutContent = converter.makeHtml(bookmakerSkinAbout);
        console.debug('Converted Bookmaker Skin About Content:', bookmakerSkinAboutContent);
        const fullReviewText = I18n.translateString('bookmakersSkinPostTranslations.fullReview');
        const registerText = I18n.translateString('bookmakersSkinPostTranslations.register');
        const bkskinHtml = /*html*/`
         <div class="postbookmakersskin-component container my-4">
            <div class="top" style="background-color: ${bookmakerSkinColor}">
              <div class="image-wrapper mt-2">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer">
                  <img src="${bookmakerSkinLogo}" alt="${bookmakerSkinName} Logo" >
                </a>
              </div>
              <div class="mt-2">
                <span class="text-white">${bookmakerSkinName}</span>
              </div>
              <div class="mt-2">
                <span class="text-rating-resize">
                  <span>${bookmakerSkinRating} / 5</span>
                  <i class="bi bi-star-fill"></i>
                </span>
              </div>
              <div class="my-2 cta-col-action">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer" class="btn">${registerText}</a>
              </div>
            </div>

            <!-- Intro Section -->
            <div class="intro-section">
              <h1 class="title-section">${bookmakerSkinName} - ${fullReviewText}</h1>
              <div class="content-section">
                ${bookmakerSkinAboutContent}
              </div>
            </div>

            <!-- Banner Section -->
            <div class="banner-section">
              <div class="image-wrapper">
                <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer">
                  <img src="${bookmakerSkinLogo}" alt="${bookmakerSkinName}">
                </a>
              </div>
              <span class="banner-text">${bookmakerSkinBonusText}</span>
              <span class="banner-text">${bookmakerSkinBonusDetails}</span>
              <a href="${bookmakerSkinBonusUrl}" target="_blank" rel="noopener noreferrer" class="betnow">${registerText}</a>
            </div>
          </div>
        `;

        const containerElement = document.querySelector('#s_bookmaker_skin');
        if (containerElement) {
            containerElement.insertAdjacentHTML('beforeend', bkskinHtml);
            console.debug('Bookmaker Skin HTML inserted into #s_bookmaker_skin.');
        } else {
            console.error('Container element #s_bookmaker_skin not found in the DOM.');
            return;
        }
    }
}

PostBookmakersSkinComponent.template = /*html*/`
  <div id="s_bookmaker_skin"></div>
`;

PostBookmakersSkinComponent.reg('postbookmakersskin-component'); // components should always be 2 words separated with a dash
