import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { I18n } from "../../utilities/i18n/i18n";

const css = `
<style>
/* Widget Component Styles */
widget-bookmakers-skin- {
    background-color: #0D1117;
    color: #D3D3D3;
    font-family: 'Roboto', sans-serif;
}

/* Widget Container */
widget-bookmakers-skin .widget-container {
    width: 100%;
}

/* Title Style */
widget-bookmakers-skin .widget-title {
    color: #D3D3D3;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
    padding-bottom: 10px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Bookmaker Card */
widget-bookmakers-skin .bookmaker-card-skin {
    display: flex;
    align-items: center;
    background-color: #252E39;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 12px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3), -3px -3px 6px rgba(30, 33, 50, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
widget-bookmakers-skin .bookmaker-card-skin:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
}

/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
    background-color: #1B222A;
    
    border-radius: 8px;
    padding: 10px;
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

/* Bookmaker Details */
widget-bookmakers-skin .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1.1rem;
    color: #E7EAF2;
    font-weight: bold;
    margin-bottom: 5px;
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 1rem;
    color: #A6A9B3;
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
    background-color: #28A745;
    color: white !important;
    font-weight: bold;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 0px 8px rgba(40, 167, 69, 0.5);
}
widget-bookmakers-skin .cta-button:hover {
    background-color: #21a039;
    box-shadow: 0px 0px 15px rgba(40, 167, 69, 0.8);
}

/* "See All" Button */
widget-bookmakers-skin .see-all-button {
    background-color: transparent;
    border: 1px solid #D3D3D3;
    color: #D3D3D3;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}
widget-bookmakers-skin .see-all-button:hover {
    background-color: #D3D3D3;
    color: #0D1117;
}
/* 1. Extra Small Devices: up to 600px */
@media only screen and (max-width: 600px) {



widget-bookmakers-skin .widget-title { 
    font-size: 1rem;  
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
 
    width: 100px;
    height: 60px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1rem;
   
    
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 0.9rem;
   

   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
    padding: 6px 10px;
    font-size: 0.8rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 6px 10px;
    
    font-size: 0.7rem;
   
}
}

/* 2. Small Devices: 600px - 768px */
@media only screen and (min-width: 600px) and (max-width: 768px) {



widget-bookmakers-skin .widget-title { 
    font-size: 1rem;  
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
 
    width: 100px;
    height: 60px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1rem;
   
    
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 0.9rem;
   

   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
    padding: 6px 10px;
    font-size: 0.8rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 6px 10px;
    
    font-size: 0.7rem;
   
}
}

/* 3. Medium Devices: 768px - 1099px */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
widget-bookmakers-skin .bookmaker-card-skin{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
}


widget-bookmakers-skin .widget-title { 
    font-size: 0.75rem;  
    text-align:center;
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
    margin:0;
    width: 80px;
    height: 40px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1rem;
    text-align: center;
     margin-top:5px;
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 0.9rem;
    text-align: center;
   margin-bottom:10px;
   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
    padding: 6px 10px;
    font-size: 0.8rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 6px 10px;
    
    font-size: 0.7rem;
   
}

}
/* 4. Large Devices: 1100px - 1319px */
@media only screen and (min-width: 1100px) and (max-width: 1319px) {
widget-bookmakers-skin .bookmaker-card-skin{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
}


widget-bookmakers-skin .widget-title { 
    font-size: 0.91rem;  
    text-align:center;
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
    margin:0;
    width: 100px;
    height: 60px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1.1rem;
    text-align: center;
     margin-top:5px;
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 1rem;
    text-align: center;
   margin-bottom:10px;
   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
    padding: 10px 14px;
    font-size: 0.9rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 5px 10px;
    
    font-size: 0.75rem;
   
}
}

/* 5. Extra Large Devices: 1320px - 1799px */
@media only screen and (min-width: 1320px) and (max-width: 1799px) {


widget-bookmakers-skin .bookmaker-card-skin{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
}


widget-bookmakers-skin .widget-title { 
    font-size: 1.14rem;  
    text-align:center;
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
    margin:0;
    width: 100px;
    height: 60px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1.1rem;
    text-align: center;
     margin-top:5px;
}
widget-bookmakers-skin .bonus-text-skin {
    font-size: 1rem;
    text-align: center;
   margin-bottom:10px;
   
   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
    padding: 10px 14px;
    font-size: 0.9rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 6px 12px;
    
    font-size: 0.9rem;
   
}

}

/* 6. Extra Extra Large Devices: 1800 and above */
@media only screen and (min-width: 1800px) {


widget-bookmakers-skin .widget-title { 
    font-size: 1.4rem;  
    
}


/* Bookmaker Logo */
widget-bookmakers-skin .bookmakerskin-logo-wrapper {
 
    width: 100px;
    height: 60px;
 
}
widget-bookmakers-skin .bookmakerskin-logo-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

widget-bookmakers-skin .bookmaker-name-skin {
    font-size: 1.1rem;
   
    
}
widget-bookmakers-skin .bonus-text-skin {
   font-size: 1rem;
   margin-right: 7px;

   
}

/* CTA Button */
widget-bookmakers-skin .cta-button {
   
   padding: 10px 14px;
    font-size: 0.9rem;
    
}


/* "See All" Button */
widget-bookmakers-skin .see-all-button {
   
    padding: 6px 12px;
    
    font-size: 0.9rem;
   
}
}
</style>
`;

export class WidgetBookmakersSkin extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css);
        RouterAssistant.checkForSingleContainer('widget-bookmakers-skin', WidgetBookmakersSkin.initiator);
        WidgetBookmakersSkin.translations();
    }

    static translations() {
        document.querySelectorAll('.bookmakers-skins-title').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersSkinList.widgetTitle");
        });
        document.querySelectorAll('#see-all-bookmakers-button-skin').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersSkinList.widgetButton");
        });
    }

    static dynamicTranslations() {
        document.querySelectorAll('.play-bookmakers-skins-button').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersSkinList.playNow");
        });
    }

    static initiator() {
        FirebaseAssistant.getAllBookmakersSkins((dataReceived) => {

            GeofencingGuard.getUserCountry(function (userCurrentCountry) {
                const userCurrentLanguage = I18n.getUsersPreferredLanguage();

                const docs = dataReceived.docs ? dataReceived.docs : dataReceived;


                const sortedData = docs.sort((a, b) => {
                    const orderA = a.data().order_skin_bookmaker !== undefined ? a.data().order_skin_bookmaker : Infinity;
                    const orderB = b.data().order_skin_bookmaker !== undefined ? b.data().order_skin_bookmaker : Infinity;
                    return orderA - orderB;
                });


                let html = '';

                let count = 0;
                sortedData.forEach((single, index) => {

                    if (index > 4) { //showing only top 5 skin-bookmakers on the widget
                        return;
                    }

                    const bookmakerData = single.data();

                    // if (!bookmakerData.location_bookmaker.includes(userCurrentCountry)) return;
                    // if (bookmakerData.language_bookmaker != userCurrentLanguage) return;
                    if (bookmakerData.status === 'draft') return;

                    // Extract data with language consideration
                    const bookmakerName = bookmakerData.bookmaker_skin_name || '';
                    const bookmakerLogo = bookmakerData.bookmaker_skin_logo || '';
                    const bookmakerBonusText = (bookmakerData.bookmaker_skin_hot_bonus && bookmakerData.bookmaker_skin_hot_bonus[userCurrentLanguage]) || '';
                    const bookmakerUrl = bookmakerData.bookmaker_skin_bonus_url || '#';


                    // Generate HTML
                    html += /*html*/ `
                    <div class="bookmaker-card-skin">
                        <div class="bookmakerskin-logo-wrapper clickable" id="bookmaker-${single.id}">
                            <img src="${bookmakerLogo}" alt="${bookmakerName} Logo">
                        </div>
                        <div class="details">
                            <div class="bonus-text-skin">${bookmakerBonusText}</div>
                        </div>
                        <a href="${bookmakerUrl}" class="play-bookmakers-skins-button cta-button" target="_blank" rel="noopener noreferrer">Play Now</a>
                    </div>
                    `;

                    count++;
                    // Limit to 5 bookmakers for the widget
                    if (count >= 5) return;
                });

                if (document.querySelector('#widgetbookmakers-container-skin')) {
                    document.querySelector('#widgetbookmakers-container-skin').innerHTML = html;
                    // Add event listener for the "See All" button
                    WidgetBookmakersSkin.addEventListeners();
                    WidgetBookmakersSkin.dynamicTranslations();
                }
            });
        });
    }

    static addEventListeners() {
        const seeAllButton = document.querySelector('#see-all-bookmakers-button-skin');
        if (seeAllButton) {
            seeAllButton.addEventListener('click', function (evt) {
                evt.preventDefault();
                RouterAssistant.redirect('skin_platforms');
            });
        }

        const bookmakerComp = document.querySelectorAll('.bookmakerskin-logo-wrapper');
        for (let i = 0; i < bookmakerComp.length; i++) {
            bookmakerComp[i].addEventListener('click', function (evt) {
                evt.preventDefault();
                evt.stopPropagation();
                const bookmakerId = this.id.split('-')[1];
                RouterAssistant.redirect('s_bookmaker_skin', {
                    skin_bookmaker: bookmakerId
                });
            });
        }
    }
}

WidgetBookmakersSkin.template = /*html*/ `
<div class="widget-container mt-4">
    <div class="widget-title">
        <span class="bookmakers-skins-title">Top 5 Skin Platforms</span>
        <button id="see-all-bookmakers-button-skin" class="see-all-button">See All</button>
    </div>
    <!-- Bookmakers List -->
    <div id="widgetbookmakers-container-skin"></div>
</div>
`;

WidgetBookmakersSkin.reg('widget-bookmakers-skin');
