import { BaseComponent } from "@symbiotejs/symbiote";
import discLogo from './discord-white-icon.png'; // Ensure the path is correct

const css = `
  <style>


    /* Container Styling */
    discord-template .widget-container {
      background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
      border-radius: 16px;
      padding: 25px;
      color: #ffffff;
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      text-decoration: none; /* Ensure link styles don't interfere */
      cursor: pointer;
    }

    /* Hover effect for container */
    discord-template .widget-container:hover::before {
      opacity: 1;
    }

    discord-template .widget-container:hover {
      transform: translateY(-10px);
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6);
    }

    /* Header Styling */
    discord-template .widget-header {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    /* Image Wrapper Styling */
    discord-template .widget-header .image-wrapper {
      width: 60px; /* Fixed size for the wrapper */
      height: 60px;
      border-radius: 50%;
      border: 3px solid #5865F2;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 20px;
      position: relative;
      background-color: transparent; /* Optional: Background color for the wrapper */
      animation: pulse 2s infinite;
      
    }

    discord-template .widget-header .image-wrapper img {
      width: 40px; /* Smaller size for the image */
      height: 40px;
      object-fit: contain;
      transition: transform 0.5s ease;
      
    }

    discord-template .widget-header .image-wrapper img:hover {
      transform: rotate(360deg);
    }

    discord-template .widget-header .titles {
      display: flex;
      flex-direction: column;
    }

    discord-template .widget-header .titles .title-main {
      font-size: 1.8rem;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    discord-template .widget-header .titles .title-sub {
      font-size: 1.2rem;
      color: #b9bbbe;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

  

    

    discord-template .widget-action {
      font-size: 2rem;
      color: #5865F2;
      transition: color 0.3s ease, transform 0.3s ease;
    }

    discord-template .widget-action:hover {
      color: #7289da;
      transform: translateX(5px);
    }

    /* Animations */
    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(88, 101, 242, 0.7);
      }
      70% {
        box-shadow: 0 0 0 20px rgba(88, 101, 242, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(88, 101, 242, 0);
      }
    }

    
    @media only screen and (max-width: 600px) {
       discord-template .widget-container {
         padding: 15px;
       }

       discord-template .widget-header .image-wrapper {
         width: 40px; /* Reduced size for smaller screens */
         height: 40px;
         margin-right: 10px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 25px; /* Further reduced size */
         height: 25px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 1.2rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 0.9rem;
       }

       discord-template .widget-content {
         padding: 10px;
       }

     
     }

     @media only screen and (min-width: 600px) and (max-width: 768px) {
       discord-template .widget-container {
         padding: 18px;
       }

       discord-template .widget-header .image-wrapper {
         width: 45px;
         height: 45px;
         margin-right: 15px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 30px;
         height: 30px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 1.4rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 1.1rem;
       }

       discord-template .widget-content {
         padding: 12px;
       }

     }

     @media only screen and (min-width: 768px) and (max-width: 992px) {
         discord-template .widget-container {
         padding: 10px;
       }

       discord-template .widget-header .image-wrapper {
         width: 50px;
         height: 50px;
         margin-right: 10px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 30px;
         height: 30px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 0.9rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 0.7rem;
       }

     
     }

     @media only screen and (min-width: 992px) and (max-width: 1200px) {
       discord-template .widget-container {
         padding: 15px;
       }

       discord-template .widget-header .image-wrapper {
         width: 50px;
         height: 50px;
         margin-right: 10px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 30px;
         height: 30px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 1rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 0.8rem;
       }

       

     
     }

     @media only screen and (min-width: 1200px) and (max-width: 1920px) {
       discord-template .widget-container {
         padding: 15px;
       }

       discord-template .widget-header .image-wrapper {
         width: 50px;
         height: 50px;
         margin-right: 15px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 35px;
         height: 35px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 1.1rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 1rem;
       }

       discord-template .widget-content {
         padding: 20px;
       }

     
     }

     @media only screen and (min-width: 1920px) {
       discord-template .widget-container {
         padding: 30px;
       }

       discord-template .widget-header .image-wrapper {
         width: 70px;
         height: 70px;
         margin-right: 25px;
       }

       discord-template .widget-header .image-wrapper img {
         width: 45px;
         height: 45px;
       }

       discord-template .widget-header .titles .title-main {
         font-size: 1.7rem;
       }

       discord-template .widget-header .titles .title-sub {
         font-size: 1rem;
       }

       discord-template .widget-content {
         padding: 25px;
       }

     
     }
  </style>
`;

export class DiscordTemplate extends BaseComponent {
  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
  }
}

DiscordTemplate.template = /*html*/ `
  <a href="https://discord.gg/3sU4u59qze" target="_blank" class="widget-container" aria-label="Join our Discord server">
    <div class="widget-header">
      <div class="image-wrapper">
        <img src="${discLogo}" alt="Discord Logo">
      </div>
      <div class="titles">
        <div class="title-main">Need Help?</div>
        <div class="title-sub">Join our Discord now!</div>
      </div>
    </div>
  </a>
`;

DiscordTemplate.reg('discord-template');
