// bookmakers-skin-component.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { I18n } from "../../utilities/i18n/i18n";

// Updated CSS with Media Queries and Bootstrap Integration
const css = `
<style>
   bookmakers-skin-component .container{
max-width: 100% !important;
}
bookmakers-skin-component .intro-section p {
    color: #A0A3A7;
    font-size: 1rem;
    line-height: 1.5;
}

bookmakers-skin-component .cta-row {
    display: flex;
    align-items: center;
    background-color: #2B2F38;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

bookmakers-skin-component .cta-row:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
}

bookmakers-skin-component .cta-col {
    flex: 1;
    padding: 10px;
}

bookmakers-skin-component .cta-col-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #1E232D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 1rem;
}

bookmakers-skin-component .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
    background-color: #1B222A;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 10px rgb(55,69,85);
    margin-right: 15px;
    cursor: pointer;
}

bookmakers-skin-component .image-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

bookmakers-skin-component .cta-col-bonus .bonus-text  {
    color: #4CAF50;
    font-weight: bold;
    font-size: 1.2rem;
}

bookmakers-skin-component .cta-col-bonus .bonus-details {
    color: #b5b5b5;
    font-size: 0.9rem;
}

bookmakers-skin-component .cta-col-info {
    text-align: left;
    color: #A0A3A7;
    padding-left: 15px;
}

bookmakers-skin-component .cta-col-info a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}
bookmakers-skin-component .text-a-style{
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
}
bookmakers-skin-component .cta-col-info .cta-list-items {
    font-size: 0.9rem;
    line-height: 1.5;
}

bookmakers-skin-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: white !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
}

bookmakers-skin-component .cta-col-action .btn:hover {
    background-color: #45a049;
}

bookmakers-skin-component .title-section {
    font-weight: bold;
    font-size: 1.5rem;
    color: #ffffff;
}

/* Media Queries */

/* 1. Extra Small Devices: Smartphones (portrait and landscape) */
@media only screen and (max-width: 600px) {
 
    bookmakers-skin-component .cta-col-info .cta-list-items {
    font-size: 0.7rem;
   }
    bookmakers-skin-component .cta-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    bookmakers-skin-component .cta-col-rank {
       display: none !important;
    }

    bookmakers-skin-component .image-wrapper {
        width: 100%;
        height: 50px;
        margin-bottom: 0;
    }
    bookmakers-skin-component .image-wrapper img {
        width: 100px;
        height: auto;
        margin-bottom: 0;
    }
    bookmakers-skin-component .cta-col-action {
    text-align: center;
    }
    bookmakers-skin-component .cta-col , bookmakers-skin-component .cta-review-title{
    text-align: center;
    }
    bookmakers-skin-component .cta-col-action .btn {
        width: 30%;
        text-align: center; 
        padding: 5px 10px;
     }

    /* Hide cta-list-items on mobile */
    bookmakers-skin-component .cta-col-info .cta-list-items {
        display: none;
    }
}

/* 2. Small Devices: Smartphones (landscape) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
   bookmakers-skin-component .title-section {
    font-size: 0.8rem;
    }
    bookmakers-skin-component .cta-col-info .cta-list-items {
    font-size: 0.7rem;
   }
    bookmakers-skin-component .cta-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    bookmakers-skin-component .cta-col-rank {
       display: none !important;
    }

    bookmakers-skin-component .image-wrapper {
        width: 100%;
        height: 50px;
        margin-bottom: 0;
    }
    bookmakers-skin-component .image-wrapper img {
        width: 100px;
        height: auto;
        margin-bottom: 0;
    }
    bookmakers-skin-component .cta-col-action {
    text-align: center;
    }
    bookmakers-skin-component .cta-col , bookmakers-skin-component .cta-review-title{
    text-align: center;
    }
    bookmakers-skin-component .cta-col-action .btn {
        width: 30%;
        text-align: center;
        padding: 6px 12px;
    }

    /* Hide cta-list-items on mobile */
    bookmakers-skin-component .cta-col-info .cta-list-items {
        display: none;
    }
}
/* 3. Medium Devices: Tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    bookmakers-skin-component .cta-col-bonus .bonus-text {
    font-size: 0.9rem;
   } 
    bookmakers-skin-component .cta-col-bonus .bonus-details {
    font-size: 0.8rem;
   } 
   bookmakers-skin-component .cta-col-info .cta-review-title {
    font-size: 0.9rem;
   }
    bookmakers-skin-component .cta-col-info .cta-list-items {
    font-size: 0.8rem;
   }
    bookmakers-skin-component .image-wrapper {
        width: 100px;
        height: 60px;
    }

    bookmakers-skin-component .cta-col-action .btn {
        padding: 5px 7px;
        font-size:0.9rem;
    }
}
/* 4. Large Devices: Desktops */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    bookmakers-skin-component .image-wrapper {
        width: 110px;
        height: 65px;
    }

    bookmakers-skin-component .cta-col-action .btn {
        padding: 10px 20px;
    }
}

/* 5. Extra Large Devices: Large Desktops */
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
    bookmakers-skin-component .image-wrapper {
        width: 120px;
        height: 70px;
    }

    bookmakers-skin-component .cta-col-action .btn {
        padding: 10px 20px;
    }
}

/* 6. Ultra Large Devices: Very Large Desktops */
@media only screen and (min-width: 1920px) {
    bookmakers-skin-component .image-wrapper {
        width: 130px;
        height: 75px;
    }

    bookmakers-skin-component .cta-col-action .btn {
        padding: 10px 40px;
    }
}
</style>
`;

export class BookmakersSkinComponent extends BaseComponent {

    initCallback() {
        // Ensure the root element has the correct container and component class
        RouterAssistant.checkForSingleContainer('bookmakers-skin-component', BookmakersSkinComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css);
        BookmakersSkinComponent.mainTranslations();
    }

    static mainTranslations() {
        document.querySelector('.main-intro').innerHTML = I18n.translateString("bookmakersSkinList.introSection");
        document.querySelector('.where-to').innerHTML = I18n.translateString("bookmakersSkinList.sectionTitle");
        document.querySelector('.safe-bettting').innerHTML = I18n.translateString("bookmakersSkinList.pleaseBetResponsibly");
        document.querySelector('.review-methodology').innerHTML = I18n.translateString("bookmakersSkinList.reviewMethodology");
    }

    static dynamicTranslations() {
        document.querySelectorAll('.raw-review').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersSkinList.review");
        });
        document.querySelectorAll('.raw-betNow').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersSkinList.betNow");
        });
    }

    static initiator() {
        BookmakersSkinComponent.getBookmakersSkin('fromBookmakersSkinComponent', () => {
            if (document.querySelector('#all-bookmakers-skin')) {
                console.debug('I am running the Bookmakers Skin component callback!');
                BookmakersSkinComponent.updateBookmakersSkin();
            } else {
                console.debug('The bookmakers skin component callback is supposed to run but I am not on the correct page!!!');
            }
        });
    }

    static getBookmakersSkin(callbackName, callback) {
        if (window.getBookmakersSkinEventListeners === undefined) {
            window.getBookmakersSkinEventListeners = {};
            window.getBookmakersSkinEventListeners[callbackName] = callback;
            FirebaseAssistant.getAllBookmakersSkins((dataReceived) => {
                console.debug('Running Firebase getAllBookmakersSkins() query snapshot');
                dataReceived.forEach(single => {
                    const data = single.data();
                    if (window.savedBookmakersSkins === undefined) {
                        window.savedBookmakersSkins = { [single.id]: data };
                    } else {
                        window.savedBookmakersSkins[single.id] = data;
                    }
                });
                Object.keys(window.getBookmakersSkinEventListeners).forEach(eventListenerName => {
                    window.getBookmakersSkinEventListeners[eventListenerName]();
                });
            }, (error) => {
                console.error('Error fetching bookmakers skins:', error);
                // Optionally, show an error message in the UI
                const container = document.querySelector('#all-bookmakers-skin');
                if (container) {
                    container.innerHTML = '<p>Failed to load bookmaker skins. Please try again later.</p>';
                }
            });
        } else if (window.savedBookmakersSkins === undefined) {
            setTimeout(() => {
                BookmakersSkinComponent.getBookmakersSkin(callbackName, callback);
            }, 50);
        } else {
            window.getBookmakersSkinEventListeners[callbackName] = callback;
            callback();
        }
    }

    static updateBookmakersSkin() {
        console.debug('Running update Bookmakers Skin');
        GeofencingGuard.getUserCountry(function (userCurrentCountry) {

            // Use I18n to get the user's preferred language
            let language = I18n.getUsersPreferredLanguage() || 'en';



            const container = document.querySelector('#all-bookmakers-skin');
            if (container) {
                container.innerHTML = '';
            }

            const bookmakersSkinArray = Object.keys(window.savedBookmakersSkins).map(key => {
                const data = window.savedBookmakersSkins[key];
                data.id = key;
                return data;
            });


            bookmakersSkinArray.sort((a, b) => {
                const orderA = a.order_skin_bookmaker !== undefined ? Number(a.order_skin_bookmaker) : Infinity;
                const orderB = b.order_skin_bookmaker !== undefined ? Number(b.order_skin_bookmaker) : Infinity;
                return orderA - orderB;
            });

            // Sort by rank if you have this property
            // bookmakersSkinArray.sort((a, b) => (a.rank || 0) - (b.rank || 0));

            bookmakersSkinArray.forEach((bookmakerData, index) => {

                if (bookmakerData.status === 'draft') {
                    return;
                }

                // **New Condition: Check if location_bookmaker_skin is empty**
                const hasLocationRestriction = Array.isArray(bookmakerData.location_bookmaker_skin) && bookmakerData.location_bookmaker_skin.length > 0;

                if (hasLocationRestriction) {
                    if (bookmakerData.location_bookmaker_skin.includes(userCurrentCountry)) {
                        console.debug(`Skipping bookmaker ${bookmakerData.id} due to location mismatch.`);
                        return;
                    }
                }
                // If location_bookmaker_skin is empty, do not filter by country

                // **Language Filtering**
                // if (
                //     bookmakerData.language_bookmaker_skin &&
                //     !bookmakerData.language_bookmaker_skin.includes(language)
                // ) {
                //     console.debug(`Skipping bookmaker ${bookmakerData.id} due to language mismatch.`);
                //     return;
                // }

                // Extract data with checks for undefined

                const bookmakerName = bookmakerData.bookmaker_skin_name || '';
                const bookmakerLogo = bookmakerData.bookmaker_skin_logo || '';
                const bookmakerBonusText = (bookmakerData.bookmaker_skin_hot_bonus && bookmakerData.bookmaker_skin_hot_bonus[language]) || '';
                const bookmakerBonusDetails = (bookmakerData.bonus_details && bookmakerData.bonus_details[language]) || '';

                const bookmakerListItems = (bookmakerData.bookmaker_skin_list_items && bookmakerData.bookmaker_skin_list_items[language]) || [];
                const bookmakerBetNowLink = bookmakerData.bookmaker_skin_bonus_url || '#';



                // Create list items HTML
                const listItemsHTML = bookmakerListItems.map(item => `<div>★ ${item}</div>`).join('');

                // Generate the HTML for the bookmaker skin entry with Bootstrap column and order classes
                const html = /*html*/ `
                    <div id="bookmaker-skin-${bookmakerData.id}" class="cta-row row g-3 my-3">
                        <!-- Logo Section -->
                        <div class="image-wrapper col-12 col-md-2 order-1 order-md-2">
                            <img src="${bookmakerLogo}" alt="${bookmakerName} Logo" loading="lazy">
                        </div>
                        
                        <!-- Rank Section -->
                        <div class="cta-col-rank col-12 col-md-1 order-2 order-md-1">
                            ${index + 1}
                        </div>
                        
                        <!-- Bonus Section -->
                        <div class="cta-col cta-col-bonus col-12 col-md-3 order-3 order-md-3">
                            <div class="bonus-text">${bookmakerBonusText}</div>
                            <div class="bonus-details">${bookmakerBonusDetails}</div>
                        </div>
                        
                        <!-- Info Section -->
                        <div class="cta-col-info col-12 col-md-4 order-4 order-md-4">
                            <a href="" class="cta-review-title">${bookmakerName} <span class="raw-review">a</span></a>
                            <div class="cta-list-items">
                                ${listItemsHTML}
                            </div>
                        </div>
                        
                        <!-- Action Section -->
                        <div class="cta-col-action col-12 col-md-2 order-5 order-md-5">
                            <a href="${bookmakerBetNowLink}" class="btn" target="_blank" rel="noopener noreferrer"><span class="raw-betNow"></span></a>
                        </div>
                    </div>
                `;

                container.insertAdjacentHTML('beforeend', html);
                BookmakersSkinComponent.dynamicTranslations();
                BookmakersSkinComponent.addEventListeners(`bookmaker-skin-${bookmakerData.id}`, bookmakerData.id);
            });

            // **Handle No Data Scenario**
            if (container && container.innerHTML === '') {
                container.innerHTML = I18n.translateString("bookmakersSkinList.noBookmakerSkinsMsg");
                document.querySelector('.removal-class').style.display = 'none';
                document.querySelector('.removal-title').style.display = 'none';
            }
        }.bind(this));
    }

    static addEventListeners(bookmakerElementId, collectionId) {
        const element = document.getElementById(bookmakerElementId);

        if (!element) {
            console.warn(`Element with id ${bookmakerElementId} not found.`);
            return;
        }

        const reviewTitle = element.querySelector('.cta-review-title');
        if (reviewTitle) {
            reviewTitle.addEventListener('click', function (evt) {
                evt.preventDefault();
                RouterAssistant.redirect('s_bookmaker_skin', {
                    skin_bookmaker: collectionId
                });
            });
        }

        const imageWrapper = element.querySelector('.image-wrapper');
        if (imageWrapper) {
            imageWrapper.addEventListener('click', function (evt) {
                evt.preventDefault();
                RouterAssistant.redirect('s_bookmaker_skin', {
                    skin_bookmaker: collectionId
                });
            });
        }
    }
}

BookmakersSkinComponent.template = /*html*/ `
<div class=" container my-4">
    <!-- Intro Section -->
    <div class="main-intro intro-section">
        <h1 class="title-section">Top Esports Betting Sites to Join in 2024: Discover the Best Esports Bookmaker Skins</h1>
        <p>
            With the explosive growth of esports, leading betting skins have risen to meet demand, offering extensive markets for popular games like Counter-Strike 2, Dota 2, League of Legends, Valorant, Mobile Legends, and Call of Duty. These games bring a continuous stream of thrilling tournaments and leagues, providing bettors with diverse options – from straightforward match winners to game-specific bets.
        </p>
        <p>
            At <a href="https://myesports.gg" class="text-a-style">myesports.gg</a>, we’ve carefully reviewed a range of top esports betting skin platforms to help you find the perfect skin for your betting needs.
        </p>
    </div>

    <h2 class="where-to title-section">Where to Bet on Esports: Best Esports Betting Skins & Bonuses</h2>

    <!-- Bookmakers Skins List -->
    <div id="all-bookmakers-skin" class=" m-1">
        <!-- Dynamically inserted bookmaker skin entries will go here -->
    </div>

    <h2 class="safe-bettting title-section removal-title">Please Bet Responsibly</h2>
</div>

<!-- Methodology Section -->
<div class="container my-4 removal-class">
    <div class="review-methodology intro-section">
        <h2 class="title-section">Our Review Methodology</h2>
        <p>
            At <a class="text-a-style" href="https://myesports.gg">myesports.gg</a>, our mission is straightforward – to guide you in selecting the ideal bookmaker skin for your esports betting. We evaluate each skin based on ease of use, variety of esports markets, and security features to ensure your safety when depositing or withdrawing funds.
        </p>
        <p>
            We also assess licensing to verify if the bookmaker skin operates legally in your region. Only those that meet our high standards receive our seal of approval. We feature exclusive esports bonuses and promotions to further enhance your betting experience.
        </p>
        <p>
            Our review criteria span multiple areas, including user-friendliness, market offerings, and payment methods. Each bookmaker skin is thoroughly evaluated across eight main categories, covering everything from design and functionality to customer service. Check our in-depth reviews to explore each bookmaker skin’s performance in these critical areas.
        </p>
    </div>
</div>
`;

BookmakersSkinComponent.reg('bookmakers-skin-component'); // components should always be 2 words separated with a dash
