// bookmakershome-component.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";
import { I18n } from "../../utilities/i18n/i18n";

// Updated CSS with Media Queries and Bootstrap Integration
const css = `
<style>

bookmakershome-component .container{
max-width: 100% !important;
}
bookmakershome-component .intro-section p {
    color: #A0A3A7;
    font-size: 1rem;
    line-height: 1.5;
}

bookmakershome-component .cta-row {
    display: flex;
    align-items: center;
    background-color: #2B2F38;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

bookmakershome-component .cta-row:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
}

bookmakershome-component .cta-col {
    flex: 1;
    padding: 10px;
}

bookmakershome-component .cta-col-rank {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #1E232D;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 15px;
    font-size: 1rem;
}

bookmakershome-component .image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 60px;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.1);
    margin-right: 15px;
    cursor: pointer;
}

bookmakershome-component .image-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

bookmakershome-component .cta-col-bonus .bonus-text  {
    color: #4CAF50;
    font-weight: bold;
    font-size: 1.2rem;
}

bookmakershome-component .cta-col-bonus .bonus-details {
    color: #b5b5b5;
    font-size: 0.9rem;
}

bookmakershome-component .cta-col-info {
    text-align: left;
    color: #A0A3A7;
    padding-left: 15px;
}

bookmakershome-component .cta-col-info a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

bookmakershome-component .text-a-style{
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
}

bookmakershome-component .cta-col-info .cta-list-items {
    font-size: 0.9rem;
    line-height: 1.5;
}

bookmakershome-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: white !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
}

bookmakershome-component .cta-col-action .btn:hover {
    background-color: #45a049;
}

bookmakershome-component .title-section {
    font-weight: bold;
    font-size: 1.5rem;
    color: #ffffff;
}

/* Media Queries */

/* 1. Extra Small Devices: Smartphones (portrait and landscape) */
@media only screen and (max-width: 600px) {
    bookmakershome-component .cta-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    bookmakershome-component .cta-col-rank {
        display: none !important;
    }

    bookmakershome-component .image-wrapper {
        width: 100%;
        height: 50px;
        margin-bottom: 0;
    }

    bookmakershome-component .image-wrapper img {
        width: 100px;
        height: auto;
        margin-bottom: 0;
    }

    bookmakershome-component .cta-col-action {
        text-align: center;
    }

    bookmakershome-component .cta-col,
    bookmakershome-component .cta-review-title {
        text-align: center;
    }

    bookmakershome-component .cta-col-action .btn {
        width: 30%;
        text-align: center; 
        padding: 5px 10px;
    }

    /* Hide cta-list-items on mobile */
    bookmakershome-component .cta-col-info .cta-list-items {
        display: none;
    }

    /* Adjust font sizes */
    bookmakershome-component .title-section {
        font-size: 0.8rem;
    }

    bookmakershome-component .cta-col-info .cta-list-items {
        font-size: 0.7rem;
    }
}

/* 2. Small Devices: Smartphones (landscape) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    bookmakershome-component .cta-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-content: center;
    }

    bookmakershome-component .cta-col-rank {
        display: none !important;
    }

    bookmakershome-component .image-wrapper {
        width: 100%;
        height: 50px;
        margin-bottom: 0;
    }

    bookmakershome-component .image-wrapper img {
        width: 100px;
        height: auto;
        margin-bottom: 0;
    }

    bookmakershome-component .cta-col-action {
        text-align: center;
    }

    bookmakershome-component .cta-col,
    bookmakershome-component .cta-review-title {
        text-align: center;
    }

    bookmakershome-component .cta-col-action .btn {
        width: 30%;
        text-align: center;
        padding: 6px 12px;
    }

    /* Hide cta-list-items on mobile */
    bookmakershome-component .cta-col-info .cta-list-items {
        display: none;
    }

    /* Adjust font sizes */
    bookmakershome-component .title-section {
        font-size: 0.8rem;
    }

    bookmakershome-component .cta-col-info .cta-list-items {
        font-size: 0.7rem;
    }
}

/* 3. Medium Devices: Tablets */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    bookmakershome-component .cta-col-bonus .bonus-text {
        font-size: 0.9rem;
    } 
    bookmakershome-component .cta-col-bonus .bonus-details {
        font-size: 0.8rem;
    } 
    bookmakershome-component .cta-col-info .cta-review-title {
        font-size: 0.9rem;
    }
    bookmakershome-component .cta-col-info .cta-list-items {
        font-size: 0.8rem;
    }
    bookmakershome-component .image-wrapper {
        width: 100px;
        height: 60px;
    }

    bookmakershome-component .cta-col-action .btn {
        padding: 5px 7px;
        font-size: 0.9rem;
    }
}

/* 4. Large Devices: Desktops */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    bookmakershome-component .image-wrapper {
        width: 110px;
        height: 65px;
    }

    bookmakershome-component .cta-col-action .btn {
        padding: 10px 20px;
    }
}

/* 5. Extra Large Devices: Large Desktops */
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
    bookmakershome-component .image-wrapper {
        width: 120px;
        height: 70px;
    }

    bookmakershome-component .cta-col-action .btn {
        padding: 10px 20px;
    }
}

/* 6. Ultra Large Devices: Very Large Desktops */
@media only screen and (min-width: 1920px) {
    bookmakershome-component .image-wrapper {
        width: 130px;
        height: 75px;
    }

    bookmakershome-component .cta-col-action .btn {
        padding: 10px 40px;
    }
}
</style>
`;

export class BookmakershomeComponent extends BaseComponent {

    initCallback() {
        // Ensure the root element has the correct container and component class
        RouterAssistant.checkForSingleContainer('bookmakershome-component', BookmakershomeComponent.initiator);
        document.head.insertAdjacentHTML("beforeend", css);
        BookmakershomeComponent.mainTranslations();

    }

    static mainTranslations() {
        document.querySelector('.main-intro').innerHTML = I18n.translateString("bookmakersList.introSection");
        document.querySelector('.where-to').innerHTML = I18n.translateString("bookmakersList.sectionTitle");
        document.querySelector('.safe-bettting').innerHTML = I18n.translateString("bookmakersList.pleaseBetResponsibly");
        document.querySelector('.review-methodology').innerHTML = I18n.translateString("bookmakersList.reviewMethodology");
    }

    static dynamicTranslations() {
        document.querySelectorAll('.raw-review').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersList.review");
        });
        document.querySelectorAll('.raw-betNow').forEach(element => {
            element.innerHTML = I18n.translateString("bookmakersList.betNow");
        });
    }

    static initiator() {
        BookmakershomeComponent.getBookmakers('fromBookmakersComponent', () => {
            if (document.querySelectorAll('#all-bookmakers').length !== 0) {
                console.debug('I am running the Bookmakers component call back!');
                BookmakershomeComponent.updateBookmakers();
            } else {
                console.debug('The bookmakers component call back is supposed to run but I am not on the correct page!!!');
            }
        });
    }

    static getBookmakers(callbackName, callback) {
        if (window.getBookmakersEventListeners === undefined) {
            window.getBookmakersEventListeners = {};
            window.getBookmakersEventListeners[callbackName] = callback;
            FirebaseAssistant.getAllBookmakers((dataReceived) => {
                console.debug('running firebase getAllBookmakers() query snapshot');
                dataReceived.forEach(single => {
                    const data = single.data();
                    if (window.savedBookMakers === undefined) {
                        window.savedBookMakers = { [single.id]: data };
                    } else {
                        window.savedBookMakers[single.id] = data;
                    }
                });
                Object.keys(window.getBookmakersEventListeners).forEach(eventListenerName => {
                    window.getBookmakersEventListeners[eventListenerName]();
                });
            });
        } else if (window.savedBookMakers === undefined) {
            setTimeout(() => {
                BookmakershomeComponent.getBookmakers(callbackName, callback);
            }, 50);
        } else {
            window.getBookmakersEventListeners[callbackName] = callback;
            callback();
        }
    }

    static updateBookmakers() {
        console.debug('running update Bookmakers');
        GeofencingGuard.getUserCountry(function (userCurrentCountry) {

            // Use I18n to get the user's preferred language
            let language = I18n.getUsersPreferredLanguage() || 'en';

            console.log('User Country:', userCurrentCountry);
            console.log('Language selected:', language);

            const container = document.querySelector('#all-bookmakers');
            if (container) {
                container.innerHTML = '';
            }

            const bookmakersArray = Object.keys(window.savedBookMakers).map(key => {
                const data = window.savedBookMakers[key];
                data.id = key;
                return data;
            });

            bookmakersArray.sort((a, b) => {
                const orderA = a.bookmaker_order !== undefined ? Number(a.bookmaker_order) : Infinity;
                const orderB = b.bookmaker_order !== undefined ? Number(b.bookmaker_order) : Infinity;
                return orderA - orderB;
            });

            // Sort by rank if you have this property
            // bookmakersArray.sort((a, b) => (a.rank || 0) - (b.rank || 0));

            bookmakersArray.forEach((bookmakerData, index) => {

                console.log(`Processing bookmaker ${bookmakerData.id}`);

                if (bookmakerData.status === 'draft') {
                    console.log(`Skipping bookmaker ${bookmakerData.id} due to draft status.`);
                    return;
                }

                // **New Condition: Check if location_bookmaker_skin is empty**
                const hasLocationRestriction = Array.isArray(bookmakerData.location_bookmaker_skin) && bookmakerData.location_bookmaker_skin.length > 0;

                if (hasLocationRestriction) {
                    if (!bookmakerData.location_bookmaker_skin.includes(userCurrentCountry)) {
                        console.log(`Skipping bookmaker ${bookmakerData.id} due to location mismatch.`);
                        return;
                    }
                }
                // If location_bookmaker_skin is empty, do not filter by country

                // **Language Filtering**
                if (
                    bookmakerData.language_bookmaker_skin &&
                    !bookmakerData.language_bookmaker_skin.includes(language)
                ) {
                    console.log(`Skipping bookmaker ${bookmakerData.id} due to language mismatch.`);
                    return;
                }

                // Extract data with checks for undefined
                const bookmakerName = bookmakerData.name || '';
                const bookmakerLogo = bookmakerData.bookmaker_logo || '';
                const bookmakerBonusText = (bookmakerData.bonus_text && bookmakerData.bonus_text[language]) || '';
                const bookmakerBonusDetails = (bookmakerData.bonus_details && bookmakerData.bonus_details[language]) || '';

                const bookmakerListItems = (bookmakerData.bookmaker_list_items && bookmakerData.bookmaker_list_items[language]) || [];
                const bookmakerBetNowLink = bookmakerData.bookmaker_bonus_url || '';

                console.log(`Bookmaker Name: ${bookmakerName}`);
                console.log(`Bookmaker Logo: ${bookmakerLogo}`);
                console.log(`Bookmaker Bonus Text: ${bookmakerBonusText}`);
                console.log(`Bookmaker Bonus Details: ${bookmakerBonusDetails}`);
                console.log(`Bookmaker List Items: ${bookmakerListItems}`);
                console.log(`Bookmaker Bet Now Link: ${bookmakerBetNowLink}`);

                // Create list items HTML
                const listItemsHTML = bookmakerListItems.map(item => `<div>★ ${item}</div>`).join('');

                // Generate the HTML for the bookmaker entry with Bootstrap column and order classes
                const html =/*html*/ `
                    <div id="bookmaker-skin-${bookmakerData.id}" class="cta-row row g-3 my-3 ">
                        <!-- Logo Section -->
                        <div class="image-wrapper col-12 col-md-2 order-1 order-md-2">
                            <img src="${bookmakerLogo}" alt="${bookmakerName} Logo" loading="lazy">
                        </div>
                        
                        <!-- Rank Section -->
                        <div class="cta-col-rank col-12 col-md-1 order-2 order-md-1">
                            ${index + 1}
                        </div>
                        
                        <!-- Bonus Section -->
                        <div class="cta-col cta-col-bonus col-12 col-md-3 order-3 order-md-3">
                            <div class="bonus-text">${bookmakerBonusText}</div>
                            <div class="bonus-details">${bookmakerBonusDetails}</div>
                        </div>
                        
                        <!-- Info Section -->
                        <div class="cta-col-info col-12 col-md-4 order-4 order-md-4">
                            <a href="" class="cta-review-title">${bookmakerName} <span class="raw-review">a</span></a>
                            <div class="cta-list-items">
                                ${listItemsHTML}
                            </div>
                        </div>
                        
                        <!-- Action Section -->
                        <div class="cta-col-action col-12 col-md-2 order-5 order-md-5">
                            <a href="${bookmakerBetNowLink}" class="btn" target="_blank" rel="noopener noreferrer"><span class="raw-betNow"></span></a>
                        </div>
                    </div>
                `;

                container.insertAdjacentHTML('beforeend', html);
                BookmakershomeComponent.addEventListeners(`bookmaker-skin-${bookmakerData.id}`, bookmakerData.id);
                BookmakershomeComponent.dynamicTranslations();
            });

            // **Handle No Data Scenario**
            if (container.innerHTML.trim() === '') {
                container.innerHTML = I18n.translateString("bookmakersList.noBookmakersMsg");
                document.querySelector('.removal-class').style.display = 'none';
                document.querySelector('.removal-title').style.display = 'none';
            }
        }.bind(this));
    }

    static addEventListeners(bookmakerElementId, collectionId) {
        const element = document.getElementById(bookmakerElementId);

        if (!element) {
            console.warn(`Element with id ${bookmakerElementId} not found.`);
            return;
        }

        const reviewTitle = element.querySelector('.cta-review-title');
        if (reviewTitle) {
            reviewTitle.addEventListener('click', function (evt) {
                evt.preventDefault();
                RouterAssistant.redirect('s_bookmaker', {
                    bookmaker: collectionId
                });
            });
        }

        const imageWrapper = element.querySelector('.image-wrapper');
        if (imageWrapper) {
            imageWrapper.addEventListener('click', function (evt) {
                evt.preventDefault();
                RouterAssistant.redirect('s_bookmaker', {
                    bookmaker: collectionId
                });
            });
        }
    }
}

BookmakershomeComponent.template = /*html*/ `
<div class="container my-4">
    <!-- Intro Section -->
    <div class="main-intro intro-section">
    </div>

    <h2 class="where-to title-section"></h2>

    <!-- Bookmakers List -->
    <div id="all-bookmakers" class=" g-3 " style="margin:0 !important;">
        <!-- Dynamically inserted bookmaker skin entries will go here -->
    </div>

    <h2 class="safe-bettting title-section mt-2 removal-title">Please Bet Responsibly</h2>
</div>

<!-- Methodology Section -->
<div class="container my-4 removal-class">

    <div class="review-methodology intro-section">
    </div>

</div>
`;

BookmakershomeComponent.reg('bookmakershome-component'); // components should always be 2 words separated with a dash
