import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { PredictionComponent } from "../predictions-component/predictions-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
<style>
    
</style>
`

export class PredictionContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.activateGameFilters();
        document.getElementById('predictionsContainerHeader').innerText = I18n.translateString('navMainMenu.Predictions')
    }
}

PredictionContainer.template = /*html*/ `
<h1 id="predictionsContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>

<div id="predictionsInnerContainerHeader" class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-2 ">

     <!-- here -->
     <predictions-component></predictions-component>


</div>

 <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 ">

                    <placeholder-matcheswidget></placeholder-matcheswidget>
                    <div class="sidebar-matches home-components row m-2 ">
                        <div class="title">
                        </div>
                        <matches-component data-mode="homePage"></matches-component>
                    </div>

                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                        </div>
                    </div>
                </div>
`;

PredictionContainer.reg('predictions-container');