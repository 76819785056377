import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { BookmakersSkinComponent } from "../bookmakers-skin-component/bookmakers-skin-component";

const css = `
    <style>
         /* Add any specific styles for the container if needed */
    </style>
`

export class BookmakersSkinContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
    }
}

BookmakersSkinContainer.template = /*html*/ `
      <div class="main-content col-12 g-0 mt-2 ">

        <bookmakers-skin-component></bookmakers-skin-component>

        
</div>

`;

BookmakersSkinContainer.reg('bookmakers-skin-container');
