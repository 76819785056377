import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { PredictionsHomecomponent } from "../predictions-homecomponent/predictions-homecomponent";
import { MatchesComponent } from "../matches-component/matches-component";
import { AuthorsComponent } from "../author-component/author-component";
import { GameNavbar } from "../game-navbar/game-navbar";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";

const noImageFound = require('../matches-component/Assets/no image found.png')
const noImageFoundPlayer = require('../postmatches-component/assets/author.webp')
const css = `
<style>
.tooltip-predictionspage-stats {
  position: absolute;
  font-size: 0.6rem;
  background-color: #161b22;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 21rem; /* Set a fixed width */
  white-space: normal; /* Allows text to wrap when needed */
  word-wrap: break-word; /* Breaks long words if they exceed the width */
  z-index: 1000;
  pointer-events: none; /* Prevent blocking hover */
  opacity: 0;
  transition: opacity 0.2s;
}

predictions-component body {
            background-color: #0e1117;
            color: #c9d1d9;
            font-family: 'Inter', sans-serif;
            margin: 0;
            padding: 0;
        }
predictions-component        .container {
            max-width: 1400px;
            margin: auto;
            padding: 20px;
        }
predictions-component        a {
            text-decoration: none;
            color: inherit;
        }
        /* Header Styles */
predictions-component        .header {
            text-align: center;
            margin-bottom: 40px;
        }
predictions-component        .header h1 {
            font-size: 2.5rem;
            font-weight: 700;
            color: #58a6ff;
            margin-bottom: 10px;
        }
predictions-component        .header p {
            font-size: 1rem;
            color: #8b949e;
        }
        /* Custom Scrollable Datepicker */
predictions-component        .date-selector-container {
            position: relative;
            margin-bottom: 30px;    
            overflow: hidden; /* Hide overflow to use transform for scrolling */
            white-space: nowrap; /* Prevent line breaks */
            
        }
predictions-component        .table-responsive2 {
        margin-top: 30px;
}
predictions-component        .date-content {
    display: inline-flex;
    transition: transform 0.3s ease; /* Smooth transition for transform */
}

predictions-component        .date-selector {
            display: flex;
            justify-content: center;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            scrollbar-width: none;
            -ms-overflow-style: none;
            padding: 10px 40px; /* Increased padding to accommodate arrows */
            background-color: inherit;
            border-radius: 8px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.5);
            align-items: center;
        }
predictions-component        .date-selector::-webkit-scrollbar {
            display: none;
        }
predictions-component       .date-box {
            background-color: #21262d;
            border-radius: 15px;
            padding: 12px 20px;
            margin: 0 10px;
            cursor: pointer;
            transition: background-color 0.3s, transform 0.3s, color 0.3s;
            text-align: center;
            flex-shrink: 0;
            font-size: 0.9rem;
            user-select: none;
            min-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
predictions-component        .date-box:hover {
            background-color: #30363d;
            transform: translateY(-3px);
        }
predictions-component        .date-box.active {
            background-color: #238636;
            color: #ffffff;
            transform: translateY(-5px);
            box-shadow: 0 4px 6px rgba(0,0,0,0.3);
        }
predictions-component        .date-box .day-number {
            font-size: 1.2rem;
            font-weight: 600;
            display: block;
        }
predictions-component        .date-box .day-name {
            font-size: 0.85rem;
            display: block;
            color: #adbac7;
        }
        /* Scroll Arrow Styles */
predictions-component        .scroll-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(24, 28, 33, 0.7);
            border: none;
            color: #c9d1d9;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 3;
            transition: background-color 0.3s, color 0.3s;
        }
predictions-component        .scroll-arrow:hover {
            background-color: rgba(24, 28, 33, 0.9);
            color: #58a6ff;
        }
predictions-component        .scroll-left {
            left: 5px;
        }
predictions-component        .scroll-right {
            right: 5px;
        }
        /* Predictions Table Styles */
predictions-component        .predictions-table {
            width: 100%;
            border-collapse: collapse;
            background-color: #161b22;
            border: 1px solid #30363d;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0,0,0,0.5);
        }
predictions-component        .predictions-table th, .predictions-table td {
            padding: 15px;
            text-align: center;
            border-bottom: 1px solid #30363d;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
predictions-component        .predictions-table th {
            background-color: #21262d;
            color: #58a6ff;
            font-size: 1rem;
            font-weight: 700;
            position: sticky;
            top: 0;
            z-index: 2;
        }
predictions-component        .predictions-table tr:last-child td {
            border-bottom: none;
        }
predictions-component        .predictions-table tbody tr:hover {
            background-color: #30363d;
            cursor: pointer;
        }
        /* Time Cell Styles */
predictions-component        .time-cell {
            font-weight: 600;
            color: #58a6ff;
        }
        /* Match Cell Styles */
predictions-component        .match-cell {

        }
predictions-component        .team-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
predictions-component        .team-logo {
            width: 35px;
            height: 35px;
            object-fit: contain;
            border-radius: 50%;
            border: 2px solid #21262d;
            background-color: #2f363d;
        }
predictions-component        .team-name {

            font-weight: 600;
            color: #c9d1d9;
            margin-left: 10px;
            max-width: 150px;
        }
        /* Odds Cell Styles */
predictions-component        .odds-value {

            font-weight: 700;
            color: #58a6ff;
            margin-bottom: 5px;
        }
predictions-component        .bookmaker-logo {
            width: 80px;
            height: auto;
            object-fit: contain;
            margin: 0 auto;
        }
        /* Tournament Name Styles */
predictions-component        .tournament-name {
            text-align: left;

            font-weight: 600;
            color: #adbac7;
            max-width: 200px;
        }
        /* Prediction Status Styles */
predictions-component        .prediction-won {
            background-color: #28a745 !important; /* Green */
            color: #ffffff;
        }
predictions-component        .prediction-lost {
            background-color: #dc3545 !important; /* Red */
            color: #ffffff;
        }
predictions-component .match-count {
            font-size: 1.2rem;
            font-weight: 600;
            padding: 3px 10px;
            background-color: #58a6ff;
            color:rgb(255, 255, 255);
            min-width: 30px;
            border-radius: 20px;
            text-align: center;
            position: relative;
            overflow: hidden;
            transition: background-color 0.3s, color 0.3s;
        }

predictions-component .author-logo {
            width: 45px;
            height: 45px;
            object-fit: contain;
            border-radius: 50%;
            border: 2px solid #21262d;
            background-color: #2f363d;
            border-radius: 50%;
            margin-right: 10px;
}
        /* Responsive Adjustments */
@media (max-width: 1920px) {
    predictions-component .predictions-table th, .predictions-table td {
        padding: 0.75rem;
        font-size: 1rem;
    }
    predictions-component .team-logo {
        width: 1.5rem;
        height: 1.5rem;
    }
    predictions-component .author-logo {
    width: 2.5rem;
    height: 2.5rem;
}
    predictions-component .bookmaker-logo {
        width: 3.5rem;
    }
    predictions-component .tournament-name {
        max-width: 7.5rem;
        font-size: 1rem;    
    }
    predictions-component .checkPredictStateClass {
        font-size: 1.5rem;
    }
}
@media (max-width: 1460px) {
    predictions-component .predictions-table th, .predictions-table td {
        padding: 0.7rem;
        font-size: 0.8rem;
    }
    predictions-component .team-logo {
        width: 1.4rem;
        height: 1.4rem;
    }
    predictions-component .author-logo {
    width: 2.2rem;
    height: 2.2rem;
}
    predictions-component .bookmaker-logo {
        width: 3rem;
    }
    predictions-component .team-name {
        max-width: 6.5rem;
    }
    predictions-component .tournament-name {
        max-width: 6rem;
    }
            predictions-component .checkPredictStateClass {
        font-size: 1.25rem;
    }
}
@media (max-width: 1317px) {
    predictions-component .predictions-table th, .predictions-table td {
        padding: 0.625rem;
        font-size: 0.7rem;
    }
    predictions-component .team-logo {
        width: 1.25rem;
        height: 1.25rem;
    }
    predictions-component .author-logo {
    width: 2rem;
    height: 2rem;
}
    predictions-component .bookmaker-logo {
        width: 2.5rem;
    }
    predictions-component .team-name {
        max-width: 5.5rem;
    }
    predictions-component .tournament-name {
        max-width: 5rem;
    }
    predictions-component .date-box {
        min-width: 5rem;
        padding: 0.625rem 0.9375rem;
    }
    predictions-component .checkPredictStateClass {
        font-size: 1.1rem;
    }
}
@media (max-width: 1232px) {
    predictions-component .predictions-table th, .predictions-table td {
        padding: 0.5rem;
        font-size: 0.55rem;
    }
    predictions-component .team-logo {
        width: 1.1rem;
        height: 1.1rem;
    }
    predictions-component .bookmaker-logo {
        width: 2rem;
    }
    predictions-component .team-name {
        max-width: 4.5rem;
        margin-left: 7px;
    }
    predictions-component .tournament-name {
        max-width: 4rem;
    }
    predictions-component .date-box {
        min-width: 5rem;
        padding: 0.625rem 0.9375rem;
    }
        predictions-component .author-logo {
    width: 1.6rem;
    height: 1.6rem;
    }
    predictions-component .checkPredictStateClass {
    font-size: 1rem;
    }
}
    @media (max-width: 724px) {
    .tooltip-predictionspage-stats {
        font-size: 0.5rem;
        padding: 5px 10px;
        width: 15rem; /* Set a fixed width */
        }
    predictions-component .predictions-table th, .predictions-table td {
        padding: 0.4rem;
        font-size: 0.5rem;
    }
    predictions-component .stats-earn-td {
        display: none;
    }
    predictions-component .stats-earn-th {
        display: none;
    }
    predictions-component .stats-open-bets-td {
        display: none;
    }
    predictions-component  .stats-open-bets-th {
        display: none;
    }
    predictions-component .team-name {
        margin-left: 3px;
        }
    predictions-component .tournament-name {
        display: none;
    }
    predictions-component .th-tournament {
    display: none;
}
}
    @media (max-width: 403px) {
    predictions-component .date-box .day-number  {
    font-size: 0.65rem;
  }
    predictions-component .date-box .day-name {
    font-size: 0.47rem;
  }
    predictions-component .date-box .match-count {
    font-size: 0.65rem;
  }
    predictions-component .date-box {
    padding: 0.35rem 0.5rem;
    min-width: 3rem;

    }
    predictions-component .tournament-name {
        display: none;
    }
    predictions-component .th-tournament {
    display: none;
}
        predictions-component .team-name {
        max-width: 2rem;
    }
}

</style>

`

export class PredictionComponent extends BaseComponent {

    initCallback() {


        GridOrchestrator.activateGameFilters()
        RouterAssistant.checkForSingleContainer('predictions-component', PredictionComponent.initiator)
        document.head.insertAdjacentHTML("beforeend", css)
        window.PredictionComponent = PredictionComponent;

    }


    static initiator() {

        console.debug('I am running the matches component call back for predictions page!');
        if (document.querySelectorAll('#predictions-tbody').length !== 0) {
            console.debug('I am running the authors component call back for predictions page!');
            AuthorsComponent.getAuthors('fromPredictionsPageComponent', () => {
                PredictionComponent.updatePredictionMatches();
                PredictionComponent.js(GameNavbar.getCurrentlyActivatedGameIfAny());

            });
        } else {
            console.debug('The authors component call back is supposed to run for predictions but I am not on the correct page!!!')
        }



        GameNavbar.subscribeToGameFilterChanges('fromPredictionsPageComponent', (eventId) => {
            if (document.querySelectorAll('#predictions-tbody').length !== 0) {
                console.debug('I am running the predictions component call back for the filter changes!');
                PredictionComponent.js(eventId);
            } else {
                console.debug('I am not running the predictions component call back!');
            }
        });

        PredictionComponent.translations();

    }




    static updatePredictionMatches() {
        if (window.predictionMatches === undefined) {
            window.predictionMatches = [];
        }
        Object.keys(window.savedAuthors).forEach(key => {
            for (let i = 0; i < window.savedAuthors[key].predictions.length; i++) {
                const prediction = window.savedAuthors[key].predictions[i];
                const author = window.savedAuthors[key];


                // Check if the match already exists in window.predictionMatches
                const matchExists = window.predictionMatches.some(existingMatch => existingMatch.id === prediction.id && existingMatch.authorName.toLowerCase() === key.toLowerCase());
                if (!matchExists) {
                    window.predictionMatches.push({
                        id: prediction.id,
                        state: prediction.state,
                        matchName: prediction.matchName,
                        tournamentName: prediction.tournamentName,
                        matchDate: prediction.matchDate.toDate(),
                        teams: prediction.teams,
                        odds: prediction.odds,
                        prediction: prediction.predictionValue,
                        authorName: author.name,
                        authorPhoto: author.photo,
                        gameId: prediction.gameId,

                    });
                }
            }
        });
    }

    static renderTipsterStats() {
        const tbody2 = document.getElementById('predictions-stats-tbody');
        if (!tbody2) {
            console.error("Element with id 'predictions-tbody' not found.");
            return;
        }
        tbody2.innerHTML = '';

        // Convert savedAuthors object to an array and sort by the number of predictions
        const sortedAuthors = Object.keys(window.savedAuthors)
            .map(key => window.savedAuthors[key])
            .sort((a, b) => b.predictions.length - a.predictions.length);

        sortedAuthors.forEach(author => {


            const authorName = author.name;
            const authorPhoto = (author.photo === "" || author.photo === "N/A") ? noImageFoundPlayer : author.photo;
            const authorPredictions = author.predictions;


            let betsCounter = 0;
            let winsCounter = 0;
            let closedBetsCounter = 0;
            let openBetsCounter = 0;
            let oddsAverage = 0;
            let totalWinnings = 0;
            authorPredictions.forEach(prediction => {
                betsCounter++;
                oddsAverage += parseFloat(prediction.oddValue);
                if (prediction.state === "W" && prediction.state !== "Upcoming" && prediction.state !== "Canceled") {
                    winsCounter++;
                    totalWinnings += parseFloat(prediction.oddValue) - 1;
                    closedBetsCounter++;
                } else if (prediction.state === "L" && prediction.state !== "Upcoming" && prediction.state !== "Canceled") {
                    totalWinnings -= 1;
                    closedBetsCounter++;
                } else if (prediction.state === "Upcoming") {
                    openBetsCounter++;
                } else if (prediction.state === "Canceled") {
                    betsCounter--;
                }
            });

            if (betsCounter === 0) {
                return;
            }


            // Create Table Row
            const tr = document.createElement('tr');
            tr.id = "predictionGeneralStats-" + authorName;

            // Author Cell
            const authorTd = document.createElement('td');
            const authorContainer = document.createElement('div');
            authorContainer.classList.add('author-container');
            const authorSpan = document.createElement('span');
            authorSpan.classList.add('author-name');
            authorSpan.textContent = authorName;
            const authorImg = document.createElement('img');
            authorImg.src = authorPhoto;
            authorImg.alt = authorName + " Logo";
            authorImg.classList.add('author-logo');
            authorContainer.appendChild(authorImg);
            authorContainer.appendChild(authorSpan);
            authorTd.appendChild(authorContainer);
            tr.appendChild(authorTd);

            // Bets Cell
            const betsTd = document.createElement('td');
            betsTd.classList.add('stats-bets-td');
            betsTd.textContent = betsCounter;
            tr.appendChild(betsTd);

            // Winnings cell
            const winningsTd = document.createElement('td');
            winningsTd.classList.add('stats-earn-td');
            winningsTd.textContent = totalWinnings.toFixed(2);
            tr.appendChild(winningsTd);

            // Win percentage cell
            const winPercentageTd = document.createElement('td');
            winPercentageTd.classList.add('stats-win-td');
            winPercentageTd.textContent = closedBetsCounter !== 0 ? (winsCounter / (betsCounter - openBetsCounter) * 100).toFixed(2) + "%" : "0.00%";
            tr.appendChild(winPercentageTd);

            // ROI cell
            const roiTd = document.createElement('td');
            roiTd.classList.add('stats-roi-td');
            roiTd.textContent = closedBetsCounter !== 0 ? (totalWinnings / (betsCounter - openBetsCounter) * 100).toFixed(2) + "%" : "0.00%";
            tr.appendChild(roiTd);

            // Average Odds cell
            const averageOddsTd = document.createElement('td');
            averageOddsTd.classList.add('stats-avg-odds-td');
            averageOddsTd.textContent = (oddsAverage / betsCounter).toFixed(2);
            tr.appendChild(averageOddsTd);

            // Open Bets cell
            const openBetsTd = document.createElement('td');
            openBetsTd.classList.add('stats-open-bets-td');
            openBetsTd.textContent = openBetsCounter;
            tr.appendChild(openBetsTd);

            // Append Row to Table Body
            tbody2.appendChild(tr);
            tr.addEventListener('click', () => {
                RouterAssistant.redirect('s_author', {
                    authid: authorName,
                })
            })
        });

    }

    static sortTable() {
        const sortButtons = document.querySelectorAll('.sort-icons'); // Select all buttons
        sortButtons.forEach(button => {
            const th = button.closest('th'); // Find the closest <th> containing the button
            if (th && !th.dataset.listenerAdded) {
                const handleClick = () => {
                    const table = th.closest('table'); // Get the table
                    const rows = Array.from(table.rows).slice(1); // Exclude header row
                    const column = th.getAttribute('data-column') || button.getAttribute('data-column'); // Use <th>'s data-column or button's
                    const isAscending = button.classList.contains('bi-sort-up');

                    console.debug('rows', rows);

                    rows.sort((a, b) => {
                        const cellA = a.cells[column].innerText;
                        const cellB = b.cells[column].innerText;

                        const compareValues = (a, b) => {
                            if (column === "3" || column === "4") { // (%) and ROI columns
                                return parseFloat(a.replace('%', '')) - parseFloat(b.replace('%', ''));
                            } else {
                                return parseFloat(a) > parseFloat(b) ? 1 : (parseFloat(a) < parseFloat(b) ? -1 : 0);
                            }
                        };

                        if (isAscending) {
                            return compareValues(cellA, cellB);
                        } else {
                            return compareValues(cellB, cellA);
                        }
                    });

                    // Clear existing rows and append sorted rows
                    const tbody = table.tBodies[0];
                    tbody.innerHTML = '';
                    tbody.append(...rows);

                    // Toggle sort direction on button
                    button.classList.toggle('bi-sort-up');
                    button.classList.toggle('bi-sort-down');
                };

                th.addEventListener('click', handleClick);
                th.dataset.listenerAdded = 'true'; // Set flag to indicate listener has been added
            }
        });
    }



    static renderAllPredictions(gameId) {
        // Filter matches based on selected date if provided
        const elements = document.querySelectorAll('[data-date]');

        elements.forEach(element => {
            const selectedDate = element.getAttribute('data-date');
            const filteredMatches = selectedDate
                ? window.predictionMatches.filter(match => {
                    const matchDate = new Date(match.matchDate);
                    const selected = new Date(selectedDate);
                    // Compare year, month, and day to ensure accurate filtering
                    return matchDate.getFullYear() === selected.getFullYear() &&
                        matchDate.getMonth() === selected.getMonth() &&
                        matchDate.getDate() === selected.getDate();
                })
                : window.predictionMatches; // If no date selected, grab all matches
            let matchCount = 0;
            const gameIdMapping = MatchesComponent.createGameIdMapping();
            filteredMatches.forEach(match => {
                // If a gameId is provided and it doesn't match the current game's id, skip this match
                if (gameId && gameIdMapping[gameId] !== undefined) {
                    if (Array.isArray(gameIdMapping[gameId])) {
                        if (!gameIdMapping[gameId].includes(match.gameId)) {
                            return;
                        }
                    } else {
                        if (gameIdMapping[gameId] !== match.gameId) {
                            return;
                        }
                    }
                }
                matchCount++;
            });

            let matchCountElement = undefined;
            if (matchCount !== 0) {
                matchCountElement = document.createElement('span');
                matchCountElement.classList.add('match-count');
                matchCountElement.classList.add('blue-animation');
                matchCountElement.textContent = matchCount
            }
            if (matchCountElement) {
                element.insertAdjacentHTML('beforeend', matchCountElement.outerHTML);
            }

        });




    }

    static renderPredictions(gameId, selectedDate = null) {
        // Function to Render Predictions Table based on selected date
        const tbody = document.getElementById('predictions-tbody');
        if (!tbody) {
            console.error("Element with id 'predictions-tbody' not found.");
            return;
        }
        tbody.innerHTML = ''; // Clear existing rows

        const dateContent = document.querySelector('.date-content');
        dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;
        const element = document.querySelector(`.date-box[data-date="${PredictionComponent.rememberedDate}"]`);
        if (element) {
            element.classList.add('active');
        }

        // Filter matches based on selected date if provided
        const filteredMatches = selectedDate
            ? window.predictionMatches.filter(match => {
                const matchDate = new Date(match.matchDate);
                const selected = new Date(selectedDate);
                // Compare year, month, and day to ensure accurate filtering
                return matchDate.getFullYear() === selected.getFullYear() &&
                    matchDate.getMonth() === selected.getMonth() &&
                    matchDate.getDate() === selected.getDate();
            })
            : window.predictionMatches; // If no date selected, show all matches


        const matchesWithPredictions = filteredMatches.filter(match => match.prediction !== null && match.prediction !== undefined);
        if (matchesWithPredictions.length === 0) {
            const noPredictionTr = document.createElement('tr');
            const noPredictionTd = document.createElement('td');
            noPredictionTd.colSpan = 8;
            noPredictionTd.textContent = "No prediction for this date yet, come back later.";
            noPredictionTd.classList.add('translateNoPredictions');
            noPredictionTd.style.fontStyle = "italic";
            noPredictionTd.style.color = "#8b949e";
            noPredictionTr.appendChild(noPredictionTd);
            tbody.appendChild(noPredictionTr);
        }

        const gameIdMapping = MatchesComponent.createGameIdMapping();

        // Sort matchesWithPredictions by match time
        matchesWithPredictions.sort((a, b) => {
            const dateA = new Date(a.matchDate);
            const dateB = new Date(b.matchDate);
            return dateA - dateB;
        });

        matchesWithPredictions.forEach(match => {

            // If a gameId is provided and it doesn't match the current game's id, skip this match
            if (gameId && gameIdMapping[gameId] !== undefined) {
                if (Array.isArray(gameIdMapping[gameId])) {
                    if (!gameIdMapping[gameId].includes(match.gameId)) {
                        return;
                    }
                } else {
                    if (gameIdMapping[gameId] !== match.gameId) {
                        return;
                    }
                }
            }

            // Create Table Row
            const tr = document.createElement('tr');
            tr.id = "predictionMatch-" + match.id + "-" + match.authorName;

            // Determine if the match is in the past
            const now = new Date();
            const matchDate = new Date(match.matchDate);
            const isPast = matchDate < now;

            // Add Click Event to Redirect to Match Details
            //to be continued due to a tag
            tr.href = `${window.location.origin}/s_match?match=${match.id}&name=${match.matchName}`; // Set the href attribute as needed
            tr.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
                RouterAssistant.redirect('s_match', {
                    match: match.id,
                    name: match.matchName
                })
            }))


            // Time Cell
            const timeTd = document.createElement('td');
            timeTd.classList.add('time-cell');
            timeTd.textContent = PredictionComponent.formatTime(match.matchDate);
            tr.appendChild(timeTd);

            // Match Cell
            const matchTd = document.createElement('td');
            matchTd.classList.add('match-cell');

            // Team 1 Container
            const team1Container = document.createElement('div');
            team1Container.classList.add('team-container');
            const team1Img = document.createElement('img');
            team1Img.src = match.teams[0].logo === 'noImageFound' ? noImageFound : match.teams[0].logo === 'noImageFoundPlayer' ? noImageFoundPlayer : match.teams[0].logo;
            team1Img.alt = match.teams[0].name + " Logo";
            team1Img.classList.add('team-logo');
            team1Img.title = match.teams[0].name; // Tooltip for team name
            const team1Name = document.createElement('span');
            team1Name.classList.add('team-name');
            team1Name.textContent = match.teams[0].name;
            team1Container.appendChild(team1Img);
            team1Container.appendChild(team1Name);

            // Team 2 Container
            const team2Container = document.createElement('div');
            team2Container.classList.add('team-container');
            const team2Img = document.createElement('img');
            team2Img.src = match.teams[1].logo === 'noImageFound' ? noImageFound : match.teams[1].logo === 'noImageFoundPlayer' ? noImageFoundPlayer : match.teams[1].logo;
            team2Img.alt = match.teams[1].name + " Logo";
            team2Img.classList.add('team-logo');
            team2Img.title = match.teams[1].name; // Tooltip for team name
            const team2Name = document.createElement('span');
            team2Name.classList.add('team-name');
            team2Name.textContent = match.teams[1].name;
            team2Container.appendChild(team2Img);
            team2Container.appendChild(team2Name);

            // Assemble Match Cell
            matchTd.appendChild(team1Container);
            matchTd.appendChild(team2Container);
            tr.appendChild(matchTd);

            // Odds Cells (1, X, 2)
            ["1", "X", "2"].forEach(key => {
                const oddsTd = document.createElement('td');

                // Odds Value
                const oddsValue = document.createElement('div');
                oddsValue.classList.add('odds-value');
                oddsValue.textContent = match.odds[key] !== "-" ? parseFloat(match.odds[key]).toFixed(2) : "-";

                // Thunderpick Logo
                // const bookmakerImg = document.createElement('img');
                // bookmakerImg.src = thunderpickLogo;
                // bookmakerImg.alt = "Thunderpick Logo";
                // bookmakerImg.classList.add('bookmaker-logo');

                // Assemble Odds Cell
                oddsTd.appendChild(oddsValue);
                // oddsTd.appendChild(bookmakerImg);

                tr.appendChild(oddsTd);
            });

            // Tournament Name Cell
            const tournamentTd = document.createElement('td');
            tournamentTd.classList.add('tournament-name');
            tournamentTd.textContent = match.tournamentName;
            tr.appendChild(tournamentTd);

            // Tournament Name Cell
            const authorTd = document.createElement('td');
            const authorContainer = document.createElement('div');
            authorContainer.classList.add('author-container');
            const author = document.createElement('span');
            author.classList.add('author-name');
            author.textContent = match.authorName;
            const authorImg = document.createElement('img');
            authorImg.src = match.authorPhoto;
            authorImg.alt = match.authorName + " Logo";
            authorImg.classList.add('author-logo');
            authorContainer.appendChild(authorImg);
            authorContainer.appendChild(author);
            authorTd.appendChild(authorContainer);
            tr.appendChild(authorTd);


            // If the match is in the past and outcome is available, apply prediction status
            if (match.state !== "Upcoming") {
                if (isPast && match.state === "W") {
                    const outcomeTd = document.createElement('td');
                    const outcomeSpan = document.createElement('span');
                    const bufferSpan = document.createElement('span');
                    const outcomeIcon = document.createElement('i');
                    bufferSpan.textContent = " ";
                    outcomeSpan.classList.add('text-success');
                    outcomeSpan.classList.add('checkPredictStateClass');
                    outcomeIcon.classList.add('bi', 'bi-check-circle-fill');
                    outcomeSpan.appendChild(bufferSpan);
                    outcomeSpan.appendChild(outcomeIcon);
                    outcomeTd.appendChild(outcomeSpan);
                    tr.appendChild(outcomeTd);
                } else if (match.state === "Canceled") {
                    const outcomeTd = document.createElement('td');
                    const outcomeSpan = document.createElement('span');
                    const bufferSpan = document.createElement('span');
                    const outcomeIcon = document.createElement('i');
                    bufferSpan.textContent = " ";
                    outcomeSpan.classList.add('checkPredictStateClass');
                    outcomeIcon.classList.add('bi', 'bi-dash-circle-fill');
                    outcomeSpan.appendChild(bufferSpan);
                    outcomeSpan.appendChild(outcomeIcon);
                    outcomeTd.appendChild(outcomeSpan);
                    tr.appendChild(outcomeTd);

                } else if (isPast && match.state === "L") {
                    const outcomeTd = document.createElement('td');
                    const outcomeSpan = document.createElement('span');
                    const bufferSpan = document.createElement('span');
                    const outcomeIcon = document.createElement('i');
                    bufferSpan.textContent = " ";
                    outcomeSpan.classList.add('text-danger');
                    outcomeSpan.classList.add('checkPredictStateClass');
                    outcomeIcon.classList.add('bi', 'bi-x-circle-fill');
                    outcomeSpan.appendChild(bufferSpan);
                    outcomeSpan.appendChild(outcomeIcon);
                    outcomeTd.appendChild(outcomeSpan);
                    tr.appendChild(outcomeTd);
                }
            } else { // If the match is in the future or outcome is not available, leave the cell empty
                const outcomeTd = document.createElement('td');
                outcomeTd.textContent = " ";
                tr.appendChild(outcomeTd);
            }
            // Append Row to Table Body
            tbody.appendChild(tr);
        });

        if (document.querySelectorAll('#predictions-tbody tr').length === 0) {
            const noMatchTr = document.createElement('tr');
            const noMatchTd = document.createElement('td');
            noMatchTd.colSpan = 8;
            noMatchTd.textContent = `No prediction for this date, come back later or try another game.`;
            noMatchTd.classList.add('translateNoPredictions');
            noMatchTd.style.fontStyle = "italic";
            noMatchTd.style.color = "#8b949e";
            noMatchTr.appendChild(noMatchTd);
            tbody.appendChild(noMatchTr);
            return;
        }

        const elements = document.querySelectorAll('.date-box');
        const activeElement = Array.from(elements).find(el => el.classList.contains('active'));
        const dateSelector = document.getElementById('dateSelector');
        setTimeout(() => { // setting timeout to 0 (wtf) to ensure it works
            if (dateSelector) {
                if (dateContent) {
                    // PredictionComponent.currTranslateX = Math.max(PredictionComponent.currTranslateX - dateSelector.clientWidth / 4, -600); // Ensure it does not go beyond the right limit
                    dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;
                }

                // Get the position of the active element relative to the parent container
                const activeElementRect = activeElement.getBoundingClientRect();
                const containerRect = dateContent.getBoundingClientRect();
                // console.debug('Active Element Rect:', activeElement, activeElementRect, 'Container Rect:', dateContent, containerRect);
                // Calculate the offset of the active element within the container
                const elementOffsetLeft = activeElementRect.left - containerRect.left;

                function getScrollLimit() {
                    return dateContent.clientWidth / 2;
                }
                const scrollLimit = getScrollLimit();

                if (activeElement) {
                    // console.debug('here', activeElement);
                    // Calculate the necessary translateX value to bring the element into view
                    const targetTranslateX = ((elementOffsetLeft / dateContent.clientWidth) * (2 * -scrollLimit)) + scrollLimit;
                    // Set the new translateX and update the currentTranslateX
                    PredictionComponent.currTranslateX = targetTranslateX;
                    dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;
                    // console.debug('Element offset:', elementOffsetLeft, 'New TranslateX:', targetTranslateX);
                } else {
                    console.warn('No active element found in the list.');
                }

                // Scroll Arrow Functionality
                let scrollLeftBtn = document.querySelector('.scroll-left');
                let scrollRightBtn = document.querySelector('.scroll-right');
                if (scrollLeftBtn && scrollRightBtn) {
                    // Remove all listeners from the scroll buttons
                    scrollLeftBtn = MatchesComponent.removeAllEventListeners(scrollLeftBtn);
                    scrollRightBtn = MatchesComponent.removeAllEventListeners(scrollRightBtn);

                    scrollLeftBtn.addEventListener('click', () => {
                        // console.debug('Scroll Left Clicked');
                        PredictionComponent.currTranslateX = Math.min(PredictionComponent.currTranslateX + dateSelector.clientWidth / 4, scrollLimit); // Ensure it does not go beyond the left limit
                        dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;
                        // console.debug('Current TranslateX:', PredictionComponent.currTranslateX, "dateSelector.clientWidth", dateSelector.clientWidth);
                    });

                    scrollRightBtn.addEventListener('click', () => {
                        // console.debug('Scroll Right Clicked');
                        PredictionComponent.currTranslateX = Math.max(PredictionComponent.currTranslateX - dateSelector.clientWidth / 4, -scrollLimit); // Ensure it does not go beyond the right limit
                        dateContent.style.transform = `translateX(${PredictionComponent.currTranslateX}px)`;
                        // console.debug('Current TranslateX:', PredictionComponent.currTranslateX, "dateSelector.clientWidth", dateSelector.clientWidth);
                    });

                    dateContent.addEventListener('touchmove', (e) => {
                        e.preventDefault(); // Prevent swiping or dragging
                    }, { passive: false });
                    dateContent.addEventListener('wheel', (e) => {
                        e.preventDefault();
                    }, { passive: false });
                }
            }
        }, 0);
    }

    static formatTime(isoString) {
        // Function to Format Time from ISO String
        const date = new Date(isoString);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const strTime = hours + ':' + minutes; // 24-hour format
        return strTime;
    }

    // Function to Format Date as YYYY-MM-DD
    static formatDate(date) {
        const y = date.getFullYear();
        const m = String(date.getMonth() + 1).padStart(2, '0');
        const d = String(date.getDate()).padStart(2, '0');
        return `${y}-${m}-${d}`;
    }

    static dynamicTranslations() {
        document.querySelectorAll('.todayClass').forEach(element => {
            element.innerHTML = I18n.translateString("predictionsTranslations.today");
        });
        document.querySelectorAll('.translateNoPredictions').forEach(element => {
            element.innerHTML = I18n.translateString("predictionsTranslations.noPredictionForThisDate");
        });
    }

    static translations() {
        document.querySelector('.translateTitle').innerHTML = I18n.translateString("predictionsTranslations.title");
        document.querySelector('.translateSubtitle').innerHTML = I18n.translateString("predictionsTranslations.subtitle");
        document.querySelector('.th-time').innerHTML = I18n.translateString("predictionsTranslations.time");
        document.querySelector('.th-match').innerHTML = I18n.translateString("predictionsTranslations.match");
        document.querySelector('.th-tipster').innerHTML = I18n.translateString("predictionsTranslations.tipster");
        document.querySelector('.th-tournament').innerHTML = I18n.translateString("predictionsTranslations.tournament");
        document.querySelector('.translate-stats-avg-odds').innerHTML = I18n.translateString("predictionsTranslations.statsAvgOdds");
        document.querySelector('.translate-stats-earn').innerHTML = I18n.translateString("predictionsTranslations.statsEarn");
        document.querySelector('.translate-stats-bets').innerHTML = I18n.translateString("predictionsTranslations.statsBets");
        document.querySelector('.translate-stats-open-bets').innerHTML = I18n.translateString("predictionsTranslations.statsOpenBets");
        // document.querySelector('.translate-stats-tipster').innerHTML = I18n.translateString("predictionsTranslations.tipster");
    }

    static js(gameId) {



        // Function to Initialize the Custom Scrollable Datepicker
        function initializeDateSelector() {


            const dateSelector = document.getElementById('dateSelector');
            if (!dateSelector) {
                console.error("Element with id 'dateSelector' not found.");
                return;
            }
            dateSelector.innerHTML = ''; // Clear existing rows

            const allDates = generateDateRange(new Date(), 14); // Generate 7  days before and after today
            const todayStr = PredictionComponent.formatDate(new Date());

            // Center datepicker around today with past and future dates
            allDates.forEach((dateObj, index) => {
                const dateStr = PredictionComponent.formatDate(dateObj);
                const day = dateObj.getDate();
                const dayName = dateObj.toLocaleString(MatchesComponent.userLanguage, { weekday: 'short' });
                const monthName = dateObj.toLocaleString(MatchesComponent.userLanguage, { month: 'short' });

                const dateBox = document.createElement('div');
                dateBox.classList.add('date-box');

                // Label 'Today' if the date is today
                const isTodayDate = (dateStr === todayStr);
                if (isTodayDate) {
                    if (!PredictionComponent.rememberedDate) {
                        dateBox.classList.add('active');
                    }
                    dateBox.innerHTML = `<span class="day-number">Today</span><span class="day-name">${dayName}</span>`;
                } else {
                    dateBox.innerHTML = `<span class="day-number">${day}</span><span class="day-name">${dayName} ${monthName}</span>`;
                }

                dateBox.setAttribute('data-date', dateStr);
                dateBox.setAttribute('role', 'button');
                dateBox.setAttribute('tabindex', '0');
                dateBox.setAttribute('aria-label', 'Select ' + (isTodayDate ? 'Today' : dayName + ' ' + monthName + ' ' + day));

                // Event Listener for Date Selection
                dateBox.addEventListener('click', function () {
                    // Remove 'active' from all date boxes
                    document.querySelectorAll('.date-box').forEach(box => box.classList.remove('active'));
                    // Add 'active' to the clicked date box
                    this.classList.add('active');
                    const selectedDate = this.getAttribute('data-date');
                    // Render predictions for today by default
                    PredictionComponent.rememberedDate = selectedDate;
                    console.debug('Selected Date:', selectedDate);
                    console.debug('Remembered Date:', PredictionComponent.rememberedDate);
                    PredictionComponent.renderPredictions(gameId, PredictionComponent.rememberedDate ? PredictionComponent.rememberedDate : selectedDate);
                    PredictionComponent.dynamicTranslations();


                });

                // Accessibility: Handle keyboard events
                dateBox.addEventListener('keypress', function (e) {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        this.click();
                    }
                });

                dateSelector.appendChild(dateBox);
            });

            const dateContent = document.createElement('div');
            dateContent.classList.add('date-content');
            while (dateSelector.firstChild) {
                dateContent.appendChild(dateSelector.firstChild);
            }
            dateSelector.appendChild(dateContent);




        }

        // Function to Generate a Date Range (past + future days)
        function generateDateRange(startDate, numberOfDays) {
            const dates = [];
            const halfDays = Math.floor(numberOfDays / 2);
            // Generate past dates
            for (let i = halfDays; i > 0; i--) {
                const pastDate = new Date(startDate);
                pastDate.setDate(startDate.getDate() - i);
                dates.push(pastDate);
            }
            // Add today
            dates.push(new Date(startDate));
            // Generate future dates
            for (let i = 1; i <= halfDays; i++) {
                const futureDate = new Date(startDate);
                futureDate.setDate(startDate.getDate() + i);
                dates.push(futureDate);
            }
            return dates;
        }



        initializeDateSelector();


        // Render predictions for today by default
        const todayStr = PredictionComponent.formatDate(new Date());
        let dayToCheck = document.querySelector('.date-box.active');
        PredictionComponent.renderPredictions(gameId, dayToCheck ? dayToCheck.getAttribute('data-date') : PredictionComponent.rememberedDate ? PredictionComponent.rememberedDate : todayStr);
        PredictionComponent.renderAllPredictions(gameId);
        PredictionComponent.renderTipsterStats();
        PredictionComponent.dynamicTranslations();
        PredictionComponent.sortTable();
        PredictionComponent.tooltips();
    }

    static currTranslateX = 0;
    static rememberedDate = null;

    static tooltips() {

        let tooltipElements = document.querySelectorAll('.predictions-tooltip-engage');

        tooltipElements.forEach((tooltipElement) => {
            tooltipElement.addEventListener("mouseenter", (e) => {
                const tooltip = document.createElement("div");
                tooltip.className = "tooltip-predictionspage-stats";
                tooltip.innerHTML = I18n.translateString(`predictionsTranslations.${tooltipElement.getAttribute("data-tooltip")}`);
                document.body.appendChild(tooltip);

                const rect = tooltipElement.getBoundingClientRect();

                const clientWidth = document.documentElement.clientWidth;
                if (clientWidth < 768) {
                    tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2 - 60}px`;
                } else {
                    tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2}px`;
                }

                tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 10}px`;
                tooltip.style.opacity = "1";

                tooltipElement.addEventListener("mouseleave", () => {
                    tooltip.style.opacity = "0";
                    tooltip.addEventListener("transitionend", () => tooltip.remove());
                });
                // Mobile-specific handling
                // tooltipElement.addEventListener("touchstart", () => {
                //     console.debug('change because of touchstart');
                //     tooltip.style.opacity = "0";
                //     tooltip.addEventListener("transitionend", () => tooltip.remove());
                // });
            });

            // Listen for scroll, touchmove, and wheel events
            window.addEventListener("scroll", () => {
                if (document.querySelector('.tooltip-predictionspage-stats')) {
                    const tooltip = document.querySelector('.tooltip-predictionspage-stats');
                    tooltip.style.opacity = "0";
                    tooltip.addEventListener("transitionend", () => tooltip.remove());
                }
            }, { passive: true });
            window.addEventListener("wheel", () => {
                if (document.querySelector('.tooltip-predictionspage-stats')) {
                    const tooltip = document.querySelector('.tooltip-predictionspage-stats');
                    tooltip.style.opacity = "0";
                    tooltip.addEventListener("transitionend", () => tooltip.remove());
                }
            }, { passive: true });
            window.addEventListener("touchmove", () => {
                if (document.querySelector('.tooltip-predictionspage-stats')) {
                    const tooltip = document.querySelector('.tooltip-predictionspage-stats');
                    tooltip.style.opacity = "0";
                    tooltip.addEventListener("transitionend", () => tooltip.remove());
                }
            }, { passive: true });
            window.addEventListener("popstate", () => {
                if (document.querySelector('.tooltip-predictionspage-stats')) {
                    const tooltip = document.querySelector('.tooltip-predictionspage-stats');
                    tooltip.style.opacity = "0";
                    tooltip.addEventListener("transitionend", () => tooltip.remove());
                }
            });
        });
    }

}

PredictionComponent.template = /*html*/ `

<div class="container py-2">
        <!-- Header Section -->
        <div class="header">
            <h1 class="translateTitle">Esports Predictions</h1>
            <p class="translateSubtitle">At MyEsports, we study each match closely to bring you the best betting tips in esports.</p>
        </div>

        <!-- Custom Scrollable Datepicker with Scroll Arrows -->
        <div class="date-selector-container">
            <button class="scroll-arrow scroll-left" aria-label="Scroll Left"><i class="bi bi-chevron-left"></i></button>
            <div class="date-selector" id="dateSelector">
                <!-- Date boxes will be injected here by JavaScript -->
            </div>
            <button class="scroll-arrow scroll-right" aria-label="Scroll Right"><i class="bi bi-chevron-right"></i></button>
        </div>

        <!-- Predictions Table -->
        <div class="table-responsive">
            <table class="predictions-table">
                <thead>
                    <tr>
                        <th class="th-time">Time</th>
                        <th class="th-match">Match</th>
                        <th>1</th>
                        <th>X</th>
                        <th>2</th>
                        <th class='th-tournament'>Tournament</th>
                        <th class="th-tipster">Tipster</th>
                        <th class="th-outcome"></th>
                    </tr>
                </thead>
                <tbody id="predictions-tbody">
                    <!-- Predictions rows will be injected here by JavaScript -->
                </tbody>
            </table>
        </div>
        <!-- Predictions Stats -->
        <div class="table-responsive2">
            <table class="predictions-table">
                <thead>
                    <tr>
                    <th class="translate-stats-tipster">Tipster</th>
                    <th class="clickable stats-bets-th"><span class="translate-stats-bets">Bets</span> <i class="bi bi-sort-up sort-icons" data-column="1"></i></th>
                    <th class="clickable predictions-tooltip-engage stats-earn-th" data-tooltip="EarnExplain"><span class="translate-stats-earn">Κέρδος</span> <i class=" bi bi-sort-up sort-icons" data-column="2"></i></th>
                    <th class="clickable predictions-tooltip-engage stats-wr-th" data-tooltip="WinrateExplain"><span class="translate-stats-wr">(%)</span> <i class=" bi bi-sort-up sort-icons" data-column="3"></i></th>
                    <th class="clickable predictions-tooltip-engage stats-roi-th" data-tooltip="RoiExplain"><span class="translate-stats-roi">ROI</span> <i class=" bi bi-sort-up sort-icons" data-column="4"></i></th>
                    <th class="clickable predictions-tooltip-engage stats-avg-odds-th" data-tooltip="AvgOddsExplain"><span class="translate-stats-avg-odds">Μ.Ο. απ.</span> <i class=" bi bi-sort-up sort-icons" data-column="5"></i></th>
                    <th class="stats-open-bets-th"><span class="translate-stats-open-bets">Open bets</span></th>
                    </tr>
                </thead>
                <tbody id="predictions-stats-tbody">
                    <!-- Predictions rows will be injected here by JavaScript -->
                </tbody>
            </table>
        </div>
    </div>

`;

PredictionComponent.reg('predictions-component');

