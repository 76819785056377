export class GeofencingGuard {
    static initiate() {
        try {
            fetch('https://api.country.is/', {
                method: "GET",
                cache: "no-cache",
                headers: { "Content-Type": "application/json" },
                redirect: "follow",
                referrerPolicy: "no-referrer",
            }).then(x => x.json())
                .then(data => {
                    window.geoFencingGuard = data.country;
                    // if (!window.location.host.includes('localhost')) {
                    //     if (data.country === 'GR' && window.location.host.includes('myesports.gg')) {
                    //         window.location.href = window.location.href.replace('.gg', '.gr').replace('.html', '')
                    //     } else if (data.country !== 'GR' && window.location.host.includes('myesports.gr')) {
                    //         window.location.href = window.location.href.replace('.gr', '.gg').replace('.html', '')
                    //     }
                    // }
                })
                .catch(error => {
                    // This catch block will only trigger for errors within this block (e.g., parsing JSON failing)
                    // console.error("Error fetching country:", error);
                    window.geoFencingGuard = "EN";
                })
        } catch (e) {
            window.geoFencingGuard = "EN"
        }
    }

    static getUserCountry(callback) {
        if (window.geoFencingGuard === undefined)
            setTimeout(() => {
                GeofencingGuard.getUserCountry(callback)
            }, 50)
        else
            callback(window.geoFencingGuard)
    }
}
