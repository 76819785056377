
import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { I18n } from "../../utilities/i18n/i18n";
import { GeofencingGuard } from "../../utilities/geofencing-guard/geofencing-guard";

const css = `
    <style>
        .adblock-banner {
            position: fixed;
            bottom: 0; /* Change to 'top: 0' for a top banner */
            left: 0;
            width: 100%;
            background-color: #f8d7da;
            color: #721c24;
            text-align: center;
            padding: 15px;
            font-family: Arial, sans-serif;
            font-size: 16px;
            z-index: 2147483999;
            display: none; /* Initially hidden */
        }

        .adblock-banner button {
            background-color: #dc3545;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
        }

        .adblock-banner button:hover {
            background-color: #c82333;
        }
    </style>
`

export class AdblockComponent extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
        AdblockComponent.javascript();

    }

    static adblockCheck = false;
    static javascript() {


        // Function to close the banner
        function closeBanner() {
            document.getElementById('adblockBanner').style.display = 'none';
        }




        let html = /*html*/ `
                <!-- AdBlock Detection Floating Banner -->
    <div class="adblock-banner" id="adblockBanner">
        <!-- dynamic message from I18N comes here -->
    </div>
    `;

        // Add the banner to the page
        document.getElementById('produced-html').insertAdjacentHTML("beforeend", html);
        document.getElementById('adblockBanner').innerHTML = I18n.translateString('adblockDetector.adblockParagraph');

        // Function to check if an adblocker is active
        async function detectAdBlock() {
            const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
            try {
                await fetch(new Request(googleAdUrl)).catch(_ => AdblockComponent.adblockCheck = true); // method 1 to detect adblock

                await fetch('https://api.country.is/', { // method 2 to detect adblock
                    method: "GET",
                    cache: "no-cache",
                    headers: { "Content-Type": "application/json" },
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                }).then(x => x.json())
                    .then(data => {
                        window.geoFencingGuard = data.country;
                    })
                    .catch(error => {
                        AdblockComponent.adblockCheck = true;
                    })
            } catch (e) {
                AdblockComponent.adblockCheck = true
            } finally {
                console.debug(`AdBlock Enabled: ${AdblockComponent.adblockCheck}`)
                if (AdblockComponent.adblockCheck) {
                    document.getElementById('adblockBanner').style.display = 'block';
                } else {
                    closeBanner();
                }
            }
        }
        // Detect Adblock when the page loads
        window.onload = function () {
            detectAdBlock();
        };

        document.getElementById('close-button').addEventListener('click', closeBanner);
    }
}

AdblockComponent.template = /*html*/ `
<div id="produced-html"></div>
  
`;

AdblockComponent.reg('adblockerdetect-component');
