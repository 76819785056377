import { initializeApp } from "firebase/app";
import {
    initializeFirestore,
    setDoc,
    doc,
    getDoc,
    getDocs,
    collection,
    onSnapshot,
    query,
    orderBy,
    where,
    limit,
    documentId,
    startAfter
} from "firebase/firestore";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFunctions } from 'firebase/functions'
import { getStorage } from "firebase/storage";
import { RouterAssistant } from "../router-assistant/router-assistant.js";
import { I18n } from "../i18n/i18n.js";
import { MiscAssistant } from "../misc-assistant/misc-assistant";
import { LoadingSplasher } from "../../components/loading-splasher/loading-splasher";

const FUNCTION_URL_helloWorld = 'https://helloworld-i7wfxnwogq-uc.a.run.app'
const FUNCTION_URL_getRosters = 'https://getrosters-i7wfxnwogq-uc.a.run.app'

export class FirebaseAssistant {
    static firebaseConfig = {
        apiKey: "AIzaSyBt-xKciT0SZODQbdAhXKqjN5ZTYHxBSSA",
        authDomain: "myesports-65c4d.firebaseapp.com",
        databaseURL: "https://myesports-65c4d-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "myesports-65c4d",
        storageBucket: "myesports-65c4d.appspot.com",
        messagingSenderId: "740670413394",
        appId: "1:740670413394:web:25004a9da0bdca4b7e1d8b",
        measurementId: "G-CF1KG790M6"
    };

    static initiate() {
        LoadingSplasher.activateSplasher('', true)
        window.app = initializeApp(FirebaseAssistant.firebaseConfig);
        window.auth = getAuth(app);
        window.firestore = initializeFirestore(app, { PersistenceEnabled: false, experimentalAutoDetectLongPolling: true, useFetchStreams: false });
        window.auth.languageCode = I18n.getUsersPreferredLanguage()
        window.storage = getStorage(app)
        window.functions = getFunctions(app)
        setPersistence(window.auth, browserLocalPersistence)
            .then(() => {
            })
            .catch((error) => {
                console.debug(error.code + " : " + error.message)
            });
        window.auth.onAuthStateChanged(user => {
            LoadingSplasher.activateSplasher('')
            if (user) {
                FirebaseAssistant.userExists().then((userExists) => {
                    if (userExists) {
                        RouterAssistant.authStateChangedRerouting(true, userExists);
                    } else {
                        RouterAssistant.authStateChangedRerouting(true, userExists);
                    }
                })
            } else {
                RouterAssistant.authStateChangedRerouting(false, null)
            }
        })
    }

    static userExists() {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(window.firestore, 'users', window.auth.currentUser.uid);
            await getDoc(docRef).then(async function (docSnap) {
                resolve(docSnap.exists())
            }).catch(function (error) {
                console.debug("Error getting document:", error);
            });
        })
    }

    static performNewUserActions(userIsProvider) {
        FirebaseAssistant.userExists().then((exists) => {
            // console.debug("does user exist?: " + exists.toString())
            if (!exists) {
                FirebaseAssistant.registerNewUserToDatabase(userIsProvider).then(r => {
                    // console.debug(r)
                })
            }
        })
    }

    static registerNewUserToDatabase() { // when this changes it needs to happen at functions as well
        return new Promise(async (resolve, reject) => {
            try {
                const initialData = { foo: 'bar' } // todo change this to required initiation data
                setDoc(doc(window.firestore, "users", window.auth.currentUser.uid), initialData).then((r) => {
                    window.eUser = initialData;
                    window.sharedTimelines = {};
                    window.userHasPremiumPaidForCurrentMonth = true;
                    RouterAssistant.authStateChangedRerouting(true, true)
                });
            } catch (e) {
                console.debug(e)
            }
        })
    }

    static getAllAuthors(callback) {
        const q = query(
            collection(window.firestore, "authors"),
            where("status", "!=", "draft")
        );
        getDocs(q).then(results => {
            callback(results);
        });
    }

    static getAllBookmakers(callback) {
        const q = query(
            collection(window.firestore, "bookmakers"),
            where("status", "!=", "draft")
        );
        getDocs(q).then(results => {
            callback(results);
        });
    }

    static getAllPredictions(callback) {
        console.debug('running firebase getAllPredictions() for the first time')
        const q = query(
            collection(window.firestore, "predictions"),
            where("status", "!=", "Draft")
        );
        getDocs(q).then(results => {
            callback(results);
        });
    }

    static getSinglePrediction(predictionId, callback) {
        console.debug('running firebase getSinglePrediction() for the first time', predictionId)
        const predictionQuery = query(
            collection(window.firestore, "predictions"),
            where("id", "==", Number(predictionId)),
            where("status", "!=", "Draft")
        );

        getDocs(predictionQuery).then((predictionSnapshot) => {
            callback(predictionSnapshot);
        });
    }

    static getAllBookmakersSkins(callback) {
        const q = query(
            collection(window.firestore, "bookmakers_skin"),
            where("status", "!=", "draft")
        );
        getDocs(q).then(results => {
            callback(results);
        });
    }

    static getMatchesArchive(callback) {
        console.debug('running firebase getMatches() for the first time')
        return onSnapshot(doc(window.firestore, "Status", "fetchDataAndUpdateViewerCount"), (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                const currentTime = new Date();
                const sixHoursEarlier = new Date(currentTime.getTime() - 6 * 60 * 60 * 1000);
                const dayLater = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);

                const sixHoursEarlierISO = sixHoursEarlier.toISOString();
                const dayLaterISO = dayLater.toISOString();

                const matchesQuery = query(
                    collection(window.firestore, "MatchesGeneral"),
                    where("scheduled_at", ">=", sixHoursEarlierISO),
                    where("scheduled_at", "<=", dayLaterISO),
                    orderBy("scheduled_at")
                );

                getDocs(matchesQuery).then((matchesSnapshot) => {
                    callback(matchesSnapshot);
                });
            }
        });
    }

    static getMatches(callback, date = new Date()) {
        return onSnapshot(doc(window.firestore, "Status", "fetchDataAndUpdateViewerCount"), async (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                // const currentTime = new Date();
                console.debug('date', date)
                const dateObject = new Date(date);
                const startOfDay = new Date(dateObject.setHours(0, 0, 0, 0));
                const endOfDay = new Date(dateObject.setHours(23, 59, 59, 999));

                const startOfDayISO = startOfDay.toISOString();
                const endOfDayISO = endOfDay.toISOString();
                console.debug('running firebase getMatches() for DATE->' + date + ' for the first time')
                const matchesQuery = query(
                    collection(window.firestore, "MatchesGeneral"),
                    where("scheduled_at", ">=", startOfDayISO),
                    where("scheduled_at", "<=", endOfDayISO),
                    orderBy("scheduled_at")
                );

                const matchesSnapshot = await getDocs(matchesQuery);

                // Check localStorage favourites
                const favourites = JSON.parse(localStorage.getItem('myEsportsfavourites')) || {};
                const favIds = Object.keys(favourites)
                    .filter(key => key.startsWith('favCheck-') && favourites[key] === true)
                    .map(key => key.replace('favCheck-', ''));

                if (favIds.length > 0) {
                    const favMatchesQuery = query(
                        collection(window.firestore, "MatchesGeneral"),
                        where(documentId(), 'in', favIds)
                    );

                    const favMatchesSnapshot = await getDocs(favMatchesQuery);

                    // Combine matches and favourite matches
                    const combinedMatches = [...matchesSnapshot.docs, ...favMatchesSnapshot.docs];
                    callback(combinedMatches);
                } else {
                    callback(matchesSnapshot.docs);
                }
            }
        });
    }

    static getPredictionMatches(theIds, callback) {
        console.debug('running firebase getPredictionMatches() for the first time', theIds);
        const matchesQuery = query(
            collection(window.firestore, "MatchesGeneral"),
            where(documentId(), 'in', theIds)
        );

        getDocs(matchesQuery).then((matchesSnapshot) => {
            // console.debug('here3', theIds);
            callback(matchesSnapshot);
        });
    }

    static getAnalysesV2(callback, date = new Date()) {
        return onSnapshot(doc(window.firestore, "Status", "updateAnalyses"), async (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                console.debug('date', date)
                console.debug('running firebase getAnalysesV2() for DATE->' + date + ' for the first time')
                const gameTypes = [3, 4, 1, 25, 26, 23, 14, 20, 24, 22, 29, 30, 27];
                const promises = [];

                for (let type of gameTypes) {

                    const matchesQuery = query(
                        collection(window.firestore, "analysis"),
                        where("language", "==", I18n.getUsersPreferredLanguage()),
                        where("status.state", "==", 'COMPLETED'),
                        where("gameId", "==", type),
                        orderBy("status.updateTime", "desc"),
                        limit(5)
                    );
                    promises.push(getDocs(matchesQuery));
                }

                const querySnapshots = await Promise.all(promises);
                const results = [];
                querySnapshots.forEach(snapshot => {
                    results.push(...snapshot.docs)
                });

                results.sort((a, b) => {
                    return b.data().status.updateTime.toMillis() - a.data().status.updateTime.toMillis();
                });

                callback(results);
            }
        });
    }

    static getNews(callback) {
        console.debug('running firebase getNews() for the first time');
        const newsCollection = collection(window.firestore, "news");
        const sortedQuery = query(newsCollection, orderBy("writtenAt", "desc"));
        getDocs(sortedQuery).then(results => {
            callback(results);
        });
    }

    static getStreamers(callback) {
        console.debug('running firebase getStreamers() for the first time')
        getDocs(collection(window.firestore, "livestreamers")).then(results => {
            callback(results)
        })
    }

    static getPastMatches(opponentId1, opponentId2, callback) {
        console.debug('running firebase getPastMatches() for the first time')
        const pastMatchesQuery1 = query(
            collection(window.firestore, "MatchesGeneral"),
            where("status", "==", "finished"),
            where("opponentsIds", "array-contains", opponentId1),
            orderBy("scheduled_at", "desc"),
            limit(11)
        );
        const pastMatchesQuery2 = query(
            collection(window.firestore, "MatchesGeneral"),
            where("status", "==", "finished"),
            where("opponentsIds", "array-contains", opponentId2),
            orderBy("scheduled_at", "desc"),
            limit(11)
        );
        getDocs(pastMatchesQuery1).then((matchesSnapshot1) => {
            getDocs(pastMatchesQuery2).then((matchesSnapshot2) => {
                callback(matchesSnapshot1, matchesSnapshot2);
            });
        });
    }

    static getH2hPastMatches(opponentId1, opponentId2, callback) {
        console.debug('running firebase getPastMatches() for the first time')
        const pastMatchesQuery1 = query(
            collection(window.firestore, "MatchesGeneral"),
            where("status", "==", "finished"),
            where("opponentsIds", "in", [[opponentId1, opponentId2]]),
            orderBy("scheduled_at", "desc"),
            limit(11)
        );
        const pastMatchesQuery2 = query(
            collection(window.firestore, "MatchesGeneral"),
            where("status", "==", "finished"),
            where("opponentsIds", "in", [[opponentId2, opponentId1]]),
            orderBy("scheduled_at", "desc"),
            limit(11)
        );
        getDocs(pastMatchesQuery1).then((matchesSnapshot1) => {
            getDocs(pastMatchesQuery2).then((matchesSnapshot2) => {
                let combinedMatches = [...matchesSnapshot1.docs, ...matchesSnapshot2.docs];
                combinedMatches.sort((a, b) => new Date(b.data().scheduled_at) - new Date(a.data().scheduled_at));
                callback(combinedMatches);
            });
        });
    }

    static getLiveMatches(callback) {
        console.debug('running firebase getMatches() for the first time to get the live data')
        return onSnapshot(doc(window.firestore, "Status", "fetchDataAndUpdateViewerCountLive"), (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                const currentTime = new Date();
                const fifteenMinutesAgo = new Date(currentTime.getTime() - 15 * 60 * 1000);
                const fifteenMinutesAgoISO = fifteenMinutesAgo.toISOString();

                const runningMatchesQuery = query(
                    collection(window.firestore, "MatchesGeneral"),
                    where("status", "==", "running"),
                );

                const finishedMatchesQuery = query(
                    collection(window.firestore, "MatchesGeneral"),
                    where("status", "==", "finished"),
                    where("end_at", ">=", fifteenMinutesAgoISO),
                );

                Promise.all([
                    getDocs(runningMatchesQuery),
                    getDocs(finishedMatchesQuery)
                ]).then(([runningMatchesSnapshot, finishedMatchesSnapshot]) => {
                    callback([...runningMatchesSnapshot.docs, ...finishedMatchesSnapshot.docs]);
                });
            }
        });
    }

    static getSingleMatch(matchId, callback) {
        console.debug('running firebase getSingleMatch() for the first time')
        return onSnapshot(doc(window.firestore, "Status", "fetchDataAndUpdateViewerCount"), (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                const matchesQuery = query(
                    collection(window.firestore, "MatchesGeneral"),
                    where("id", "==", Number(matchId)),
                );

                getDocs(matchesQuery).then((matchesSnapshot) => {
                    callback(matchesSnapshot);
                });
            }
        });
    }

    static getAnalysis(matchId, callback) {
        console.debug('running firebase getAnalysis() for the first time')
        getDoc(doc(window.firestore, "analysis", matchId.toString())).then(results => {
            if (results.exists()) {
                callback(results.data())
                // console.debug("here", results.data().output)
            } else {
                console.debug("No document found with matchId:", matchId);
                callback(null); // Pass null or some default value to the callback
            }
        }).catch(error => {
            console.error("Error getting document:", error);
            callback(null); // Pass null or some default value to the callback
        });
    }

    static async getMultipleMatches(matchIds, callback) {
        console.debug('running firebase getMultipleMatches() for the first time')
        const chunkSize = 10;
        const chunks = Array(Math.ceil(matchIds.length / chunkSize)).fill().map((_, index) => index * chunkSize).map(begin => matchIds.slice(begin, begin + chunkSize));
        // we have to do that cause firestore has a limit of 10 items in an "in" query

        const promises = chunks.map(chunk => {
            const matchesQuery = query(
                collection(window.firestore, "MatchesGeneral"),
                where("id", "in", chunk.map(Number)),
            );

            return getDocs(matchesQuery);
        });

        const results = await Promise.all(promises);
        const matches = results.flatMap(result => result.docs.map(doc => doc.data()));

        callback(matches);
    }

    static getTournaments(callback) {
        console.debug('running firebase getTournaments() for the first time');

        const tenDaysAgo = new Date();
        const fourMonthsAgo = new Date();
        tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
        fourMonthsAgo.setDate(fourMonthsAgo.getDate() - 120);

        return onSnapshot(doc(window.firestore, "Status", "fetchTournamentsWithMatches"), (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                const nullEndAtQuery = query(
                    collection(window.firestore, "TournamentsWithMatchesv2Light"),
                    where("end_at", "==", null),
                    where("begin_at", ">=", fourMonthsAgo.toISOString())
                );

                const recentEndAtQuery = query(
                    collection(window.firestore, "TournamentsWithMatchesv2Light"),
                    where("end_at", ">=", tenDaysAgo.toISOString())
                );

                Promise.all([
                    getDocs(nullEndAtQuery),
                    getDocs(recentEndAtQuery)
                ]).then(([nullEndAtSnapshot, recentEndAtSnapshot]) => {
                    callback([...nullEndAtSnapshot.docs, ...recentEndAtSnapshot.docs]);
                });
            }
        });
    }

    static getSingleTournament(tournamentId, callback) {
        console.debug('running firebase getSingleTournament() for the first time')
        return onSnapshot(doc(window.firestore, "Status", "fetchTournamentsWithMatches"), (statusSnapshot) => {
            if (statusSnapshot.exists()) {
                const tournamentQuery = query(
                    collection(window.firestore, "TournamentsWithMatchesv2"),
                    where("id", "==", Number(tournamentId)),
                );

                getDocs(tournamentQuery).then((tournamentSnapshot) => {
                    callback(tournamentSnapshot);
                });
            }
        });
    }

    static callHelloWorldFunction(TeamsVariable, callback) {

        MiscAssistant.postData(FUNCTION_URL_helloWorld, TeamsVariable).then(response => {
            callback(response)
        })
    }

    static callGetRostersFunctions(RosterVariable, callback) {

        MiscAssistant.postData(FUNCTION_URL_getRosters, RosterVariable).then(response => {
            callback(response)
        })
    }
}

//todo remove this after PoC is done (let it exist in main for now)
window.foo = function () {
    MiscAssistant.postData('https://littlescrapper-i7wfxnwogq-uc.a.run.app', {}).then(response => {
        console.debug(response)
    })
}
