import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { AuthorsComponent } from "../author-component/author-component";
import { NewsCarousel } from "../news-carousel/news-carousel";
import { PostMatchesComponent } from "../postmatches-component/postmatches-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";
import { MatchesComponent } from "../matches-component/matches-component";
import "../../utilities/bootstrap-assistant/assets/scroll-bar.css";
import showdown from 'showdown';
import { ref } from "firebase/storage";

const noAuthorImg = require('../postmatches-component/assets/author.webp');
const noTeamImg = require('../matches-component/Assets/no image found.png')




const css = `
<style>

.tooltip-authorpage-stats {
  position: absolute;
  font-size: 0.6rem;
  background-color: #161b22;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 21rem; /* Set a fixed width */
  white-space: normal; /* Allows text to wrap when needed */
  word-wrap: break-word; /* Breaks long words if they exceed the width */
  z-index: 1000;
  pointer-events: none; /* Prevent blocking hover */
  opacity: 0;
  transition: opacity 0.2s;
}

/* Show More Button */
postauthor-component        .show-more-predictions {
            text-align: center;
            margin: 1.3rem 0rem 0.9rem;
        }
postauthor-component        .show-more-predictions {
            background-color: #58a6ff; /* Button background */
            border: none;
            color: #FFFFFF;
            font-size: 0.9rem;
            font-weight: 700;
            padding: 10px 20px; /* Enhanced padding for touch */
            border-radius: 6px;
            cursor: pointer;
            transition: background-color 0.3s, box-shadow 0.3s;
        }
postauthor-component        .show-more-predictions:hover {
            background-color:rgb(65, 122, 189); /* Hover state */
            box-shadow: 0 6px 18px rgb(17, 66, 121);
        }

#bioHtmlPlaceholder hr {
    border: none; /* Remove default styling */
}
/* Headings styling */
#bioHtmlPlaceholder h1,#bioHtmlPlaceholder h2, #bioHtmlPlaceholder h3, #bioHtmlPlaceholder h4, #bioHtmlPlaceholder h5, #bioHtmlPlaceholder h6 {
    
    color: #ffffff;
    margin-bottom: 1rem;
}

#bioHtmlPlaceholder h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

#bioHtmlPlaceholder h2 {
    font-size: 2rem;
    line-height: 1.3;
}

#bioHtmlPlaceholder h3 {
    font-size: 1.75rem;
}

#bioHtmlPlaceholder h4 {
    font-size: 1.5rem;
}

/* Paragraphs styling */
#bioHtmlPlaceholder p {
    font-size: 1.1rem;
    color: #dadada;
    margin-bottom: 1.5rem;
}

/* Links */
#bioHtmlPlaceholder a {
    color: #007BFF !important;
    text-decoration: none !important;
    transition: color 0.3s ease !important;
}

#bioHtmlPlaceholder a:hover {
    color: #0056b3 !important;
}

/* Images */
#bioHtmlPlaceholder img {
   display: block;              /* Makes the image a block-level element */
    max-width: 100%;             /* Ensures the image scales responsively */
    height: auto;                /* Maintains aspect ratio */
    border-radius: 10px;         /* Rounds the corners */
    margin-bottom: 1.5rem;       /* Adds space below the image */
}

/* Videos and Iframes */
#bioHtmlPlaceholder iframe,#bioHtmlPlaceholder  video {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    border: none;
}

/* Lists */
#bioHtmlPlaceholder ul,#bioHtmlPlaceholder ol {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
}

#bioHtmlPlaceholder li {
    margin-bottom: 0.5rem;
}

/* Blockquotes */
#bioHtmlPlaceholder blockquote {
    border-left: 4px solid #007BFF;
    padding:0.4rem;
    font-style: italic;
    color: #555;
}

/* Buttons */
#bioHtmlPlaceholder button,#bioHtmlPlaceholder  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#bioHtmlPlaceholder button:hover,#bioHtmlPlaceholder  .btn:hover {
    background-color: #0056b3;
}

/* Tables */
#bioHtmlPlaceholder table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
}

#bioHtmlPlaceholder th,#bioHtmlPlaceholder  td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}
postauthor-component .article-box:hover {
  cursor: pointer;
}

postauthor-component .ratio {
  position: relative;
  overflow: hidden;
}

postauthor-component .zoom {
  transition: transform 0.3s ease-in-out;
}

postauthor-component .zoom {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}
postauthor-component .unzoom {
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
postauthor-component .img-info {
  position: relative;
  margin: 0;
  border-radius: 0;
  padding: 0;
}

postauthor-component .img-info p {
  margin: .5rem;
}

postauthor-component .text-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.social-icons a {
  color: white;
  margin-left: 10px;
}
.carousel-control-prev, .carousel-control-next {
  width: 5%;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: unset !important;
}

      /* Bio Analysis Accordion Styles */
  postauthor-component .accordion-item {
    border: none;
    background-color: #1e1e1e;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1; /* Above background */
    padding: 0;
  }

  postauthor-component .accordion-button {
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 15px;
    transition: background-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  postauthor-component .accordion-button:hover,
  postauthor-component  .accordion-button:focus {
    background-color: #2c2c2c;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1);
    outline: none;
  }

  postauthor-component  .accordion-button::after {
    display: none; /* Remove default caret */
  }

  postauthor-component  .accordion-button::before {
    content: '';
    display: inline-block;
    margin-right: 15px;
    border: solid #00ffee; /* Neon color for caret */
    border-width: 0 3px 3px 0;
    padding: 7px;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }

  postauthor-component  .accordion-button.collapsed::before {
    transform: rotate(-135deg);
  }

  postauthor-component  .accordion-body {
    padding: 25px;
    color: #e0e0e0;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0 0 15px 15px;
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Bio Title */
  postauthor-component .bio-title {
    font-size: 1.3rem;
    font-weight: 700;
    display: block;
  }

  /* AI Clickbait */
  postauthor-component .bio-clickbait {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 10px;
    transition: opacity 0.3s ease-in-out;
  }

   /* Predictions Table Styles */
postauthor-component        .predictions-table {
            width: 100%;
            border-collapse: collapse;
            background-color: #161b22;
            border: 1px solid #30363d;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0 4px 12px rgba(0,0,0,0.5);
        }
postauthor-component        .predictions-table th, .predictions-table td {
            padding: 15px;
            text-align: center;
            border-bottom: 1px solid #30363d;
            vertical-align: middle;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
postauthor-component        .predictions-table th {
            background-color: #21262d;
            color: #58a6ff;
            font-size: 1rem;
            font-weight: 700;
            position: sticky;
            top: 0;
            z-index: 2;
        }
postauthor-component        .predictions-table tr:last-child td {
            border-bottom: none;
        }
postauthor-component        .hover-pred-table tbody tr:hover {
            background-color: #30363d;
            cursor: pointer;
        }
        /* Time Cell Styles */
postauthor-component        .time-cell {
            font-weight: 600;
            color: #58a6ff;
        }
        /* Match Cell Styles */
postauthor-component        .match-cell {

        }
postauthor-component        .team-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
postauthor-component        .team-logo {
            width: 35px;
            height: 35px;
            object-fit: contain;
            border-radius: 50%;
            border: 2px solid #21262d;
            background-color: #2f363d;
        }
postauthor-component        .team-name {

            font-weight: 600;
            color: #c9d1d9;
            margin-left: 10px;
            max-width: 150px;
        }
        /* Odds Cell Styles */
postauthor-component        .odds-value {

            font-weight: 700;
            color: #58a6ff;
            margin-bottom: 5px;
        }
postauthor-component        .bookmaker-logo {
            width: 80px;
            height: auto;
            object-fit: contain;
            margin: 0 auto;
        }
        /* Tournament Name Styles */
postauthor-component        .tournament-name {
            text-align: left;

            font-weight: 600;
            color: #adbac7;
            max-width: 200px;
        }
        /* Prediction Status Styles */
postauthor-component        .prediction-won {
            background-color: #28a745 !important; /* Green */
            color: #ffffff;
        }
postauthor-component        .prediction-lost {
            background-color: #dc3545 !important; /* Red */
            color: #ffffff;
        }



  
@media (min-width: 1351px) {
postauthor-component .predictions-table th, .predictions-table td {
        padding: 0.7rem;
        font-size: 0.8rem;
    }
    postauthor-component .team-logo {
        width: 1.3rem;
        height: 1.3rem;
    }
    postauthor-component .bookmaker-logo {
        width: 3rem;
    }
    postauthor-component .team-name {
        max-width: 6rem;
        margin-left: 7px;
    }
    postauthor-component .tournament-name {

    }
        postauthor-component .author-logo {
    width: 1.6rem;
    height: 1.6rem;
    }
    postauthor-component .checkPredictStateClass {
    font-size: 2rem;
    }

postauthor-component .author-photo-container{
background-color: transparent !important;
}
postauthor-component .img-circle-container-author-photo{

width:100%;
height: auto;
}
postauthor-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}

  postauthor-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  postauthor-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  postauthor-component .card-body {
    height: 70px !important;
  }
  postauthor-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  postauthor-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  postauthor-component .text-resize-title-article {
    font-size: 1rem;
    font-weight: 600;
  }
  postauthor-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  postauthor-component .carousel-item .article-box {
    height: 250px;
  }
}


@media only screen and (max-width: 1350px) {
        #bioHtmlPlaceholder h1 {
        font-size: 1.8rem;
    }
    #bioHtmlPlaceholder h2 {
        font-size: 1.55rem;
    }
    #bioHtmlPlaceholder p {
        font-size: 0.92rem;
    }
postauthor-component        .tournament-name{
            max-width: 140px;
            }
postauthor-component .predictions-table th, .predictions-table td {
        padding: 0.5rem;
        font-size: 0.55rem;
    }
    postauthor-component .team-logo {
        width: 1.1rem;
        height: 1.1rem;
    }
    postauthor-component .bookmaker-logo {
        width: 2rem;
    }
    postauthor-component .team-name {
        max-width: 4.5rem;
        margin-left: 7px;
    }
    postauthor-component .tournament-name {

    }
        postauthor-component .author-logo {
    width: 1.6rem;
    height: 1.6rem;
    }
    postauthor-component .checkPredictStateClass {
    font-size: 1rem;
    }
postauthor-component .author-photo-container{
background-color: transparent !important;
}
postauthor-component .img-circle-container-author-photo{

width:100%;
height: auto;
}
postauthor-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}

postauthor-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  postauthor-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  postauthor-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  postauthor-component .text-resize-title-article {
    font-size: 0.7rem;
    font-weight: 600;
    color: #ffffff;
  }
  postauthor-component .carousel-item .article-box {
    height: 200px;
  }
}

        @media only screen and (max-width: 992px) {
        #bioHtmlPlaceholder h1 {
        font-size: 1.7rem;
    }
    #bioHtmlPlaceholder h2 {
        font-size: 1.48rem;
    }
    #bioHtmlPlaceholder p {
        font-size: 0.88rem;
    }
postauthor-component        .tournament-name{
            max-width: 100px;
            }
postauthor-component .predictions-table th, .predictions-table td {
        padding: 0.5rem;
        font-size: 0.55rem;
    }
    postauthor-component .team-logo {
        width: 1.1rem;
        height: 1.1rem;
    }
    postauthor-component .bookmaker-logo {
        width: 2rem;
    }
    postauthor-component .team-name {
        max-width: 4.5rem;
        margin-left: 7px;
    }
    postauthor-component .tournament-name {

    }
        postauthor-component .author-logo {
    width: 1.6rem;
    height: 1.6rem;
    }
    postauthor-component .checkPredictStateClass {
    font-size: 1rem;
    }
postauthor-component .author-photo-container{
background-color: #161b22 !important;
}
postauthor-component .img-circle-container-author-photo{

width: 50%;
height: auto;
}
postauthor-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
postauthor-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  postauthor-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  postauthor-component .card-body {
    height: 70px !important;
  }
  postauthor-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  postauthor-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  postauthor-component .text-resize-title-article {
    font-size: 1rem;
    font-weight: 600;
  }
  postauthor-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  postauthor-component .carousel-item .article-box {
    height: 250px;
  }
}

@media only screen and (max-width: 768px) {
#bioHtmlPlaceholder h1 {
        font-size: 1.6rem;
    }
    #bioHtmlPlaceholder h2 {
        font-size: 1.38rem;
    }
    #bioHtmlPlaceholder p {
        font-size: 0.81rem;
    }
postauthor-component .predictions-table th, .predictions-table td {
        padding: 0.4rem;
        font-size: 0.5rem;
    }
    postauthor-component .tournament-name {
        display: none;
    }
    postauthor-component .th-auth-tournament {
    display: none;
}
        postauthor-component .team-name {
        max-width: 3.5rem;
        margin-left: 3px;
    }
postauthor-component .author-photo-container{
background-color: #161b22 !important;
}
postauthor-component .img-circle-container-author-photo{

width: 20%;
height: auto;
}
postauthor-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
 postauthor-component .img-circle-container-game-logo {
    width: 1rem;
    height: 1rem;
  }
  postauthor-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  postauthor-component .card-body {
    height: 70px !important;
  }
  postauthor-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
  }
  postauthor-component .text-resize-authors-name {
    font-size: 1rem;
    font-weight: 800;
    color: #ffffff;
  }
  postauthor-component .text-resize-title-article {
    font-size: 0.7rem;
    font-weight: 500;
  }
  postauthor-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
  }
  postauthor-component .carousel-item .article-box {
    height: 250px;
  }
}

@media only screen and (max-width: 600px) {

    .tooltip-authorpage-stats {
      font-size: 0.5rem;
      padding: 5px 10px;
      width: 13rem; /* Set a fixed width */
    }

    #bioHtmlPlaceholder h1 {
        font-size: 1.5rem;
    }
    #bioHtmlPlaceholder h2 {
        font-size: 1.3rem;
    }
    #bioHtmlPlaceholder p {
        font-size: 0.75rem;
    }
        postauthor-component .team-name {
        max-width: 3.5rem;
        margin-left: 3px;
    }
    postauthor-component .tournament-name {
        display: none;
    }
    postauthor-component .th-auth-tournament {
    display: none;
}
postauthor-component .author-photo-container{
background-color: #161b22 !important;
}
postauthor-component .img-circle-container-author-photo{

width: 20%;
height: auto;
}
postauthor-component .img-circle-container-author-photo img{
max-width: 100%;
max-height: 100%;
}
postauthor-component .img-circle-container-game-logo {
    width: 0.7rem;
    height: 0.7rem;
  }
  postauthor-component .img-circle-container-game-logo img {
    max-width: 1rem;
    max-height: 1rem;
    top: 10px;
    bottom: 0;
    left: 10px;
    right: 0;
  }
  postauthor-component .card-body {
    height: 50px !important;
  }
  postauthor-component .carousel-item .article-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    color: white;

  }
  postauthor-component .text-resize-authors-name {
    font-size: 0.8rem;
    font-weight: 800;
    color: #ffffff;
  }
  postauthor-component .text-resize-title-article {
    font-size: 0.6rem;
    font-weight: 500;

  }
  postauthor-component .text-resize-page-title {
    font-weight: 600;
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
}




</style>
`

export class PostAuthorComponent extends BaseComponent {

  initCallback() {
    GridOrchestrator.deactivateGameFilters()
    document.head.insertAdjacentHTML("beforeend", css)
    RouterAssistant.checkForSingleContainer('postauthor-component', PostAuthorComponent.initiator)
  }

  static initiator() {

    AuthorsComponent.getAuthors('fromAuthorsComponent', () => {
      if (document.querySelectorAll('#author-post').length !== 0) {
        console.debug('I am running the authors component call back!');
        if (window.savedNews === undefined) {
          NewsCarousel.getNews(() => {
            console.debug('Got the news cause we didnt have them beforehand');
            PostAuthorComponent.updateAuthor(RouterAssistant.getCurrentVariableValue('authid'));

          });
        } else {
          PostAuthorComponent.updateAuthor(RouterAssistant.getCurrentVariableValue('authid'));
        }
      } else {
        console.debug('The authors component call back is supposed to run but I am not on the correct page!!!')
      }
    });
  }

  static renderAllPredictions(predictions) {
    // Function to Render Predictions Table for the author
    const tbody = document.getElementById('predictions-tbody2');
    if (!tbody) {
      console.error("Element with id 'predictions-tbody2' not found.");
      return;
    }
    const showMoreButton = document.getElementById('show-more-authorPredictions-button');
    if (!showMoreButton) {
      console.error("Element with id 'show-more-button' not found.");
      return;
    }

    tbody.innerHTML = '';
    let itemsDisplayed = 0;
    const itemsPerPage = 5;

    predictions.sort((a, b) => {
      const dateA = a.matchDate.toDate();
      const dateB = b.matchDate.toDate();
      return dateB - dateA;
    });

    const renderItems = () => {
      // Calculate the range of items to display
      const itemsToRender = predictions.slice(itemsDisplayed, itemsDisplayed + itemsPerPage);

      itemsToRender.forEach((prediction) => {
        // Create Table Row
        const tr = document.createElement('tr');
        tr.id = "predictionMatch-" + prediction.id;

        // Determine if the match is in the past
        const now = new Date();
        const matchDate = prediction.matchDate.toDate();
        const isPast = matchDate < now;

        // Add Click Event to Redirect to Match Details
        //to be continued due to a tag
        tr.href = `${window.location.origin}/s_match?match=${prediction.id}&name=${prediction.matchName}`; // Set the href attribute as needed
        tr.addEventListener('click', MiscAssistant.MyEsportsClick(function (evt) {
          RouterAssistant.redirect('s_match', {
            match: prediction.id,
            name: prediction.matchName
          })
        }))


        // Time Cell
        const timeTd = document.createElement('td');
        timeTd.classList.add('time-cell');
        const date = `${matchDate.toLocaleDateString(MatchesComponent.userLanguage, {
          weekday: 'short', // "short" for abbreviated weekday name (e.g., Mon)
          day: 'numeric',   // numeric day of the month
          month: 'short'    // "short" for abbreviated month name (e.g., Feb)
        })} ${matchDate.toLocaleTimeString(MatchesComponent.userLanguage, {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })}`;
        timeTd.textContent = date;
        tr.appendChild(timeTd);

        // Match Cell
        const matchTd = document.createElement('td');
        matchTd.classList.add('match-cell');

        // Team 1 Container
        const team1Container = document.createElement('div');
        team1Container.classList.add('team-container');
        const team1Img = document.createElement('img');
        team1Img.src = prediction.teams[0].logo === 'noImageFound' ? noTeamImg : prediction.teams[0].logo === 'noImageFoundPlayer' ? noAuthorImg : prediction.teams[0].logo;
        team1Img.alt = prediction.teams[0].name + " Logo";
        team1Img.classList.add('team-logo');
        team1Img.title = prediction.teams[0].name; // Tooltip for team name
        const team1Name = document.createElement('span');
        team1Name.classList.add('team-name');
        team1Name.textContent = prediction.teams[0].name;
        team1Container.appendChild(team1Img);
        team1Container.appendChild(team1Name);

        // Team 2 Container
        const team2Container = document.createElement('div');
        team2Container.classList.add('team-container');
        const team2Img = document.createElement('img');
        team2Img.src = prediction.teams[1].logo === 'noImageFound' ? noTeamImg : prediction.teams[1].logo === 'noImageFoundPlayer' ? noAuthorImg : prediction.teams[1].logo;
        team2Img.alt = prediction.teams[1].name + " Logo";
        team2Img.classList.add('team-logo');
        team2Img.title = prediction.teams[1].name; // Tooltip for team name
        const team2Name = document.createElement('span');
        team2Name.classList.add('team-name');
        team2Name.textContent = prediction.teams[1].name;
        team2Container.appendChild(team2Img);
        team2Container.appendChild(team2Name);

        // Assemble Match Cell
        matchTd.appendChild(team1Container);
        matchTd.appendChild(team2Container);
        tr.appendChild(matchTd);

        // Odds Cells (1, X, 2)
        ["1", "X", "2"].forEach(key => {
          const oddsTd = document.createElement('td');

          // Odds Value
          const oddsValue = document.createElement('div');
          oddsValue.classList.add('odds-value');
          oddsValue.textContent = prediction.odds[key] ? prediction.odds[key] : "-";

          // Thunderpick Logo
          // const bookmakerImg = document.createElement('img');
          // bookmakerImg.src = thunderpickLogo;
          // bookmakerImg.alt = "Thunderpick Logo";
          // bookmakerImg.classList.add('bookmaker-logo');

          // Assemble Odds Cell
          oddsTd.appendChild(oddsValue);
          // oddsTd.appendChild(bookmakerImg);

          tr.appendChild(oddsTd);
        });

        // Tournament Name Cell
        const tournamentTd = document.createElement('td');
        tournamentTd.classList.add('tournament-name');
        tournamentTd.textContent = prediction.tournamentName;
        tr.appendChild(tournamentTd);



        // If the match is in the past and outcome is available, apply prediction status
        if (prediction.state && prediction.state !== "Upcoming") {
          if (isPast && prediction.state === "W") {
            const outcomeTd = document.createElement('td');
            const outcomeSpan = document.createElement('span');
            const bufferSpan = document.createElement('span');
            const outcomeIcon = document.createElement('i');
            bufferSpan.textContent = " ";
            outcomeSpan.classList.add('text-success');
            outcomeSpan.classList.add('checkPredictStateClass');
            outcomeIcon.classList.add('bi', 'bi-check-circle-fill');
            outcomeSpan.appendChild(bufferSpan);
            outcomeSpan.appendChild(outcomeIcon);
            outcomeTd.appendChild(outcomeSpan);
            tr.appendChild(outcomeTd);
          } else if (prediction.state === "Canceled") {
            const outcomeTd = document.createElement('td');
            const outcomeSpan = document.createElement('span');
            const bufferSpan = document.createElement('span');
            const outcomeIcon = document.createElement('i');
            bufferSpan.textContent = " ";
            outcomeSpan.classList.add('checkPredictStateClass');
            outcomeIcon.classList.add('bi', 'bi-dash-circle-fill');
            outcomeSpan.appendChild(bufferSpan);
            outcomeSpan.appendChild(outcomeIcon);
            outcomeTd.appendChild(outcomeSpan);
            tr.appendChild(outcomeTd);

          } else if (isPast && prediction.state === "L") {
            const outcomeTd = document.createElement('td');
            const outcomeSpan = document.createElement('span');
            const bufferSpan = document.createElement('span');
            const outcomeIcon = document.createElement('i');
            bufferSpan.textContent = " ";
            outcomeSpan.classList.add('text-danger');
            outcomeSpan.classList.add('checkPredictStateClass');
            outcomeIcon.classList.add('bi', 'bi-x-circle-fill');
            outcomeSpan.appendChild(bufferSpan);
            outcomeSpan.appendChild(outcomeIcon);
            outcomeTd.appendChild(outcomeSpan);
            tr.appendChild(outcomeTd);
          }
        } else { // If the match is in the future or outcome is not available, leave the cell empty
          const outcomeTd = document.createElement('td');
          outcomeTd.textContent = " ";
          tr.appendChild(outcomeTd);
        }
        // Append Row to Table Body
        tbody.appendChild(tr);
      });

      itemsDisplayed += itemsToRender.length;

      // Hide "Show More" button if all items are displayed
      if (itemsDisplayed >= predictions.length) {
        showMoreButton.style.display = 'none';
      }
    };

    // Initially render the first set of items
    renderItems();

    // Attach event listener to the "Show More" button
    showMoreButton.addEventListener('click', renderItems);


    // Calculate and display the author's statistics
    let betsCounter = 0;
    let winsCounter = 0;
    let openBetsCounter = 0;
    let closedBetsCounter = 0;
    let oddsAverage = 0;
    let totalWinnings = 0;
    predictions.forEach(prediction => {
      betsCounter++;
      oddsAverage += parseFloat(prediction.oddValue);
      if (prediction.state === "W" && prediction.state !== "Upcoming" && prediction.state !== "Canceled") {
        winsCounter++;
        totalWinnings += parseFloat(prediction.oddValue) - 1;
        closedBetsCounter++;
      } else if (prediction.state === "L" && prediction.state !== "Upcoming" && prediction.state !== "Canceled") {
        totalWinnings -= 1;
        closedBetsCounter++;
      } else if (prediction.state === "Upcoming") {
        openBetsCounter++;
      } else if (prediction.state === "Canceled") {
        betsCounter--;
      }
    });

    if (betsCounter === 0) {
      return;
    }
    const tbody2 = document.getElementById('predictions-stats-tbody2');
    if (!tbody2) {
      console.error("Element with id 'predictions-tbody2' not found.");
      return;
    }
    tbody2.innerHTML = '';

    // Create Table Row
    const tr = document.createElement('tr');
    tr.id = "predictionStats";

    // Bets Cell
    const betsTd = document.createElement('td');
    betsTd.textContent = betsCounter;
    tr.appendChild(betsTd);

    // Winnings cell
    const winningsTd = document.createElement('td');
    winningsTd.textContent = totalWinnings.toFixed(2);
    tr.appendChild(winningsTd);

    // Win percentage cell
    const winPercentageTd = document.createElement('td');
    winPercentageTd.textContent = closedBetsCounter !== 0 ? (winsCounter / (betsCounter - openBetsCounter) * 100).toFixed(2) + "%" : "0.00%";
    tr.appendChild(winPercentageTd);

    // ROI cell
    const roiTd = document.createElement('td');
    roiTd.textContent = closedBetsCounter !== 0 ? (totalWinnings / (betsCounter - openBetsCounter) * 100).toFixed(2) + "%" : "0.00%";
    tr.appendChild(roiTd);

    // Average Odds cell
    const averageOddsTd = document.createElement('td');
    averageOddsTd.textContent = (oddsAverage / betsCounter).toFixed(2);
    tr.appendChild(averageOddsTd);

    // Open Bets cell
    const openBetsTd = document.createElement('td');
    openBetsTd.textContent = openBetsCounter;
    tr.appendChild(openBetsTd);

    // Append Row to Table Body
    tbody2.appendChild(tr);


  }

  static extractFirstTextBlock(markup) {
    try {
      // Split the string into lines
      const lines = markup.split('\n');

      // Initialize an array to hold the text block lines
      let textBlock = [];
      let blockStarted = false;

      // Iterate over each line
      for (let line of lines) {
        // Check if the line does not start with special characters and is not empty
        if (!line.startsWith('<h') && !line.startsWith('##') && !line.startsWith('#') && !line.startsWith('**') && !line.startsWith('* **') && !line.startsWith('*') && !line.startsWith('-') && line.trim() !== '') {
          let refactoredLine = line.replace(/<[^>]*>?/gm, '');
          textBlock.push(refactoredLine.trim());
          blockStarted = true;
        } else if (blockStarted) {
          // If the block has started and we encounter a line with special characters or an empty line, break the loop
          break;
        }
      }
      const textToBeReturned = textBlock.join(' ').replace('<p>', '').replace('</p>', '');
      return (textToBeReturned.length === 0) ? `<span class="translate-bio-coming">Bio is coming soon<span>` : textToBeReturned;
    } catch (e) {
      console.log('ERROR')
      return ''
    }

  }

  static dynamicTranslations() {
    document.querySelector('.th-date').innerHTML = I18n.translateString("predictionsTranslations.date");
    document.querySelector('.th-auth-match').innerHTML = I18n.translateString("predictionsTranslations.match");
    document.querySelector('.th-auth-tournament').innerHTML = I18n.translateString("predictionsTranslations.tournament");
    document.querySelector('.translate-auth-stats-avg-odds').innerHTML = I18n.translateString("predictionsTranslations.statsAvgOddsAuthorPage");
    document.querySelector('.translate-auth-stats-earn').innerHTML = I18n.translateString("predictionsTranslations.statsEarnAuthorPage");
    document.querySelector('.translate-auth-stats-bets').innerHTML = I18n.translateString("predictionsTranslations.statsBets");
    document.querySelector('.translate-auth-stats-open-bets').innerHTML = I18n.translateString("predictionsTranslations.statsOpenBets");
    document.querySelector('.translate-authpost-statistics').innerHTML = I18n.translateString("predictionsTranslations.authStats");
    document.querySelector('.translate-authpost-last10').innerHTML = I18n.translateString("predictionsTranslations.last10");
    document.querySelector('.translate-authpost-articles').innerHTML = I18n.translateString("predictionsTranslations.articles");
    document.querySelector('.translate-biography').innerHTML = I18n.translateString("predictionsTranslations.authBio");
    if (document.querySelector('.translate-bio-coming')) { document.querySelector('.translate-bio-coming').innerHTML = I18n.translateString("predictionsTranslations.bioComing"); }
    if (document.querySelector('.show-more-predictions')) { document.querySelector('.show-more-predictions').innerHTML = I18n.translateString("predictionsTranslations.showMorePredictions"); }
  }

  static updateAuthor(authorIdentifier) {
    console.debug(authorIdentifier);
    const authorsContainer = document.getElementById('author-post');
    authorsContainer.innerHTML = '';
    let html = '';

    // Convert authorIdentifier to lowercase
    const lowerCaseAuthorIdentifier = authorIdentifier.toLowerCase();

    // Find the author in a case-insensitive manner
    const authorKey = Object.keys(window.savedAuthors).find(key => key.toLowerCase() === lowerCaseAuthorIdentifier);
    const author = window.savedAuthors[authorKey];

    const authorName = author.name;
    const converter = new showdown.Converter({
      extensions: [
        () => ({
          type: 'output',
          filter: (html, converter) => {
            return html.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ');
          }
        })
      ]
    });
    const convertedMarkdownContent = author.authorBio && author.authorBio[I18n.getUsersPreferredLanguage()] !== '' && author.authorBio[I18n.getUsersPreferredLanguage()] !== 'N/A' ? author.authorBio[I18n.getUsersPreferredLanguage()] : '';
    const authorBio = converter.makeHtml(convertedMarkdownContent);
    const clickBaitString = PostAuthorComponent.extractFirstTextBlock(authorBio);
    const authorFullName = author.fullName;
    const authorSocials = author.socials;
    const authorPhoto = !author.photo || (author.photo === "" || author.photo === "N/A") ? noAuthorImg : author.photo;
    author.newsPosts = [];

    const authorPredictions = author.predictions;


    for (const [NewsPostId, NewsPostData] of Object.entries(window.savedNews)) {
      if (NewsPostData.public_draft !== 'Public' || NewsPostData.is_page) continue;
      if (authorName.toLowerCase() === NewsPostData.linkedAuthor.toLowerCase() && NewsPostData.public_draft === 'Public') {
        NewsPostData.tempID = NewsPostData.public_url_greeklish;
        author.newsPosts.push(NewsPostData);
      }
    }

    let newshtml = '';
    let newshtmlMobile = '';
    author.newsPosts.forEach((NewsPostData, index) => {
      if (index % 3 === 0) {
        if (index !== 0) {
          newshtml += /*html*/`</div></div>`; // Close previous carousel item
        }
        newshtml += /*html*/`<div class="carousel-item ${index === 0 ? 'active' : ''}"><div class="row">`; // Start new carousel item
      }
      newshtml += /*html*/`
                <div class="col-lg-4 col-md-4 col-6">
                  <div class="article-box" data-authornewsid="${NewsPostData.tempID}" data-newsgameid="${NewsPostData.game_tag}">
                      <div class="">
      
                          <div class="ratio ratio-16x9 position-relative">
                              <img class="card-img-top unzoom img-fluid rounded-top" src="${NewsPostData.article_photo}" alt="Card image cap">
                              <div class="img-circle-container-game-logo d-flex align-items-center">
                                  <img class="img-game-logo position-absolute img-fluid mx-auto d-block" src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" alt="gamelogo">
                              </div>  
                          </div>
                          <div class="card-body color-dark rounded-bottom caption-mobile p-1 p-sm-1 p-md-2 p-lg-2">
                              <p class="card-text text-start text-cut text-resize-title-article">${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}</p>
                          </div>
                      </div>
                  </div>
                </div>
        `;
    });

    author.newsPosts.forEach((NewsPostData, index) => {
      if (index % 2 === 0) {
        if (index !== 0) {
          newshtmlMobile += /*html*/`</div></div>`; // Close previous carousel item
        }
        newshtmlMobile += /*html*/`<div class="carousel-item ${index === 0 ? 'active' : ''}"><div class="row">`; // Start new carousel item
      }
      newshtmlMobile += /*html*/`
              <div class="col-lg-4 col-md-4 col-6 ">
                  <div class="article-box" data-authornewsid="${NewsPostData.tempID}" data-newsgameid="${NewsPostData.game_tag}">
                      <div class="">
      
                          <div class="ratio ratio-16x9 position-relative">
                              <img class="card-img-top unzoom img-fluid rounded-top" src="${NewsPostData.article_photo}" alt="Card image cap">
                              <div class="img-circle-container-game-logo d-flex align-items-center">
                                  <img class="img-game-logo position-absolute img-fluid mx-auto d-block" src="${NewsCarousel.gameImageMapping[NewsPostData.game_tag]}" alt="gamelogo">
                              </div>  
                          </div>
                          <div class="card-body color-dark rounded-bottom caption-mobile p-1 p-sm-1 p-md-2 p-lg-2">
                              <p class="card-text text-start text-cut text-resize-title-article">${NewsPostData.titles[I18n.getUsersPreferredLanguage()]}</p>
                          </div>
                      </div>
                  </div>
              </div>
          `;
    });

    newshtml += /*html*/`</div></div></div>`;
    newshtmlMobile += /*html*/`</div></div></div>`;

    // Add carousel controls
    newshtml += /*html*/`
            <button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y" type="button" data-bs-target="#articleCarousel-${authorIdentifier}" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next position-absolute top-50 end-0 translate-middle-y" type="button" data-bs-target="#articleCarousel-${authorIdentifier}" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        `;

    // Add carousel controls
    newshtmlMobile += /*html*/`
            <button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y" type="button" data-bs-target="#articleCarouselM-${authorIdentifier}" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next position-absolute top-50 end-0 translate-middle-y" type="button" data-bs-target="#articleCarouselM-${authorIdentifier}" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        `;
    html += /*html*/ `
             

                <div class="author-card row w-100 mt-5 color-lightdark rounded">
                    <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                        <h5 class="mb-0 text-resize-authors-name">${authorFullName}(${authorName})</h5>
                        <div class="social-icons ms-2">
                            ${!authorSocials.instagram || authorSocials.instagram === "N/A" ? '' : `<a href="${authorSocials.instagram}" target="_blank" rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>`}
                            ${!authorSocials.discord || authorSocials.discord === "N/A" ? '' : `<a href="${authorSocials.discord}" target="_blank" rel="noopener noreferrer"><i class="bi bi-discord"></i></a>`}
                            ${!authorSocials.twitter || authorSocials.twitter === "N/A" ? '' : `<a href="${authorSocials.twitter}" target="_blank" rel="noopener noreferrer"><i class="bi bi-twitter"></i></a>`}
                            ${!authorSocials.facebook || authorSocials.facebook === "N/A" ? '' : `<a href="${authorSocials.facebook}" target="_blank" rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>`}
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 col-12 d-flex flex-column align-items-center justify-content-start author-photo-container p-0">
                      <div class="img-circle-container-author-photo d-flex align-items-center">
                        <img src="${authorPhoto}" alt="Author Photo" class="img-fluid mx-auto d-block">
                        </div>
                    </div>
                        <div class="col-lg-10 col-md-12 col-12 mt-3">
                        <!-- Biography Accordion -->
                      <div class="accordion accordion-flush my-3 row" id="accordionFlush">
                        <div class="accordion-item">
                          <div class="accordion-header" id="flush-heading">
                            <button class="accordion-button collapsed rounded-top color-lightdark clickable" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse" aria-expanded="false" aria-controls="flush-collapse">

                              <div class="col-8 col-md-9 col-lg-10 d-inline-flex flex-column justify-content-center align-content-start ms-3">
                                <span class="text-name-ai-resize text-white bio-title translate-biography">Biography</span>
                                <span class="text-ai-resize mt-3 text-truncate-3 text-white bio-clickbait"><em>"${clickBaitString}"</em></span>
                              </div>

                              
                            </button>
                          </div>
                          <div id="flush-collapse" class="accordion-collapse collapse" aria-labelledby="flush-heading" data-bs-parent="#accordionFlush">
                            <div class="accordion-body color-dark rounded-bottom">
                              <div class="mt-3" id="bioHtmlPlaceholder">
                        ${authorBio}
                        </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        </div>
                </div>

                



                    <div class="author-card row w-100 mt-5 color-lightdark rounded ${author.newsPosts.length === 0 ? 'd-none' : ''}">
                    <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                    <h2 class="mb-0 ms-3 text-resize-authors-name translate-authpost-articles">Articles</h2>
                    </div>
                    <div class="col-12 pb-1 p-1 d-none d-md-block rounded-bottom ${author.newsPosts.length === 0 ? 'd-none' : ''}">
                      <div class="position-relative">
                          <div id="articleCarousel-${authorIdentifier}" class="carousel slide mt-3" data-bs-ride="carousel">
                              <div class="carousel-inner">
                                  ${newshtml}
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 pb-1 p-1 d-block color-lightdark w-100 d-block d-md-none rounded-bottom ${author.newsPosts.length === 0 ? 'd-none' : ''}">
                      <div class="position-relative">
                          <div id="articleCarouselM-${authorIdentifier}" class="carousel slide mt-3" data-bs-ride="carousel">
                              <div class="carousel-inner">
                                  ${newshtmlMobile}
                              </div>
                          </div>
                      </div>
                  </div>

                  <!-- Predictions -->
                    <div class="author-card row w-100 mt-5 color-lightdark rounded">
                    <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                    <h2 class="mb-0 ms-3 text-resize-authors-name translate-authpost-statistics">Predictions stats</h2>
                    </div>
                    <div class="col-12 pb-1 p-1 rounded-bottom"> 
                      <!-- Predictions Stats -->
                      <table class="predictions-table">
    <tr>
    <thead>
      <th class="translate-auth-stats-bets">Bets</th>
      <th class="translate-auth-stats-earn author-tooltip-engage" data-tooltip="EarnExplain">Κέρδος</th>
      <th class="author-tooltip-engage" data-tooltip="WinrateExplain">(%) <i class="bi bi-info-circle"></i></th>
      <th class="author-tooltip-engage" data-tooltip="RoiExplain">ROI <i class="bi bi-info-circle"></i></th>
      <th class="translate-auth-stats-avg-odds author-tooltip-engage" data-tooltip="AvgOddsExplain">Μ.Ο. απ.</th>
      <th class="translate-auth-stats-open-bets">Open bets</th>
      </thead>
    </tr>
    <tbody id="predictions-stats-tbody2">
    <!-- Predictions stats will be injected here by JavaScript -->
    </tbody>
  </table>
                    </div>
                    <div class="col-12 d-inline-flex justify-content-center align-items-center w-100 p-0 my-3">
                    <h2 class="mb-0 ms-3 text-resize-authors-name translate-authpost-last10">Last 10 predictions</h2>
                    </div>
                    <div class="col-12 pb-1 p-1 rounded-bottom">    
                    <!-- Predictions Table -->        
                      <table class="predictions-table hover-pred-table">
                          <thead>
                              <tr>
                                  <th class="th-date">Time</th>
                                  <th class="th-auth-match">Match</th>
                                  <th>1</th>
                                  <th>X</th>
                                  <th>2</th>
                                  <th class='th-auth-tournament'>Tournament</th>
                                  <th class="th-outcome"></th>
                              </tr>
                          </thead>
                          <tbody id="predictions-tbody2">
                              <!-- Predictions rows will be injected here by JavaScript -->
                          </tbody>
                      </table>
                      
                    </div>
                    <button id="show-more-authorPredictions-button" class="col-xxl-2 col-lg-4 col-6  mx-auto show-more-predictions">Show More</button>
                  </div>
        `;


    document.querySelector('#author-post').innerHTML = html;

    PostAuthorComponent.renderAllPredictions(authorPredictions);
    PostAuthorComponent.dynamicTranslations();
    PostAuthorComponent.tooltips();
    // Add event listeners to all elements with the data-newsid attribute
    document.querySelectorAll('[data-authornewsid]').forEach(insertedElement => {
      const articleImage = insertedElement.querySelector('.card-img-top'); // Target the specific image
      insertedElement.addEventListener('click', function (evt) {
        evt.stopPropagation();
        RouterAssistant.redirect('s_news', {
          article: insertedElement.getAttribute('data-authornewsid')
        });
      });
      insertedElement.addEventListener('mouseenter', (evt) => {
        articleImage.classList.add('zoom');
        articleImage.classList.remove('unzoom');
      }, false);
      insertedElement.addEventListener('mouseleave', (evt) => {
        articleImage.classList.remove('zoom');
        articleImage.classList.add('unzoom');
      }, false);
    });
  }

  static tooltips() {

    let tooltipElements = document.querySelectorAll('.author-tooltip-engage');

    tooltipElements.forEach((tooltipElement) => {
      tooltipElement.addEventListener("mouseenter", (e) => {
        const tooltip = document.createElement("div");
        tooltip.className = "tooltip-authorpage-stats";
        tooltip.innerHTML = I18n.translateString(`predictionsTranslations.${tooltipElement.getAttribute("data-tooltip")}`);
        document.body.appendChild(tooltip);

        const rect = tooltipElement.getBoundingClientRect();

        const clientWidth = document.documentElement.clientWidth;
        if (clientWidth < 768) {
          tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2 + 20}px`;
        } else {
          tooltip.style.left = `${rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2}px`;
        }

        tooltip.style.top = `${rect.top + window.scrollY - tooltip.offsetHeight - 10}px`;
        tooltip.style.opacity = "1";

        tooltipElement.addEventListener("mouseleave", () => {
          tooltip.style.opacity = "0";
          tooltip.addEventListener("transitionend", () => tooltip.remove());
        });
        // Mobile-specific handling
        // tooltipElement.addEventListener("touchstart", () => {
        //     console.debug('change because of touchstart');
        //     tooltip.style.opacity = "0";
        //     tooltip.addEventListener("transitionend", () => tooltip.remove());
        // });
      });

      // Listen for scroll, touchmove, and wheel events
      window.addEventListener("scroll", () => {
        if (document.querySelector('.tooltip-authorpage-stats')) {
          const tooltip = document.querySelector('.tooltip-authorpage-stats');
          tooltip.style.opacity = "0";
          tooltip.addEventListener("transitionend", () => tooltip.remove());
        }
      }, { passive: true });
      window.addEventListener("wheel", () => {
        if (document.querySelector('.tooltip-authorpage-stats')) {
          const tooltip = document.querySelector('.tooltip-authorpage-stats');
          tooltip.style.opacity = "0";
          tooltip.addEventListener("transitionend", () => tooltip.remove());
        }
      }, { passive: true });
      window.addEventListener("touchmove", () => {
        if (document.querySelector('.tooltip-authorpage-stats')) {
          const tooltip = document.querySelector('.tooltip-authorpage-stats');
          tooltip.style.opacity = "0";
          tooltip.addEventListener("transitionend", () => tooltip.remove());
        }
      }, { passive: true });
      window.addEventListener("popstate", () => {
        if (document.querySelector('.tooltip-authorpage-stats')) {
          const tooltip = document.querySelector('.tooltip-authorpage-stats');
          tooltip.style.opacity = "0";
          tooltip.addEventListener("transitionend", () => tooltip.remove());
        }
      });
    });
  }

}



PostAuthorComponent.template = /*html*/`
  <div class="container-fluid d-flex flex-column justify-content-center align-content-center align-items-center" id="author-post">
  </div>

`;

PostAuthorComponent.reg('postauthor-component');