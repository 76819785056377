const globalSocials = {
    discord: "https://discord.gg/3sU4u59qze",
    twitter: "https://x.com/myesportsgr",
    facebook: "https://www.facebook.com/myesportsgg",
    twitch: "https://www.twitch.tv/my_esports",
    instagram: "https://www.instagram.com/myesportsgr/",
    youtube: "https://www.youtube.com/@myesportsgr",
    tiktok: "https://www.tiktok.com/@myesports.gr",
};

const affiliateLinks = {
    thunderpickGeneral: "https://go.thunder.partners/visit/?bta=36737&brand=thunderpick&campaign=MyEsports",
    thunderpickCode: "?cxd=MyEsports&cxcampaign=MyEsports",
    sportazaGeneral: "https://stz.xarvilo.com/?mid=239793_1456910",
};

// Attach the objects to the global window objects
window.globalSocials = globalSocials;
window.affiliateLinks = affiliateLinks;
