import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { BookmakersSkinComponent } from "../bookmakers-skin-component/bookmakers-skin-component";
import { I18n } from "../../utilities/i18n/i18n";

const css = `
    <style>
         @media screen and (orientation:portrait) {
             casinos-container h1{
                 
            }
        }
         @media screen and (orientation:landscape) {
             casinos-container h1{
                 
            }
        }
    </style>
`

export class CasinosContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters()
        document.getElementById('casinosContainerHeader').innerText = I18n.translateString('navMainMenu.Casinos')
    }
}

CasinosContainer.template = /*html*/ `
<h1 id="casinosContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>

      <div class="main-content col-12 g-0 mt-2 ">

     <div class="home-bookmakers home-components row m-0 p-0">
       
        <bookmakershome-component></bookmakershome-component>
        
    </div>
        
</div>
    
<!-- <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-5 ">-->
<!--                    <placeholder-matcheswidget></placeholder-matcheswidget>-->
<!--                    <div class="sidebar-matches home-components row m-2 ">-->
<!--                        <div class="title">-->
<!--                        </div>-->
<!--                        <matches-component data-mode="homePage"></matches-component>-->
<!--                    </div>-->
<!--                    <div class="sidebar-predictions home-components row m-2 ">-->
<!--                        <predictions-homecomponent></predictions-homecomponent>-->
<!--                    </div>-->
<!--                    <div class="sidebar-discord home-components row m-2 " >-->
<!--                        <div class="home-discord-template">-->
<!--                            <discord-template></discord-template>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="sidebar-bonus home-components row m-2 ">-->
<!--                        <div class="home-widget-bookmakers">-->
<!--                            <widget-bookmakers></widget-bookmakers>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
  
`;

CasinosContainer.reg('casinos-container');
