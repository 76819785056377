import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GameNavbar } from "../game-navbar/game-navbar";
import { AdblockComponent } from "../adblockerdetect-component/adblockerdetect-component";

const Logo = require("./Logo-site.png");
const LogoWhite = require("./Logo color White.png");
const css = `
    <style> 
  
    
       main-navbar .activeMobile{
            background-color:  #ff282859;
            color:#ffffff;
            
        }
        .img-container {
  transition: width 0.5s ease-in-out; /* Smooth transition for width */
}

/* Base styles for the logo */
#navDesktopElement {
  transition: transform 0.5s ease-in-out; /* Smooth transition for transform */
}
.img-container{
width: 13rem;
background: #161B22;
transform: skew(-30deg);
margin-left: -1rem;
}
main-navbar .navbar {
 flex-wrap: nowrap; /* Prevent wrapping */
}
main-navbar .navbar-ad {
            width: calc(100% - ((1500px - 100vw) / 10)); /* Reduce 1px width for every pixel below 1300px */
            max-width: 100%;
          height: 70px;
          object-fit:contain;
          padding: 4px;
         }

/* Hover styles for the logo */
.img-container:hover #navDesktopElement {
  transform: skew(30deg) scale(1.1); /* Maintain skew and apply scale */
}
   .menu-design{
    height: 70px;
    padding: 0;
    }
    .menu-design li{
    
    transform: skew(-30deg);
    }
    main-navbar .menu-design span{
    text-transform:uppercase;
    transform: skew(30deg);
    
    }
    .menu-design li:hover{
    background-color:#1f2731;
    color: #c4c4c4;
    }
       main-navbar .active
        {
       background-color:  #161B22!important;
       border-top:2px solid #B22A23 !important;
      
        }
           
        #navDesktopElement,a,.list-group-item:hover {
              cursor: pointer;
        }
        #navDesktopElement{
         height: 2.5rem;
         transform: skew(30deg);
         }
    #navMobileElement,a,.list-group-item:hover {
              cursor: pointer;
        }
        #navMobileElement{
         height: 2.5rem;
         }
        main-navbar .navbar {
            background-color: #1c232c !important;
        }

        main-navbar .navbar, main-navbar .navbar-brand {
         
           
        }
    main-navbar .list-group{
     font-weight: bold;
    }
        main-navbar .navbar, main-navbar .navbar-nav, main-navbar .nav-link {
           
          
        }

        main-navbar .nav-link {
        width: calc(100% / 6) !important; /* EDW ALLAZOUME TO NUMERO ANALOGA ME TIS EPILOGES STO MENU */
        }
        main-navbar .navbar, main-navbar .navbar-nav, main-navbar .nav-link:hover {
            color: #fffffe;
        }

        

        #mobileNav a{
            padding-left: 1rem;
        }
      
        #mobileNav .nav-link{
            padding: 0.4rem !important;
        }
 
     .list-group-horizontal>.list-group-item:first-child:not(:last-child){
     border-radius:0;
     }
        main-navbar .list-group-item {
            /*border-radius: 6px;*/
            background-color: transparent;
               border: transparent;
           
            
        }
        main-navbar   i{    
           font-size: 1.5rem;
           }
        .text-menu-resize{
        font-size: 0.5rem;
        
        }

        @media only screen and (max-width: 1300px){
         
         main-navbar .resize-desktop {
          font-size: 0.85rem;
         }

        
        }
         @media only screen and (max-width: 1100px){

         main-navbar .resize-desktop {
          font-size: 0.75rem;
         }

        }
         @media only screen and (max-width: 1000px){

         main-navbar .resize-desktop {
          font-size: 0.7rem;
         }

        }

        @media only screen and (max-width: 900px){

         main-navbar .resize-desktop {
          font-size: 0.6rem;
         }

        }
         
    </style>
`;

export class MainNavbar extends BaseComponent {
  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    window.addEventListener(
      "resize",
      MainNavbar.performOrientationChangeActions,
    );
    MainNavbar.performOrientationChangeActions();
    MainNavbar.enablePostPageRedirect();
    MainNavbar.translations();
    document.querySelectorAll('main-navbar .menu-design span').forEach((el) => {
      el.innerText = MainNavbar.customUppercase(el.innerText);
    });
    //   setTimeout(() => {
    //     let ahrefopenOption = ''
    //     console.debug("savvas", AdblockComponent.adblockCheck)
    //     if (AdblockComponent.adblockCheck) {
    //       ahrefopenOption = '_Top'
    //     } else {
    //       ahrefopenOption = '_blank'
    //     }


    //     document.querySelector('.list-group').insertAdjacentHTML('afterend', /*html*/ `
    //                                 <div class="m-2"><a href="https://go.thunder.partners/visit/?bta=36737&nci=5631&campaign=MyEsports" Target="${ahrefopenOption}" class="d-flex justify-content-end"><img src="https://firebasestorage.googleapis.com/v0/b/myesports-65c4d.appspot.com/o/affiliateAds%2FthunderPickMenuBigEsports.png?alt=media&token=101416e4-b363-4226-871c-1534cc27a957" class="navbar-ad"></a></div>
    //                 `);
    //   }, 300); // lol
  }

  static translations() {
    document.getElementById("navDesktopElement").innerText =
      I18n.translateString("navMainMenu.navDesktopTitle");
    document.querySelectorAll(".HomeElement, .HomeElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Home");
    });
    document.querySelectorAll(".TournamentsElement, .TournamentsElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Tournaments");
    });
    document.querySelectorAll(".MatchesElement, .MatchesElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Matches");
    });
    document.querySelectorAll(".GiveawaysElement, .GiveawaysElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Giveaways");
    });
    document.querySelectorAll(".CasinosElement, .CasinosElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Casinos");
    });
    document.querySelectorAll(".StreamersElement, .StreamersElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Streamers");
    });
    document.querySelectorAll(".newsElement, .newsElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.News");
    });
    document.querySelectorAll(".PredictionsElement, .PredictionsElementM").forEach((el) => {
      el.innerText = I18n.translateString("navMainMenu.Predictions");
    });
  }

  static performOrientationChangeActions() {
    if (window.innerHeight > window.innerWidth) {
      document.getElementById("mobileNav").style.display = "";
      document.getElementById("desktopNav").style.display = "none";
      document.getElementById("navDesktopElement").style.display = "none";
      document.getElementById("navMobileElement").style.display = "";
      document.getElementById('menusWrapper').classList.remove('fixed-top')
      document.getElementById('menusWrapper').classList.add('fixed-bottom')
    } else {
      document.getElementById("mobileNav").style.display = "none";
      document.getElementById("desktopNav").style.display = "";
      document.getElementById("navDesktopElement").style.display = "";
      document.getElementById("navMobileElement").style.display = "none";
      document.getElementById('menusWrapper').classList.remove('fixed-bottom')
      document.getElementById('menusWrapper').classList.add('fixed-top')
      document.querySelectorAll('main-navbar .menu-design span').forEach((el) => {
        el.innerText = MainNavbar.customUppercase(el.innerText);
      });
    }
  }

  static checkNavBarState() {
    document.querySelectorAll('#desktopNav .list-group-item').forEach(el => {
      el.classList.remove('active')
    })
    document.querySelectorAll('#mobileNav .nav-link').forEach(el => {
      el.classList.remove('activeMobile')
    })

    if (window.location.href.includes('home')) {
      document.querySelector('#desktopNav .homeActive').classList.add('active')
      document.querySelector('#mobileNav .homeMobile').classList.add('activeMobile')
    } else if (window.location.href.includes('matches')) {
      document.querySelector('#desktopNav .matchesActive').classList.add('active')
      document.querySelector('#mobileNav .MatchesMobile').classList.add('activeMobile')
    } else if (window.location.href.includes('giveaways')) {
      document.querySelector('#desktopNav .GiveawaysElement').classList.add('active')
      document.querySelector('#mobileNav .GiveawaysElement').classList.add('activeMobile')
    } else if (window.location.href.includes('tournaments')) {
      document.querySelector('#desktopNav .tournamentsActive').classList.add('active')
      document.querySelector('#mobileNav .TournamentsMobile').classList.add('activeMobile')
    }
    // else if (window.location.href.includes('casinos')) {
    //   document.querySelector('#desktopNav .casinosActive').classList.add('active')
    //   document.querySelector('#mobileNav .bookmakersMobile').classList.add('activeMobile')
    // } 
    else if (window.location.href.includes('streamers')) {
      document.querySelector('#desktopNav .streamersActive').classList.add('active')
      document.querySelector('#mobileNav .StreamersMobile').classList.add('activeMobile')
    }
    else if (window.location.href.includes('predictions')) {
      document.querySelector('#desktopNav .predictionsActive').classList.add('active')
      document.querySelector('#mobileNav .predictionsMobile').classList.add('activeMobile')
    }
    else if (window.location.href.includes('news')) {
      document.querySelector('#desktopNav .newsActive').classList.add('active')
      document.querySelector('#mobileNav .newsMobile').classList.add('activeMobile')
    }
  }

  static enablePostPageRedirect() {
    // adding event listeners to whole mobile elements starts here
    document.querySelectorAll(".homeMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("home", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    document.querySelectorAll(".MatchesMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("matches", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    document.querySelectorAll(".TournamentsMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("tournaments", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    // document.querySelectorAll(".bookmakersMobile").forEach((el) => {
    //   el.addEventListener("click", function (evt) {
    //     RouterAssistant.redirect("casinos", {
    //       // add variables
    //     });


    //   });
    // });
    document.querySelectorAll(".StreamersMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("streamers", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    document.querySelectorAll(".predictionsMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("predictions", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    document.querySelectorAll(".newsMobile").forEach((el) => {
      el.addEventListener("click", function (evt) {
        RouterAssistant.redirect("news", {
          game: GameNavbar.getCurrentlyActivatedGameIfAny()
        });


      });
    });
    //  adding event listeners to whole mobile elements ends here

    document
      .getElementById("navDesktopElement")
      .addEventListener("click", function (evt) {
        RouterAssistant.redirect("home", {
          // add variables
        });

      });

    document.querySelector("#desktopNav .homeActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("home", { game: GameNavbar.getCurrentlyActivatedGameIfAny() });
    });

    document.querySelector("#desktopNav .tournamentsActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("tournaments", { game: GameNavbar.getCurrentlyActivatedGameIfAny() });
    });

    document.querySelector("#desktopNav .matchesActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("matches", { game: GameNavbar.getCurrentlyActivatedGameIfAny() });
    });

    // document.querySelector("#desktopNav .casinosActive").addEventListener("click", function (evt) {
    //   RouterAssistant.redirect("casinos", {});
    // });

    document.querySelector("#desktopNav .streamersActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("streamers", {});
    });
    document.querySelector("#desktopNav .predictionsActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("predictions", { game: GameNavbar.getCurrentlyActivatedGameIfAny() });
    });
    document.querySelector("#desktopNav .newsActive").addEventListener("click", function (evt) {
      RouterAssistant.redirect("news", {});
    });
    document.querySelector("#desktopNav .GiveawaysElement").addEventListener("click", function (evt) {
      RouterAssistant.redirect("giveaways", {});
    });
  }

  static customUppercase(str) {
    let result = '';
    for (let i = 0; i < str.length; i++) {
      let char = str[i];
      // If the character is a Greek accented character, replace it with its non-accented uppercase equivalent
      switch (char) {
        case 'Ά':
          result += 'Α';
          break;
        case 'Έ':
          result += 'Ε';
          break;
        case 'Ή':
          result += 'Η';
          break;
        case 'Ί':
          result += 'Ι';
          break;
        case 'Ό':
          result += 'Ο';
          break;
        case 'Ύ':
          result += 'Υ';
          break;
        case 'Ώ':
          result += 'Ω';
          break;
        default:
          result += char.toUpperCase();
      }
    }
    return result;
  }
}

MainNavbar.template = /*html*/ `
  <nav  id="desktopNav" class="navbar menu-design">
     
     
<div class="img-container h-100 d-flex align-items-center justify-content-center"> 
 <img class="navbar-brand ms-3" id="navDesktopElement" src="${Logo}" alt="myesports">
</div>
          <ul  class="list-group list-group-horizontal ms-0 menu-design-ul h-100 mx-auto">
            
            <li class="list-group-item resize-desktop d-flex align-items-center  homeActive" ><span class="HomeElement"></span></li>
            <li class="list-group-item resize-desktop d-flex align-items-center  matchesActive"><span class="MatchesElement"></span></li>
            <li class="list-group-item resize-desktop GiveawaysElement" style="display: none;">A third item</li>
            <li class="list-group-item resize-desktop d-flex align-items-center  predictionsActive"><span class="PredictionsElement"></span></li>
            <li class="list-group-item resize-desktop d-flex align-items-center  tournamentsActive"><span class="TournamentsElement"></span></li>
            <!-- <li class="list-group-item d-flex align-items-center  casinosActive"><span class="CasinosElement"></span></li>  -->
            <li class="list-group-item resize-desktop d-flex align-items-center  streamersActive"><span class="StreamersElement"></span></li>
            <li class="list-group-item resize-desktop d-flex align-items-center  newsActive"><span class="newsElement"></span></li>
          </ul>
    

          </nav>
        
          
<nav id="mobileNav" class="navbar p-0 m-0 ">
<div class=" d-flex justify-content-around p-0 m-0 w-100 ">
          <div  class="row w-100 text-white-50 ">
           
          <div class="nav-link  d-flex flex-column align-items-center homeMobile">
          
          <i> <img class="navbar-brand m-0 p-0" id="navMobileElement" src="${LogoWhite}" alt="myesports"></i>
            <p class="list-group-item text-menu-resize m-0 HomeElementM ">HOMEEEEEE</p>
            </div>
            
            
            <div class="nav-link d-flex flex-column align-items-center MatchesMobile">
            <i class=" bi bi-lightning-fill"></i>
            <p class="list-group-item text-menu-resize m-0 MatchesElementM ">MATCHESSSSSSSSS</p>
            </div>
            
            <div class="nav-link d-flex flex-column align-items-center predictionsMobile">
            <i class="bi bi-calendar2-check-fill"></i>
            <p class="list-group-item text-menu-resize m-0 PredictionsElementM ">pred</p>
            </div>

             <div class="nav-link d-flex flex-column align-items-center TournamentsMobile">
             <i class=" bi bi-rocket-fill"></i>
            <p class="list-group-item text-menu-resize m-0 TournamentsElementM ">TOURNAMENTSSSSS</p>
            </div>
            
           <!-- <div class="nav-link d-flex flex-column align-items-center bookmakersMobile">
             <i class=" bi bi-gift-fill"></i>
            <p class="list-group-item text-menu-resize m-0 CasinosElementM">BOOKMAKERSSSSSSSSSS</p>
            </div> -->

            <div class="nav-link d-flex flex-column align-items-center StreamersMobile">
             <i class=" bi bi-broadcast"></i>
            <p class="list-group-item text-menu-resize m-0 StreamersElementM">Streamers</p>
            </div>
            <div class="nav-link d-flex flex-column align-items-center newsMobile">
             <i class=" bi bi-newspaper"></i>
            <p class="list-group-item text-menu-resize m-0 newsElementM">NEWS</p>
            </div>

         
           </div>
           </div>
          </nav>
        
 
`;

MainNavbar.reg("main-navbar");
