// postbookmakers-component.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { FirebaseAssistant } from "../../utilities/firebase-assistant/firebase-assistant";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { BookmakershomeComponent } from "../bookmakershome-component/bookmakershome-component";
import { I18n } from "../../utilities/i18n/i18n";
import showdown from 'showdown';



const css = `
<style>
postbookmakers-component {
    display: block;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    background: #0f1219;
}

/* Top container: lighter gradient for contrast */
postbookmakers-component .top {
    background: linear-gradient(135deg, #1b222a 0%, #0f1219 100%);
    border-radius: 10px;
    padding: 20px 15px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

/* Image wrapper for the top section */
postbookmakers-component .image-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    padding: 10px;
    transition: background 0.3s ease;
    border-bottom: none;
}
postbookmakers-component .image-wrapper:hover {
    background: rgba(255,255,255,0.91);
}

/* Logo inside image wrapper */
postbookmakers-component .image-wrapper img {
    width: 180px;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}
postbookmakers-component .image-wrapper img:hover {
    transform: scale(1.05);

}

postbookmakers-component .text-white {
    color: #fff !important;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    display: inline-block;
    text-transform: uppercase;
}

postbookmakers-component .text-rating-resize {
    background-color: #f5c518;
    color: #000000;
    padding: 0.3rem 0.6rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: 800;
    display: inline-block;
    margin-bottom: 15px;
}
postbookmakers-component .text-rating-resize i {
    color: #000000;
    margin-left: 5px;
}

/* CTA Button */
postbookmakers-component .cta-col-action .btn {
    background-color: #4CAF50;
    color: #ffffff !important;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(76, 175, 80, 0.3);
    font-size: 1rem;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none !important;
    display: inline-block;
}
postbookmakers-component .cta-col-action .btn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* Intro / Analysis Section */
postbookmakers-component .intro-section {
    text-align: center;
    margin-bottom: 2rem;
}
postbookmakers-component .intro-section .title-section {
    font-size: calc(0.5rem + 1.5vw);  
    color: #4CAF50;
    font-weight: 700;
    letter-spacing: 1px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(76,175,80,0.3);
    margin-bottom: 2rem;
}

/* Content Section */
postbookmakers-component .content-section {
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
    line-height: 1.7;
    background: #1e252e;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(76,175,80,0.05);
}

postbookmakers-component .content-section p {
    font-size: calc(0.5rem + 0.5vw);
    margin-bottom: 1rem;
}
postbookmakers-component .content-section h1 {
    font-size: calc(0.5rem + 1.5vw);
}
postbookmakers-component .content-section h2 {
    font-size: calc(0.5rem + 1vw);
}
    postbookmakers-component .content-section li {
    font-size: calc(0.5rem + 0.5vw);
}
postbookmakers-component a {
    color: #4CAF50 !important;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px dashed #4CAF50;
    transition: color 0.3s ease, border-bottom-color 0.3s ease;
}
postbookmakers-component a:hover {
    color: #66FF99 !important;
    border-bottom-color: #66FF99;
}
postbookmakers-component .progress-bar {
    text-align: start;
    padding-inline-start: 49%;
}
postbookmakers-component .content-section h1,
postbookmakers-component .content-section h2,
postbookmakers-component .content-section h3,
postbookmakers-component .content-section h4,
postbookmakers-component .content-section h5,
postbookmakers-component .content-section h6 {
    color: #4CAF50;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* Lists with star bullets */
postbookmakers-component .content-section ul,
postbookmakers-component .content-section ol {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    color: #D3D3D3;
}

postbookmakers-component .content-section li {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 1.5rem;
    list-style: none;
}
postbookmakers-component .content-section li::before {
    content: "★";
    color: #4CAF50;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0.1rem;
}

/* Code blocks */
postbookmakers-component .content-section code {
    background: rgba(76,175,80,0.1);
    color: #66FF99;
    font-family: 'Source Code Pro', monospace;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.9rem;
}
postbookmakers-component .content-section pre code {
    display: block;
    padding: 1rem;
    background: #0f1219;
    border: 1px solid rgba(76,175,80,0.3);
    border-radius: 5px;
    white-space: pre-wrap;
    word-break: break-word;
    margin-bottom: 1.5rem;
}

/* Esports Tag */
postbookmakers-component .text-resize-esports {
    color: #4CAF50;
    font-weight: 700;
    font-size: 1rem;
    padding: 5px 10px;
    border: 1px dashed #4CAF50;
    border-radius: 5px;
    background: rgba(76,175,80,0.1);
}

/* Banner Section (CTA) */
postbookmakers-component .banner-section {
    position: relative;
    margin-top: 40px;
    background: linear-gradient(135deg, #1e282e 0%, #0f1219 100%);
    padding: 40px 20px;
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed rgba(255,255,255,0.3);
}

/* Image wrapper for banner section */
postbookmakers-component .banner-section .image-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    padding: 10px;
    transition: background 0.3s ease;
    border-bottom: none;
}
postbookmakers-component .banner-section .image-wrapper:hover {
    background: rgba(255,255,255,0.91);
}

postbookmakers-component .banner-section .image-wrapper img {
    max-width: 100px;
    height: auto;
    transition: transform 0.3s ease, filter 0.3s ease;
}
postbookmakers-component .banner-section .image-wrapper img:hover {
    transform: scale(1.1);
}

postbookmakers-component .banner-text {
    display: block;
    color: #4CAF50;
    font-weight: 800;
    font-size: 1.2rem;
    margin-bottom: 0;
    letter-spacing: 0.5px;
}
postbookmakers-component .banner-details {
    display: block;
    color: #dcdcdc !important;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

postbookmakers-component .betnow {
    background-color: #dc3545;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    border-bottom: none;
    text-decoration: none !important;
    box-shadow: 0 4px 10px rgba(220,53,69,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
    display: inline-block;
}
postbookmakers-component .betnow:hover {
    background-color: #c32f3d;
    transform: translateY(-2px);
    color:#fff !important;
}

/* Responsive Tweaks */

/* (Same responsive CSS as above, unchanged for brevity) */
</style>
`;

export class PostBookmakersComponent extends BaseComponent {

  initCallback() {
    document.head.insertAdjacentHTML("beforeend", css);
    RouterAssistant.checkForSingleContainer('postbookmakers-component', PostBookmakersComponent.initiator);
  }

  static initiator() {
    BookmakershomeComponent.getBookmakers('fromBookmakersComponent', () => {
      if (window.location.href.includes('s_bookmaker')) {
        console.debug('PostBookmakersComponent callback is running.');

        const bookmakerid = RouterAssistant.getCurrentVariableValue('bookmaker');
        let data = null;

        if (window.savedBookMakers !== undefined && window.savedBookMakers[bookmakerid]) {
          data = window.savedBookMakers[bookmakerid];
        } else {
          console.error('Bookmaker data not found for ID:', bookmakerid);
          return;
        }

        console.debug('Retrieved Bookmaker Data:', data);

        const userLanguage = I18n.getUsersPreferredLanguage() || 'en';
        const getLocalizedField = (field) => {
          if (field && typeof field === 'object') {
            return field[userLanguage] || field['en'] || '';
          }
          return field || '';
        };

        const bookmakerName = getLocalizedField(data.bookmaker_skin_name || data.name);
        const bookmakerAbout = getLocalizedField(data.bookmaker_about);
        const bookmakerLogo = data.bookmaker_logo || '';
        const bookmakerColor = data.bookmaker_color || '#f5c518';
        const bookmakerBonusUrl = data.bookmaker_bonus_url || '#';
        const bookmakerBonusText = (data.bonus_text && data.bonus_text[userLanguage]) || '';
        const bookmakerBonusDetails = (data.bonus_details && data.bonus_details[userLanguage]) || '';

        const bookmakerDepositsRaw = data.bookmakers_deposit;
        const bookmakerDeposits = {};
        for (const [method, localizedData] of Object.entries(bookmakerDepositsRaw)) {
          bookmakerDeposits[method] = getLocalizedField(localizedData);
        }

        const bookmakerWithdrawalsRaw = data.bookmaker_withdrawals;
        const bookmakerWithdrawals = {};
        for (const [method, localizedData] of Object.entries(bookmakerWithdrawalsRaw)) {
          bookmakerWithdrawals[method] = getLocalizedField(localizedData);
        }

        const bookmakersEsportsRaw = data.bookmakers_esports;
        let bookmakersEsports = [];
        if (Array.isArray(bookmakersEsportsRaw)) {
          bookmakersEsports = bookmakersEsportsRaw;
        } else {
          console.warn('bookmakers_esports field is missing or format unexpected.');
        }


        const bookmakerRatingApodoseis = data.bookmaker_rating?.apodoseis || 0;
        if (bookmakerRatingApodoseis === 100) {
          const theStyle = "padding-inline-start: 48%;";
        } else {
          const theStyle = "padding-inline-start: 49%;";
        }
        const bookmakerRatingPlithwra = data.bookmaker_rating?.plithwra_esports || 0;
        const bookmakerRatingLiveSupport = data.bookmaker_rating?.live_support || 0;
        const bookmakerRatingPlhrwmes = data.bookmaker_rating?.plhrwmes || 0;
        const bookmakerRatingLiveSteaming = data.bookmaker_rating?.live_streaming || 0;

        const ratingsSum = bookmakerRatingApodoseis + bookmakerRatingPlithwra + bookmakerRatingLiveSupport + bookmakerRatingPlhrwmes + bookmakerRatingLiveSteaming;
        const overallRating = (ratingsSum / 100).toFixed(1);

        const converter = new showdown.Converter();
        const bookmakerAboutContent = converter.makeHtml(bookmakerAbout);
        console.debug('Converted Bookmaker About Content:', bookmakerAboutContent);

        function populateWithdrawals(withdrawals) {
          const container = document.getElementById('withdrawals-container');
          if (!container) return;

          container.innerHTML = '';
          if (typeof withdrawals !== 'object' || withdrawals === null) return;

          const sortedEntries = Object.entries(withdrawals).sort(([a], [b]) => a.localeCompare(b));
          sortedEntries.forEach(([method, values]) => {
            if (!Array.isArray(values) || values.length === 0 || values.every(item => !item)) return;

            const row = document.createElement('div');
            row.className = "row color-dark d-flex align-items-center justify-content-start rounded mt-2";

            const nameCol = document.createElement('div');
            nameCol.className = "col-4 resize-name";
            const nameSpan = document.createElement('span');
            nameSpan.textContent = method.replace(/_/g, ' ').toUpperCase();
            nameSpan.classList.add('text-resize-payment-name');
            nameCol.appendChild(nameSpan);
            row.appendChild(nameCol);

            const labels = ['Χρόνος', 'Χρέωση', 'Ελάχιστη', 'Max/Ημέρα'];
            values.forEach((value, index) => {
              if (index >= 4) return;
              const col = document.createElement('div');
              col.className = "col-2 d-flex flex-column";
              const labelSpan = document.createElement('span');
              labelSpan.textContent = labels[index] || '';
              labelSpan.classList.add('text-resize-top');

              const valueSpan = document.createElement('span');
              valueSpan.textContent = value || '';
              valueSpan.classList.add('text-resize-bot');

              col.appendChild(labelSpan);
              col.appendChild(valueSpan);
              row.appendChild(col);
            });
            container.appendChild(row);
          });
        }

        function populateDeposits(deposits) {
          const container = document.getElementById('deposits-container');
          if (!container) return;

          container.innerHTML = '';
          if (typeof deposits !== 'object' || deposits === null) return;

          const sortedEntries = Object.entries(deposits).sort(([a], [b]) => a.localeCompare(b));
          sortedEntries.forEach(([method, values]) => {
            if (!Array.isArray(values) || values.length === 0 || values.every(item => !item)) return;

            const row = document.createElement('div');
            row.className = "row color-dark d-flex align-items-center justify-content-start rounded mt-2";

            const nameCol = document.createElement('div');
            nameCol.className = "col-4 resize-name";
            const nameSpan = document.createElement('span');
            nameSpan.textContent = method.replace(/_/g, ' ').toUpperCase();
            nameSpan.classList.add('text-resize-payment-name');
            nameCol.appendChild(nameSpan);
            row.appendChild(nameCol);

            const labels = ['Χρόνος', 'Χρέωση', 'Ελάχιστη', 'Max/Ημέρα'];
            values.forEach((value, index) => {
              if (index >= 4) return;
              const col = document.createElement('div');
              col.className = "col-2 d-flex flex-column";
              const labelSpan = document.createElement('span');
              labelSpan.textContent = labels[index] || '';
              labelSpan.classList.add('text-resize-top');

              const valueSpan = document.createElement('span');
              valueSpan.textContent = value || '';
              valueSpan.classList.add('text-resize-bot');

              col.appendChild(labelSpan);
              col.appendChild(valueSpan);
              row.appendChild(col);
            });
            container.appendChild(row);
          });
        }

        function populateEsports(esportsArray) {
          const container = document.getElementById('esports-bookmakers-list');
          if (!container) return;

          container.innerHTML = '';
          if (!Array.isArray(esportsArray)) return;

          if (esportsArray.length === 0) {
            container.innerHTML = '<p>No Esports data available.</p>';
            return;
          }

          esportsArray.forEach((esport) => {
            if (esport) {
              const esportDiv = document.createElement('div');
              esportDiv.className = "col-auto m-2 rounded p-0";
              const esportSpan = document.createElement('span');
              esportSpan.className = "text-resize-esports";
              esportSpan.textContent = esport;
              esportDiv.appendChild(esportSpan);
              container.appendChild(esportDiv);
            }
          });
        }


        const bkmkrHtml =  /*html*/`
<div class="container">
  <div class="row top rounded-top" style="background-color: ${bookmakerColor}">
    <a href="${data.bookmaker_bonus_url || '#'}" target="_blank" rel="noopener noreferrer" class="image-wrapper">
      <img src="${bookmakerLogo}" alt="${bookmakerName} Logo" ">
    </a>
    <div class="col-12 text-center">
      <span class="text-white">${bookmakerName}</span>
    </div>
    <div class="col-12 text-center my-2">
      <span class="text-rating-resize">
        <span>${overallRating} / 5</span>
        <i class="bi bi-star-fill"></i>
      </span>
    </div>
    <div class="col-12 text-center my-2 cta-col-action">
      <a href="${data.bookmaker_bonus_url || '#'}" class="btn" target="_blank" rel="noopener noreferrer">${I18n.translateString('bookmakersPostTranslations.register')}</a>
    </div>
  </div>

  <!-- Rating Section -->
  <div class="row d-flex justify-content-center mt-1 mt-lg-2">
    <div class="col-12 scrollable-section d-flex flex-column" id="rating">
      <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.odds')}</span>
      <div class="progress mb-2" style="height: 20px;">
        <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
             style="width: ${bookmakerRatingApodoseis}%; background-color: ${bookmakerColor};">
          ${bookmakerRatingApodoseis}%
        </div>
      </div>
      <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.varietyOfEsports')}</span>
      <div class="progress mb-2" style="height: 20px;">
        <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
             role="progressbar" style="width: ${bookmakerRatingPlithwra}%; background-color: ${bookmakerColor};">
          ${bookmakerRatingPlithwra}%
        </div>
      </div>
      <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.liveSupport')}</span>
      <div class="progress mb-2" style="height: 20px;">
        <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
             role="progressbar" style="width: ${bookmakerRatingLiveSupport}%; background-color: ${bookmakerColor};">
          ${bookmakerRatingLiveSupport}%
        </div>
      </div>
      <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.liveStreaming')}</span>
      <div class="progress mb-2" style="height: 20px;">
        <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
             role="progressbar" style="width: ${bookmakerRatingLiveSteaming}%; background-color: ${bookmakerColor};">
          ${bookmakerRatingLiveSteaming}%
        </div>
      </div>
      <span class="text-center mb-2 text-resize-name-rating">${I18n.translateString('bookmakersPostTranslations.payments')}</span>
      <div class="progress mb-2" style="height: 20px;">
        <div class="progress-bar text-resize-progress-bar progress-bar-striped progress-bar-animated" 
             role="progressbar" style="width: ${bookmakerRatingPlhrwmes}%; background-color: ${bookmakerColor};">
          ${bookmakerRatingPlhrwmes}%
        </div>
      </div>
    </div>
  </div>
    
  <div class="intro-section">
    <h1 class="title-section  mt-5">${bookmakerName} - ${I18n.translateString('bookmakersPostTranslations.fullReview')}</h1>
    <div class="content-section">
      ${bookmakerAboutContent}
    </div>
  </div>

  <!-- Esports Section -->
  <div class="row mt-1 mt-lg-2 scrollable-section" id="bookmakers_esports-scroll">
    <h2 class="title-section" style="color:#4CAF50; margin-bottom:1.5rem;">${I18n.translateString('bookmakersPostTranslations.esports')}</h2>
    <div id="esports-bookmakers-list" class="row d-flex flex-row"></div>
  </div>
</div>

<!-- Banner Section -->
<div class="banner-section my-5">
  <a href="${bookmakerBonusUrl}" target="_blank" rel="noopener noreferrer" class="image-wrapper">
    <img src="${bookmakerLogo}" alt="${bookmakerName}" >
  </a>
  <span class="banner-text">${bookmakerBonusText}</span>
  <span class="banner-text banner-details">${bookmakerBonusDetails}</span>
  <a href="${bookmakerBonusUrl}" target="_blank" rel="noopener noreferrer" class="betnow">${I18n.translateString('bookmakersPostTranslations.register')}</a>
</div>
`;

        const containerElement = document.querySelector('#s_bookmaker');
        if (containerElement) {
          containerElement.insertAdjacentHTML('beforeend', bkmkrHtml);
          console.debug('Bookmaker HTML inserted into #s_bookmaker.');
        } else {
          console.error('Container element #s_bookmaker not found in the DOM.');
          return;
        }

        populateWithdrawals(bookmakerWithdrawals);
        populateDeposits(bookmakerDeposits);
        populateEsports(bookmakersEsports);
      }
    })
  }
}

PostBookmakersComponent.template = /*html*/`
  <div id="s_bookmaker"></div>
`;

PostBookmakersComponent.reg('postbookmakers-component'); // components should always be 2 words separated with a dash
