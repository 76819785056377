// postbookmakersskin-container.js
import { BaseComponent } from "@symbiotejs/symbiote";
import { GridOrchestrator } from "../grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersSkinComponent } from "../postbookmakersskin-component/postbookmakersskin-component"; // Updated Import
import { PredictionsHomeComponent } from "../predictions-homecomponent/predictions-homecomponent"; // Ensure correct path
import { DiscordTemplate } from "../discord-template/discord-template"; // Ensure correct path
import { WidgetBookmakers } from "../widget-bookmakers/widget-bookmakers"; // Ensure correct path

const css = `
    <style>
        @media screen and (orientation:portrait) {
            /* Add any specific styles for portrait orientation if needed */
        }
        @media screen and (orientation:landscape) {
            /* Add any specific styles for landscape orientation if needed */
        }

        /* Optional: Additional styles specific to the container */
        .skin-container {
            /* Example: Add padding or margin if needed */
        }
    </style>
`;

export class PostBookmakersSkinContainer extends BaseComponent {

    initCallback() {
        // Insert CSS into the document head
        document.head.insertAdjacentHTML("beforeend", css);
        // Deactivate game filters if necessary
        GridOrchestrator.deactivateGameFilters();
    }

    static initiator() {
        // Initialize components or perform any setup if needed
        // Currently, all initialization is handled by child components
    }
}

PostBookmakersSkinContainer.template = /*html*/ `
   <div class="main-content col-lg-8 col-md-12 col-sm-12 col-xs-12 g-0 mt-0 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-5 mt-xxl-5 ">
                <div class="home-matches home-components row mx-2">
                        <postbookmakersskin-component></postbookmakersskin-component>
                   
                </div>
              </div>
           <div class="sidebar col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-5 ">
                    <placeholder-matcheswidget></placeholder-matcheswidget>
                    <div class="sidebar-matches home-components row m-2 ">
                        <div class="title">
                        </div>
                        <matches-component data-mode="homePage"></matches-component>
                    </div>
                    <div class="sidebar-predictions home-components row m-2 ">
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                        </div>
                    </div>
                </div>
`;

PostBookmakersSkinContainer.reg('postbookmakersskin-container'); // Register the component
