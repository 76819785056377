import { BaseComponent } from "@symbiotejs/symbiote";
import { I18n } from "../../utilities/i18n/i18n";
import { RouterAssistant } from "../../utilities/router-assistant/router-assistant";
import { GridOrchestrator } from "../../components/grid-orchestrator/grid-orchestrator";
import { MatchesComponent } from "../matches-component/matches-component";
import { PostBookmakersComponent } from "../postbookmakers-component/postbookmakers-component";
import { StreamerComponent } from "../streamer-component/streamer-component";
import { MiscAssistant } from "../../utilities/misc-assistant/misc-assistant";


const css = `
<style>
 streamers-container  .notactive {
            background-color: #212529;
        }
 streamers-container .active {
            background-color: #800000;
        }
</style>
`

export class StreamersContainer extends BaseComponent {

    initCallback() {
        document.head.insertAdjacentHTML("beforeend", css)
        GridOrchestrator.deactivateGameFilters();


        window.addEventListener('resize', function () {
            // if no resize event is fired within 250ms of the lastly fired one, call the performOrientationChangeActions function :)
            MiscAssistant.debounce(StreamersContainer.performOrientationChangeActions, 250)
        }, true)
        document.getElementById('detailsOption').addEventListener('click', function () {
            this.classList.add('active');
            this.classList.remove('notactive');
            document.getElementById('chatOption').classList.remove('active');
            document.getElementById('chatOption').classList.add('notactive');
        });

        document.getElementById('chatOption').addEventListener('click', function () {
            this.classList.add('active');
            this.classList.remove('notactive');
            document.getElementById('detailsOption').classList.remove('active');
            document.getElementById('detailsOption').classList.add('notactive');
        });
        document.getElementById('streamersContainerHeader').innerText = I18n.translateString('navMainMenu.Streamers')
    }
    static performOrientationChangeActions() {
        if (window.location.href.includes('streamers')) {
            const currentState = (window.innerHeight > window.innerWidth) ? 'mobile' : 'desktop';
            const thereIsContentInside = document.querySelectorAll('streamer-component').length !== 0;

            if (currentState === 'mobile') {
                console.log('i am on mobile')
                StreamersContainer.loadMobileLayout()
                window.StreamersPagePreviousOrientationState = currentState
            } else if (currentState === 'desktop') {
                console.log('i am on desktop')
                // StreamersContainer.loadDesktopLayout()
                window.StreamersPageOrientationState = currentState
            }
        }
    }
    static loadMobileLayout() {
        if (document.querySelectorAll('streamer-component').length !== 0) {
            // document.getElementById('streamerInfoCard').style.display = ""

        }
    }
}

StreamersContainer.template = /*html*/ `
<h1 id="streamersContainerHeader" style="font-size: 1px;color: rgb(15, 18, 23)"></h1>

<div id="streamerContainerInnerContainer" class="main-content col-lg-8 col-md-8 col-sm-8 col-xs-12 g-0 mt-2 ">

     <streamer-component></streamer-component>


</div>

 <div class="sidebar col-lg-4 col-md-4 col-sm-4 col-xs-12 mt-2 ">

                  <div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-12 col-xxl-12 d-none d-lg-flex container flex-column justify-content-center align-items-center align-content-center">
                    <div class="row w-100" id="infoChatRow" style="display: none;">
                        <div class="col text-center clickable detailsOption active rounded-top" id="detailsOption">
                            <h5 class="text-resize-button-streamer-info">Info</h5>
                        </div>
                        <div class="col text-center clickable chatOption notactive rounded-top" id="chatOption">
                            <h5 class="text-resize-button-streamer-chat">Chat</h5>
                        </div>
                    </div>

                        <div class="row w-100" id="streamerDetails" >
                        <!-- Streamer Card info inserted here dynamically from streamer-component.js -->
                        </div>
                    </div>
                    <placeholder-matcheswidget></placeholder-matcheswidget>
                    <div class="sidebar-matches home-components row m-2 ">
                        <div class="title">
                        </div>
                        <matches-component data-mode="homePage"></matches-component>
                    </div>
                    <div class="sidebar-predictions home-components row m-2 ">
                    <streamer-chat></streamer-chat>
                        <predictions-homecomponent></predictions-homecomponent>
                    </div>
                    <div class="sidebar-discord home-components row m-2 " >
                        <div class="home-discord-template">
                            <discord-template></discord-template>
                        </div>
                    </div>
                    <div class="sidebar-bonus home-components row m-2 ">
                        <div class="home-widget-bookmakers">
                            <widget-bookmakers></widget-bookmakers>
                            <widget-bookmakers-skin></widget-bookmakers-skin>
                        </div>
                    </div>
                </div>
`;

StreamersContainer.reg('streamers-container');
